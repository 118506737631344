/*----------------------------------------*/
/*  25 - Components - Instagram
/*----------------------------------------*/
.skudmart-instagram{
    &.gutter-space{
        &-10{
            .slick-list{
                margin-left: -5px;
                margin-right: -5px;
                .slick-slide{
                    padding-left: 5px;
                    padding-right: 5px;
                }
            }
        }
    }
    .instagram_gallery{
        .instagram-image{
            position: relative;
            &:before{
                background-color: $secondary-color;
                content: "";
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                position: absolute;
                opacity: 0;
                visibility: hidden;
                @extend %basetransition;
            }
            &:after{
                background-color: $white-color;
                font-family: "LaStudioIcons";
                color: $secondary-color;
                content: "\ea67";
                border-radius: 100%;
                width: 50px;
                height: 50px;
                line-height: 50px;
                text-align: center;
                font-size: 16px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -20%);
                @extend %basetransition;
                opacity: 0;
                visibility: hidden;
            }
            &:hover{
                &:before{
                    opacity: 0.5;
                    visibility: visible;
                }
                &:after{
                    transform: translate(-50%, -50%);
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
    &.five-slide_item{
        .instagram-image{
            &:before{
                width: calc(100% - 10px);
                left: 5px;
            }
        }
    }
}

/* ---Custom Instagram--- */
.custom-instagram_area{
    .instagram-content{
        @include breakpoint(max-md_device){
            padding-bottom: 30px;
        }
        h2{
            &.sub-heading{
                font-size: 56px;
                line-height: 64.4px;
                @include breakpoint(max-lg_device){
                    font-size: 45px;
                    line-height: 53.4px;
                }
            }
        }
    }
    .instagram-img{
        display: flex;
        align-items: center;
        .single-img{
            position: relative;
            .add-action{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -20%);
                @extend %basetransition;
                opacity: 0;
                visibility: hidden;
                z-index: 1;
                i{
                    background-color: $white-color;
                    color: $secondary-color;
                    border-radius: 100%;
                    width: 50px;
                    height: 50px;
                    line-height: 50px;
                    text-align: center;
                    font-size: 16px;
                    display: block;
                }
            }
            &:hover{
                .add-action{
                    transform: translate(-50%, -50%);
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
        .group-img_wrap{
            position: relative;
            &:before{
                border: 1px solid $border-color;
                border-radius: 100%;
                width: 100%;
                height: 90%;
                top: 0;
                left: 0;
                content: "";
                position: absolute;
                z-index: -1;
            }
            .group-img{
                display: flex;
            }
            .additional-img{
                width: 65%;
            }
        }
        .single-img{
            padding: 30px;
            @include breakpoint(max-lg_device){
                padding: 20px;
            }
            @include breakpoint(max-sm_device){
                padding: 10px;
            }
        }
    }
}