/*----------------------------------------*/
/*  45 - Pages - Order Tracking
/*----------------------------------------*/
.order-tracking_item{
    p{
        &.short-desc{
            color: $secondary-color;
        }
    }
    form{
        &.order-tracking_form{
            label{
                &.order-label{
                    color: $secondary-color;
                    display: block;
                }
            }
            input{
                &.order-input{
                    border: 1px solid $border-color;
                    color: $text-color;
                    width: 100%;
                    height: 70px;
                    padding: 0 20px;
                    @extend %basetransition;
                    &:focus{
                        border-color: $secondary-color;
                        color: $secondary-color;
                        &::placeholder{
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}