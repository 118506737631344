/*----------------------------------------*/
/*  51 - Pages - Blog
/*----------------------------------------*/
.blog-item{
    .single-img{
        position: relative;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .blog-meta{
            background-color: $white-color;
            position: absolute;
            width: 60px;
            height: 60px;
            bottom: 0;
            right: 0;
            z-index: 1;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            span{
                font-size: 12px;
                text-transform: uppercase;
                color: $secondary-color;
                font-weight: 500;
                &.date{
                    display: block;
                }
            }
        }
        .add-action{
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 1;
            .post-category{
                background-color: $secondary-color;
                padding: 15px 45px;
                a{
                    color: $white-color;
                    font-size: 11px;
                    line-height: 19.8px;
                    text-transform: uppercase;
                    font-weight: 500;
                    &:hover{
                        text-decoration: underline;
                        color: $primary-color;
                    }
                }
            }
        }
    }
    .single-content{
        &.with-border{
            border-bottom: 1px solid $border-color;
            padding-bottom: 30px;
            margin-bottom: 30px;
        }
        .post-category{
            a{
                font-size: 11px;
                line-height: 19.8px;
                text-transform: uppercase;
            }
            &.with-bg{
                background-color: $primary-color-2;
                display: inline-block;
                padding: 5px;
                margin-top: 15px;
                a{
                    &:hover{
                        text-decoration: underline;
                        color: $white-color;
                    }
                }
            }
        }
        h3{
            &.heading{
                a{
                    font-size: 24px;
                    line-height: 36px;
                }
            }
        }
        .post-meta{
            span,
            a{
                font-size: 12px;
                line-height: 20.8px;
            }
            span{
                &.author{
                    a{
                        &:hover{
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
        p{
            &.short-desc{
                color: $secondary-color;
                font-size: 14px;
                line-height: 25.2px;
            }
        }
        &.different-font-size{
            h3{
                &.heading{
                    a{
                        font-size: 32px;
                        line-height: 48px;
                        @include breakpoint(max-sm_device){
                            font-size: 25px;
                            line-height: 1.2;
                        }
                    }
                }
            }
            p{
                &.short-desc{
                    font-size: 16px;
                    line-height: 28.8px;
                }
            }
        }
    }
    &.list-view{
        display: flex;
        @include breakpoint(max-sm_device){
            flex-direction: column;
        }
        .single-img{
            width: 100%;
        }
        .single-content{
            padding-left: 30px;
            @include breakpoint(max-sm_device){
                padding-top: 20px;
                padding-left: 0;
            }
        }
    }
}

/* ---Without Sidebar--- */
.blog-area{
    &.without-sidebar{
        .blog-item{
            .single-img{
                .add-action{
                    left: 8%;
                    @include breakpoint(max-sm_device){
                        left: 0;
                    }
                }
            }
            .single-content{
                padding-left: 8%;
                padding-right: 8%;
                @include breakpoint(max-sm_device){
                    padding-left: 0;
                    padding-right: 0;
                }
                h3{
                    &.heading{
                        a{
                            @include breakpoint(max-sm_device){
                                font-size: 25px;
                                line-height: 1.2;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* ---Single Post--- */
.single-post_area{
    .blog-item{
        .single-content{
            .post-category{
                background-color: $secondary-color;
                display: inline-block;
                padding: 7.5px 15px;
                a{
                    color: $white-color;
                    font-size: 11px;
                    line-height: 19.8px;
                    text-transform: uppercase;
                    font-weight: 500;
                    &:hover{
                        text-decoration: underline;
                        color: $primary-color;
                    }
                }
            }
            p{
                &.short-desc{
                    font-size: 14px;
                    line-height: 28px;
                }
            }
        }
        .img-with_content{
            .single-img{
                float: left;
                margin-right: 30px;
                width: 50%;
                @include breakpoint(max-sm_device){
                    width: 100%;
                    padding-bottom: 25px;
                }
            }
            .single-content{
                width: 100%;
            }
        }
    }
    .blog-feedback_area{
        padding-top: 55px;
        h2{
            &.heading{
                font-size: 24px;
                line-height: 33.6px;
                padding-bottom: 25px;
            }
        }
        li{
            &:not(:last-child){
                margin-bottom: 30px;
            }
            &.user-body{
                display: flex;
                @include breakpoint(max-xxs_device){
                    display: block;
                    text-align: center;
                }
                &.sub-user_body{
                    margin-left: 25px;
                }
                .user-pic{
                    width: 75px;
                    @include breakpoint(max-xxs_device){
                        padding-bottom: 30px;
                        width: 100%;
                    }
                }
                .user-content{
                    box-shadow: 0 0 10px rgba(0,0,0,.14);
                    border: 1px solid $border-color;
                    width: calc(100% - 75px);
                    margin-left: 25px;
                    padding: 30px;
                    @include breakpoint(max-xxs_device){
                        width: 100%;
                        margin-left: 0;
                        padding: 15px;
                    }
                    h3{
                        display: flex;
                        padding-bottom: 20px;
                        &.user-name{
                            a{
                                font-size: 16px;
                                line-height: 19.2px;
                            }
                        }
                        .user-meta{
                            color: $nobel-color;
                            padding-left: 5px;
                            font-size: 12px;
                            line-height: 21px;
                        }
                    }
                    p{
                        &.user-feedback{
                            border-bottom: 1px solid $border-color;
                            font-family: 'Roboto', sans-serif;
                            font-size: 14px;
                            line-height: 25.2px;
                            padding-bottom: 20px;
                        }
                    }
                    .reply-btn_wrap{
                        padding-top: 10px;
                        a{
                            &.reply-btn{
                                color: $charcoal-color;
                                font-weight: 600;
                                font-size: 12px;
                                line-height: 21px;
                                display: inline-block;
                                > span{
                                    padding-right: 5px;
                                    vertical-align: middle;
                                }
                            }
                            &:hover{
                                color: $primary-color;
                            }
                        }
                    }
                }
            }
        }
    }
    .blog-comment_area{
        padding-top: 50px;
        h2{
            &.heading{
                padding-bottom: 35px;
            }
        }
        .comment-field{
            textarea{
                border: 1px solid $border-color;
                width: 100%;
                height: 150px;
                padding: 15px;
            }
        }
        .group-input{
            display: flex;
            justify-content: space-between;
            padding-top: 20px;
            @include breakpoint(max-lg_device){
                display: block;
            }
           input{
                &.input-field{
                    border: 1px solid $border-color;
                    width: 100%;
                    height: 60px;
                    padding: 0 10px;
                    @include breakpoint(max-lg_device){
                        margin-bottom: 25px;
                        margin-right: 0;
                    }
                    &:not(:last-child){
                        margin-right: 30px;
                        @include breakpoint(max-lg_device){
                            margin-right: 0;
                        }
                    }
                }
            }
        }
        .field-checkbox{
            padding-top: 15px;
            input{
                &.input-checkbox{
                    margin-right: 15px;
                    vertical-align: middle;
                }
            }
            input[type="checkbox"]:checked + label {
                &:after {
                    color: #13aff0;
                }
            }
            label{
                &.label-checkbox{
                    color: $charcoal-color;
                    font-size: 14px;
                }
            }
        }
        .comment-btn_wrap{
            padding-top: 15px;
            a{
                &.skudmart-btn{
                    font-size: 14px;
                }
            }
        }
    }
}