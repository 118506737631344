/*----------------------------------------*/
/*  13 - Components - Slider
/*----------------------------------------*/
/* ---Main Slider--- */
.main-slider{
	.swiper-slide{
		.inner-slide{
			@include flexCenter;
			&.bg-1,
			&.bg-2,
			&.bg-3{
				background-image: url('../images/slider/bg/1-1.jpg');
				background-repeat: no-repeat;
				background-size: cover;
				height: 850px;
				@include breakpoint(max-xl_device){
					height: 580px;
				}
				@include breakpoint(max-lg_device){
					height: 445px;
				}
				@include breakpoint(max-sm_device){
					height: 380px;
				}
				&.bg-2{
					background-image: url('../images/slider/bg/1-2.jpg');
				}
				&.bg-3{
					background-image: url('../images/slider/bg/1-3.jpg');
				}
			}
			.slide-content{
				text-align: center;
				span{
					&.category{
						font-size: 13px;
						line-height: 1.2;
						padding-bottom: 20px;
						letter-spacing: 7px;
						display: block;
						&.playfair-font{
							font-family: 'Playfair Display', serif;
							font-style: italic;
							letter-spacing: 1px;
							font-size: 17px;
							line-height: 22px;
						}
					}
				}
				h1{
					&.heading{
						font-size: 53px;
						line-height: 1.2;
						font-weight: 500;
						letter-spacing: 2px;
						padding-bottom: 35px;
						@include breakpoint(max-sm_device){
							font-size: 25px;
						}
					}
				}
			}
		}
	}
}

/* ---Main Slider Two--- */
.main-slider-2{
	.swiper-slide{
		.inner-slide{
			display: flex;
			align-items: center;
			&.bg-1,
			&.bg-2,
			&.bg-3{
				background-image: url('../images/slider/bg/2-1.jpg');
				background-repeat: no-repeat;
				background-size: cover;
				height: 850px;
				@include breakpoint(max-xl_device){
					height: 580px;
				}
				@include breakpoint(max-lg_device){
					height: 445px;
				}
				@include breakpoint(max-sm_device){
					height: 380px;
				}
				&.bg-2{
					background-image: url('../images/slider/bg/2-2.jpg');
				}
				&.bg-3{
					background-image: url('../images/slider/bg/2-3.jpg');
				}
			}
			.slide-content{
				text-align: center;
				display: inline-block;
				span{
					color: $secondary-color;
					display: block;
					&.category{
						font-size: 24px;
						line-height: 28px;
					}
					&.product-offer{
						position: relative;
						font-size: 36px;
						line-height: 42px;
						strong{
							font-size: 119px;
							line-height: 139px;
							vertical-align: middle;
							font-weight: 400;
						}
						sup{
							vertical-align: super;
						}
					}
				}
				&.specific-direction{
					@include breakpoint(min-md_device){
						position: relative;
						left: 60%;
						text-align: left;
					}
				}
			}
		}
	}
}

/* ---Main Slider Three--- */
.main-slider-3{
	.swiper-slide{
		.inner-slide{
			display: flex;
			align-items: center;
			position: relative;
			&.bg-1{
				background-image: url('../images/slider/bg/3-1.jpg');
				background-repeat: no-repeat;
				background-size: cover;
			}
			&.item-height{
				height: 850px;
				@include breakpoint(max-xl_device){
					height: 610px;
				}
				@include breakpoint(max-lg_device){
					height: 445px;
				}
				@include breakpoint(max-sm_device){
					height: 380px;
				}
			}
			.container{
				@include breakpoint(max-sm_device){
					height: 100%;
				}
			}
			.slide-img{
				&.style-01{
					position: absolute;
					bottom: 0;
					left: 60px;
					height: 80%;
					@include breakpoint(max-sm_device){
						left: 0;
					}
					@include breakpoint(max-xs_device){
						height: 60%;
					}
					@include breakpoint(max-xs_device){
						height: 40%;
					}
					img{
						width: 100%;
						height: 100%;
						object-fit: contain;
						object-position: bottom;
					}
				}
			}
			.slide-img_wrap{
				position: relative;
				width: 50%;
				display: flex;
				flex-direction: column-reverse;
				@include breakpoint(max-sm_device){
					top: 20px;
				}
				@include breakpoint(max-xxs_device){
					width: 60%;
					top: 10%;
				}
				.slide-img{
					&.style-02{
						img{
							width: 90%;
							height: 100%;
							object-fit: cover;
							object-position: center center;
						}
					}
				}
				.animated-img{
					display: flex;
					justify-content: flex-end;
					animation: skud 2s infinite linear alternate;
					img{
						width: 30%;
						object-fit: cover;
					}
					@keyframes skud{
						0%{
							transform: translateX(30px);
						}
						100%{
							transform: translateX(0);
						}
					}
				}
			}
			.slide-content{
				position: absolute;
				bottom: 25%;
				right: 25%;
				@include breakpoint(max-md_device){
					right: 15%;
				}
				@include breakpoint(max-xs_device){
					bottom: auto;
					top: 30px;
				}
				&.vertical-position_middle{
					bottom: auto;
					top: 50%;
					right: 20%;
					transform: translateY(-50%);
					@include breakpoint(max-lg_device){
						right: 15%;
					}
					@include breakpoint(max-sm_device){
						bottom: 20px;
						top: auto;
						right: 20%;
						transform: translate(-50%, 0);
						left: 50%;
						width: 100%;
						text-align: center;
					}
				}
				span{
					&.category{
						font-size: 14px;
						display: block;
					}
				}
				h2{
					&.heading{
						font-size: 32px;
						line-height: 45px;
						@include breakpoint(max-lg_device){
							font-size: 20px;
							line-height: 33px;
						}
					}
				}
				.price-box{
					span{
						&.new-price{
							color: $secondary-color;
						}
					}
				}
				.button-wrap{
					@include breakpoint(max-sm_device){
						justify-content: center;
					}
					.skudmart-btn{
						@include breakpoint(max-lg_device){
							width: 120px;
							height: 40px;
							line-height: 40px;
						}
					}
				}
			}
		}
	}
}

/* ---Main Slider Four--- */
.main-slider-4{
	.swiper-slide{
		.inner-slide{
			display: flex;
			align-items: center;
			position: relative;
			height: 940px;
			@include breakpoint(max-xl_device){
				height: 730px;
			}
			@include breakpoint(max-sm_device){
				height: 315px;
			}
			.slide-content{
				width: 40%;
				padding-left: 90px;
				@include breakpoint(max-lg_device){
					padding-left: 30px;
				}
				@include breakpoint(max-xs_device){
					width: 100%;
					z-index: 1;
				}
				h1{
					&.heading{
						font-size: 46px;
						line-height: 54px;
						max-width: 65%;
						@include breakpoint(max-xl_device){
							font-size: 40px;
							max-width: 85%;
						}
						@include breakpoint(max-md_device){
							max-width: 100%;
							font-size: 26px;
							line-height: 1.4;
						}
						@include breakpoint(max-xs_device){
							color: $white-color;
						}
					}
				}
				.color-option{
					span{
						&.title{
							text-transform: uppercase;
							font-size: 13px;
							line-height: 14px;
							display: block;
							@include breakpoint(max-xs_device){
								color: $white-color;
							}
						}
					}
					ul{
						li{
							display: inline-block;
							&:not(:last-child){
								margin-right: 5px;
							}
							&:nth-child(1) {
								a{
									transition-delay: 100ms;
								}
							}
							&:nth-child(2) {
								a{
									transition-delay: 150ms;
								}
							}
							&:nth-child(3) {
								a{
									transition-delay: 200ms;
								}
							}
							a{
								display: block;
								width: 17px;
								height: 17px;
								transform: scaleY(0);
								transform-origin: bottom;
								@extend %basetransition;
							}
							&.text-color{
								a{
									background-color: $text-color;
								}
							}
							&.secondary-color{
								a{
									background-color: $secondary-color;
								}
							}
							&.primary-color{
								a{
									background-color: $primary-color;
								}
							}
						}
					}
				}
				.price-box{
					span{
						&.new-price{
							color: $secondary-color;
							font-size: 25px;
							line-height: 30px;
							@include breakpoint(max-xs_device){
								color: $white-color;
							}
						}
					}
				}
				.button-wrap{
					.skudmart-btn{
						@include breakpoint(max-xs_device){
							border: 1px solid $white-color;
							color: $white-color;
							&:hover{
								border-color: $secondary-color;
							}
						}
					}
				}
			}
			.slide-img{
				background-repeat: no-repeat;
				background-size: cover;
				height: 100%;
				width: 60%;
				position: absolute;
				right: 0;
				@include breakpoint(max-xs_device){
					width: 100%;
				}
				&.bg-1{
					background-image: url('../images/slider/slide-img/4-1-1145x1040.jpg');
				}
				&.bg-2{
					background-image: url('../images/slider/slide-img/4-2-1145x1040.jpg');
				}
				&.bg-3{
					background-image: url('../images/slider/slide-img/4-3-1145x1040.jpg');
				}
				&:before{
					@include breakpoint(max-xs_device){
						background-color: $secondary-color;
						width: 100%;
						height: 100%;
						content: "";
						position: absolute;
						top: 0;
						left: 0;
						opacity: 0.6;
					}
				}
			}
		}
	}
}

/* ---Main Slider Five--- */
.main-slider-5{
	.swiper-slide{
		.inner-slide{
			&.bg-1,
			&.bg-2,
			&.bg-3{
				background-image: url('../images/slider/bg/5-1.jpg');
				background-repeat: no-repeat;
				background-size: cover;
				height: 850px;
				@include breakpoint(max-xl_device){
					height: 580px;
				}
				@include breakpoint(max-lg_device){
					height: 445px;
				}
				@include breakpoint(max-sm_device){
					height: 380px;
				}
				&.bg-2{
					background-image: url('../images/slider/bg/5-2.jpg');
				}
				&.bg-3{
					background-image: url('../images/slider/bg/5-3.jpg');
				}
			}
			&.style-01{
				display: flex;
				align-items: center;
				justify-content: center;
				text-align: center;
				.slide-content{
					span{
						&.category{
							font-size: 18px;
							line-height: 20px;
							letter-spacing: 5px;
							text-transform: uppercase;
							padding-bottom: 20px;
							display: block;
							@include breakpoint(max-xxs_device){
								font-size: 14px;
							}
						}
					}
					h1{
						&.heading{
							padding-bottom: 35px;
							font-weight: 400;
							font-size: 75px;
							line-height: 89px;
							letter-spacing: 5px;
							@include breakpoint(max-sm_device){
								font-size: 40px;
								line-height: 1.2;
							}
							@include breakpoint(max-xs_device){
								font-size: 25px;
							}
						}
						&.playfair-font{
							font-family: 'Playfair Display', serif;
							font-style: italic;
						}
					}
				}
			}
			&.style-02{
				display: flex;
				align-items: center;
				.slide-content{
					position: relative;
					left: 12%;
					@include breakpoint(max-sm_device){
						left: 30px;
					}
					h1{
						&.heading{
							font-size: 65px;
							line-height: 76px;
							padding-bottom: 5px;
							font-weight: 400;
							@include breakpoint(max-sm_device){
								font-size: 40px;
								line-height: 1.2;
							}
							@include breakpoint(max-xs_device){
								font-size: 25px;
							}
						}
					}
					.price-box{
						span{
							&.new-price{
								color: $secondary-color;
								font-size: 36px;
								line-height: 42px;
							}
						}
					}
				}
			}
		}
	}
}

/* ---Main Slider Six--- */
.main-slider-6{
	.swiper-slide{
		.inner-slide{
			&.bg-1,
			&.bg-2,
			&.bg-3{
				background-image: url('../images/slider/bg/6-1.jpg');
				background-repeat: no-repeat;
				background-size: cover;
				position: relative;
				height: 680px;
				@include breakpoint(max-xl_device){
					height: 580px;
				}
				@include breakpoint(max-lg_device){
					height: 445px;
				}
				@include breakpoint(max-sm_device){
					background-position: center;
				}
				&.bg-2{
					background-image: url('../images/slider/bg/6-2.jpg');
				}
				&.bg-3{
					background-image: url('../images/slider/bg/6-3.jpg');
				}
			}
			&.style-01{
				display: flex;
				align-items: center;
				.slide-content{
					position: relative;
					left: 12%;
					@include breakpoint(max-sm_device){
						width: calc(100% - 30px);
						left: 15px;
					}
					.product-offer{
						background: linear-gradient(to 90deg, rgb(237, 45, 188) 0%, rgb(255, 142, 78) 100%);
						background-clip: text;
						-webkit-text-fill-color: transparent;
						text-transform: uppercase;
						line-height: 139px;
						font-weight: 700;
						font-size: 99px;
						border-color: rgb(255, 255, 255);
						color: rgb(255, 55, 74);
						transform-origin: 50% 50% 0px;
						letter-spacing: 2px;
						@include breakpoint(max-sm_device){
							font-size: 67px;
							line-height: 81px;
						}
						&.style-02{
							sub,
							sup{
								background: linear-gradient(to 90deg, rgb(237, 45, 188) 0%, rgb(255, 142, 78) 100%);
								background-clip: text;
								-webkit-text-fill-color: transparent;
								font-size: 36px;
								line-height: 42px;
								vertical-align: super;
							}
						}
					}
					span{
						&.read-more{
							color: $secondary-color;
							text-transform: uppercase;
							display: block;
						}
					}
					h3{
						&.heading{
							font-size: 46px;
							line-height: 54px;
							@include breakpoint(max-xs_device){
								font-size: 40px;
							}
						}
						&.playfair-font{
							font-family: 'Playfair Display', serif;
							font-style: italic;
							font-size: 36px;
							line-height: 48px;
						}
					}
					p{
						&.short-desc{
							background: linear-gradient(to 90deg, rgb(237, 45, 188) 0%, rgb(255, 142, 78) 100%);
							background-clip: text;
							-webkit-text-fill-color: transparent;
						}
					}
				}
			}
		}
	}
	.swiper-pagination{
		@include breakpoint(max-md_device){
			bottom: 30px;
		}
	}
}

/* ---Main Slider Seven--- */
.main-slider-7{
	.swiper-slide{
		&.bg-1,
		&.bg-2,
		&.bg-3{
			background-image: url('../images/slider/bg/7-1.jpg');
			background-repeat: no-repeat;
			background-size: cover;
			height: 655px;
			@include breakpoint(max-xl_device){
				height: 580px;
			}
			@include breakpoint(max-lg_device){
				height: 440px;
			}
			@include breakpoint(max-sm_device){
				height: auto;
			}
			&.bg-2{
				background-image: url('../images/slider/bg/7-2.jpg');
			}
		}
		&.style-01{
			.inner-slide{
				display: flex;
				align-items: center;
				justify-content: space-between;
				height: 100%;
				@include breakpoint(max-sm_device){
					flex-direction: column;
				}
				.slide-content{
					position: relative;
					text-align: center;
					width: 50%;
					z-index: 1;
					@include breakpoint(max-lg_device){
						width: 40%;
					}
					@include breakpoint(max-sm_device){
						top: 52px;
						width: 100%;
					}
					span{
						&.category{
							text-transform: uppercase;
							display: block;
							@include breakpoint(max-md_device){
								font-size: 14px;
							}
						}
					}
					h4{
						&.product-offer{
							background: linear-gradient(to right, rgb(255, 236, 162) 0%, rgb(234, 255, 122) 100%);
							background-clip: text;
							-webkit-text-fill-color: transparent;
							text-transform: uppercase;
							line-height: 139px;
							font-weight: 700;
							font-size: 99px;
							border-color: rgb(255, 255, 255);
							color: rgb(255, 236, 162);
							transform-origin: 50% 50% 0px;
							letter-spacing: 5px;
							@include breakpoint(max-lg_device){
								font-size: 52px;
								line-height: 1.4;
							}
						}
					}
				}
				.slide-img{
					position: relative;
					width: 50%;
					@include breakpoint(max-lg_device){
						width: 60%;
					}
					@include breakpoint(max-sm_device){
						width: 100%;
					}
					.front-img{
						@include breakpoint(max-xl_device){
							transform: scale(.7);
						}
					}
					.inner-img{
						position: absolute;
						z-index: -1;
						top: 10%;
						left: 5%;
						@include breakpoint(max-xl_device){
							transform: scale(.7);
						}
						@include breakpoint(max-xxs_device){
							transform: scale(.5);
							top: 0;
							left: -20%;
						}
					}
					.sticker-img{
						display: inline-block;
						position: absolute;
						top: 35%;
						left: 35%;
					}
				}
			}
		}
		&.style-02{
			display: flex;
			justify-content: center;
			align-items: center;
			.inner-slide{
				width: calc(100% - 30px);
				margin: 0 auto;
				.slide-content{
					text-align: center;
					span{
						&.category{
							font-size: 24px;
							line-height: 30px;
							letter-spacing: 30px;
							display: block;
							color: $white-color;
							@include breakpoint(max-sm_device){
								font-size: 18px;
								letter-spacing: 10px;
							}
						}
					}
					h4{
						&.product-offer{
							background: linear-gradient(to right, rgb(255, 236, 162) 0%, rgb(234, 255, 122) 100%);
							background-clip: text;
							-webkit-text-fill-color: transparent;
							text-transform: uppercase;
							line-height: 46px;
							font-weight: 700;
							font-size: 54px;
							border-color: rgb(255, 255, 255);
							color: rgb(255, 236, 162);
							transform-origin: 50% 50% 0px;
							letter-spacing: 5px;
							@include breakpoint(max-lg_device){
								font-size: 24px;
								line-height: 1.4;
							}
						}
					}
				}
			}
		}
	}
}

/* ---Main Slider Eight--- */
.main-slider-8{
	.swiper-slide{
		.inner-slide{
			@include flexCenter;
			&.bg-1,
			&.bg-2,
			&.bg-3{
				background-image: url('../images/slider/bg/8-1.jpg');
				background-repeat: no-repeat;
				background-size: cover;
				height: 850px;
				@include breakpoint(max-xl_device){
					height: 580px;
				}
				@include breakpoint(max-sm_device){
					height: 380px;
				}
				&.bg-2{
					background-image: url('../images/slider/bg/8-2.jpg');
				}
			}
			.slide-content{
				text-align: center;
				.slide-img{
					@include breakpoint(max-sm_device){
						width: 123px;
						height: 107px;
						margin: 0 auto;
					}
				}
				h1{
					&.heading{
						font-size: 55px;
						line-height: 65px;
						font-weight: 500;
						letter-spacing: 14px;
						@include breakpoint(max-md_device){
							font-size: 25px;
						}
						@include breakpoint(max-sm_device){
							letter-spacing: 5px;
							line-height: 1.4;
							padding-bottom: 10px;
							font-size: 20px;
						}
					}
				}
				span{
					&.product-collection{
						font-family: 'Playfair Display', serif;
						font-style: italic;
						font-size: 26px;
						line-height: 35px;
						position: relative;
						display: block;
						@extend %basetransition;
						@include breakpoint(max-sm_device){
							font-size: 16px;
							padding-bottom: 20px;
						}
						&:before{
							border-top: 5px solid;
							width: 220px;
							content: "";
							display: inline-block;
							vertical-align: middle;
							margin-right: 25px;
							@include breakpoint(max-md_device){
								width: 80px;
							}
							@include breakpoint(max-xs_device){
								width: 50px;
							}
						}
						&:after{
							border-top: 5px solid;
							width: 220px;
							content: "";
							display: inline-block;
							vertical-align: middle;
							margin-left: 25px;
							@include breakpoint(max-md_device){
								width: 80px;
							}
							@include breakpoint(max-xs_device){
								width: 50px;
							}
						}
					}
				}
				.button-wrap{
					.skudmart-btn{
						@include breakpoint(max-sm_device){
							width: 100px;
							height: 35px;
							line-height: 35px;
							font-size: 12px;
						}
					}
				}
			}
		}
	}
}

/* ---Main Slider Nine--- */
.main-slider-9{
	.swiper-slide{
		.inner-slide{
			&.bg-1,
			&.bg-2,
			&.bg-3{
				background-image: url('../images/slider/bg/9-1.jpg');
				background-repeat: no-repeat;
				background-size: cover;
				height: 100vh;
				&.bg-2{
					background-image: url('../images/slider/bg/9-2.jpg');
				}
				&.bg-3{
					background-image: url('../images/slider/bg/9-3.jpg');
				}
			}
			.inner-slide_wrap{
				display: flex;
				height: 100%;
				align-items: center;
				justify-content: space-between;
				.slide-color_list{
					@include breakpoint(max-sm_device){
						display: none;
					}
					ul{
						li{
							&:not(:last-child){
								a{
									span{
										padding-bottom: 5px;
										margin-bottom: 15px;
									}
								}
							}
							&.active{
								a{
									span{
										&:before{
											opacity: 1;
										}
									}
								}
							}
							a{
								font-size: 32px;
								line-height: 57px;
								letter-spacing: 2px;
								span{
									position: relative;
									display: inline-block;
									&:before{
										background-color: $white-color;
										content: "";
										height: 3px;
										width: 100%;
										position: absolute;
										bottom: 0;
										left: 0;
										opacity: 0;
										@extend %basetransition;
									}
								}
								&:hover{
									span{
										&:before{
											opacity: 1;
										}
									}
								}
							}
						}
					}
				}
				.slide-content{
					position: relative;
					text-align: center;
					width: 50%;
					@include breakpoint(max-md_device){
						width: 75%;
					}
					@include breakpoint(max-sm_device){
						width: 100%;
					}
					.playfair-font{
						font-family: 'Playfair Display', serif;
						font-style: italic;
					}
					span{
						&.category{
							font-size: 24px;
							line-height: 32px;
							letter-spacing: 1px;
							display: block;
						}
					}
					h1{
						&.heading{
							font-size: 86px;
							line-height: 86px;
							letter-spacing: 5px;
							font-weight: 400;
							@include breakpoint(max-xl_device){
								font-size: 50px;
								line-height: 1.2;
							}
							@include breakpoint(max-sm_device){
								font-size: 25px;
							}
						}
						&.different-width{
							max-width: 75%;
							margin: 0 auto;
							@include breakpoint(max-lg_device){
								max-width: 100%;
							}
						}
					}
				}
			}
		}
	}
	.swiper-pagination {
		bottom: auto;
		right: 15px;
		left: auto;
		top: 50%;
		width: auto;
		transform: translateY(-50%);
		@include breakpoint(max-sm_device){
			top: 70%;
		}
		span{
			&.swiper-pagination-bullet{
				border: 2px solid $white-color;
				background-color: transparent;
				transform-origin: top;
				display: block;
				width: 15px;
				height: 15px;
				opacity: 1;
				transition: background 0.3s ease;
			}
			&:not(:last-child){
				margin-bottom: 15px;
			}
			&.swiper-pagination-bullet-active{
				background-color: $white-color;
			}
		}
	}
}

/* ---Main Slider Ten--- */
.slider-with_banner{
	height: 100%;
	.main-slider-10{
		height: 100%;
		@include breakpoint(max-md_device){
			padding-top: 30px;
			height: 450px;
		}
		.swiper-slide{
			overflow: hidden;
			&.swiper-slide-active{
				.inner-slide{
					&:before{
						animation: kenburns-top 5s linear 1 normal both;
					}
				}
			}
			.inner-slide{
				&.bg-1,
				&.bg-2,
				&.bg-3{
					height: 100%;
					position: relative;
					&:before{
						background-image: url('../images/slider/bg/10-1.webp');
						background-repeat: no-repeat;
						background-size: cover;
						content: "";
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
					}
					&.bg-2{
						&:before{
							background-image: url('../images/slider/bg/10-2.webp');
						}
					}
					&.bg-3{
						&:before{
							background-image: url('../images/slider/bg/10-3.webp');
						}
					}
				}
				.slide-content{
					text-align: center;
					position: absolute;
					top: 50%;
					right: 130px;
					transform: translateY(-50%);
					@include breakpoint(max-sm_device){
						width: calc(100% - 60px);
						right: auto;
						left: 30px;
					}
					span{
						&.category{
							font-family: 'Playfair Display', serif;
							font-style: italic;
							font-size: 32px;
							line-height: 57.6px;
							padding-bottom: 20px;
							letter-spacing: 2px;
							display: block;
							@include breakpoint(max-lg_device){
								font-size: 20px;
								line-height: 1.2;
								padding-bottom: 5px;
							}
						}
					}
					h1{
						&.heading{
							font-size: 100px;
							line-height: 100px;
							font-weight: 500;
							letter-spacing: 5px;
							padding-bottom: 35px;
							@include breakpoint(max-xl_device){
								font-size: 50px;
								line-height: 1.4;
								font-weight: 500;
								padding-bottom: 15px;
							}
							@include breakpoint(max-sm_device){
								font-size: 30px;
							}
						}
					}
					.button-wrap{
						.skudmart-btn{
							@include breakpoint(max-lg_device){
								width: 100px;
								height: 40px;
								line-height: 40px;
								font-size: 12px;
							}
						}
					}
					&.style-02{
						right: auto;
						left: 130px;
						@include breakpoint(max-sm_device){
							left: 30px;
						}
					}
				}
			}
		}
	}
	.banner-wrap{
		@include breakpoint(max-md_device){
			padding-top: 30px;
		}
		.banner-item{
			position: relative;
			.single-img{
				.inner-content{
					position: absolute;
					top: 50%;
					left: 90px;
					transform: translateY(-50%);
					text-align: center;
					z-index: 1;
					@include breakpoint(max-xl_device){
						left: 30px;
					}
					h2{
						&.heading{
							font-family: "Pacifico",Sans-serif;
							font-style: italic;
							font-size: 46px;
							line-height: 46px;
							@include breakpoint(max-xl_device){
								padding-bottom: 5px;
								font-size: 20px;
								line-height: 1.2;
							}
						}
					}
					h3{
						&.product-offer{
							font-size: 46px;
							line-height: 46px;
							@include breakpoint(max-xl_device){
								padding-bottom: 5px;
								font-size: 30px;
								line-height: 1.2;
							}
						}
					}
					.button-wrap{
						.skudmart-btn{
							@include breakpoint(max-xl_device){
								width: 100px;
								height: 40px;
								line-height: 40px;
								font-size: 12px;
							}
						}
					}
					&.style-02{
						left: auto;
						right: 50px;
					}
				}
			}
		}
	}
}