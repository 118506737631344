/*----------------------------------------*/
/*  46 - Pages - My Account
/*----------------------------------------*/
.login-area{
    li{
        &:not(:last-child){
            padding-bottom: 30px;
        }
        a{
            display: block;
            font-size: 20px;
            line-height: 24px;
            i{
                vertical-align: text-top;
                padding-right: 10px;
            }
            &.lost-pass{
                color: $primary-color;
                font-size: 16px;
                line-height: 28.8px;
                display: block;
                &:hover{
                    text-decoration: underline;
                }
            }
        }
        ul{
            &.hassub-body{
                display: none;
                &.show{
                    display: block;
                }
                li{
                    .login-form{
                        padding-top: 20px;
                        label{
                            &.input-label{
                                display: block;
                            }
                        }
                        input{
                            &.input-field{
                                border: 1px solid $border-color;
                                padding: 0 25px;
                                width: 100%;
                                height: 50px;
                            }
                        }
                        input[type="checkbox"]:checked + label {
                            &:after {
                                color: #13aff0;
                            }
                        }
                        .vendor-body{
                            display: none;
                        }
                        .vendor-btn{
                            &.active{
                                input[type="checkbox"] + label::after {
                                    opacity: 1;
                                    color: #13aff0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}