/*----------------------------------------*/
/*  35 - Section - Header
/*----------------------------------------*/
/* ---Header Top--- */
.header-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    @include breakpoint(max-md_device){
        justify-content: flex-end;
    }
    .header-shipping{
        font-size: 12px;
        line-height: 21.6px;
        span{
            font-weight: 500;
        }
    }
    .hassub-item{
        li{
            &.has-sub{
                display: inline-block;
                position: relative;
                cursor: pointer;
                &:not(:last-child){
                    margin-right: 15px;
                }
                a{
                    text-transform: uppercase;
                    font-size: 12px;
                    line-height: 21.6px;
                    font-weight: 500;
                    @include breakpoint(max-xs_device){
                        font-size: 10px;
                    }
                    i{
                        font-size: 10px;
                        margin-left: 5px;
                    }
                }
                ul{
                    &.hassub-body{
                        box-shadow: 0 3px 14px 2px rgba(0,0,0,.1);
                        background-color: $white-color;
                        width: 140px;
                        display: none;
                        position: absolute;
                        top: 30px;
                        left: auto;
                        right: 0;
                        z-index: 2;
                        padding: 20px;
                        text-align: right;
                        &.different-width{
                            &-75{
                                width: 75px;
                            }
                            &-100{
                                width: 100px;
                            }
                        }
                    }
                }
            }
        }
    }
    &.style-02{
        @include breakpoint(max-md_device){
            justify-content: space-between;
        }
        ul{
            li{
                &.myaccount-wrap{
                    a{
                        text-transform: uppercase;
                        font-size: 11px;
                    }
                }
            }
        }
    }
}
/* ---Main Header Nav--- */
.main-header_nav{
    align-items: center;
    @include breakpoint(max-lg_device){
        padding-top: 30px;
        padding-bottom: 30px;
    }
}

/* ---Main Header--- */
.main-header{
    .main-nav{
        > ul{
            > li{
                display: inline-block;
                &:not(:last-child){
                    padding-right: 20px;
                }
                > ul{
                    &.skudmart-dropdown{
                        background-color: $white-color;
                        box-shadow: 0 1px 24px 0 rgba(0,0,0,0.09);
                        @extend %basetransition;
                        top: calc(100% + 20px);
                        position: absolute;
                        padding: 30px;
                        width: 230px;
                        opacity: 0;
                        visibility: hidden;
                        z-index: 3;
                        li {
                            @extend %basetransition;
                            &:not(:last-child){
                                padding-bottom: 20px;
                            }
                            a {
                                display: block;
                                font-size: 14px;
                                i{
                                    font-size: 11px;
                                    margin-right: 5px;
                                }
                                span{
                                    &.submenu-indicate{
                                        position: absolute;
                                        top: 50%;
                                        right: 20px;
                                        transform: translateY(-50%);
                                        font-size: 11px;
                                        margin-right: 0;
                                    }
                                }
                            }
                        }
                        li{
                            &.submenu-holder{
                                position: relative;
                                ul{
                                    &.skudmart-submenu{
                                        background-color: $white-color;
                                        width: 230px;
                                        padding: 30px;
                                        top: 0;
                                        left: calc(100% + 30px);
                                        position: absolute;
                                        @extend %basetransition;
                                        opacity: 0;
                                        visibility: hidden;
                                        z-index: 3;
                                        > li{
                                            > a{
                                                display: block;
                                                i{
                                                    font-size: 11px;
                                                    vertical-align: middle;
                                                    margin-right: 5px;
                                                }
                                            }
                                        }
                                    }
                                }
                                &:hover{
                                    > ul{
                                        &.skudmart-submenu{
                                            opacity: 1;
                                            visibility: visible;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &.megamenu-holder{
                    .sku-megamenu_wrap{
                        background-color: $white-color;
                        box-shadow: 0 1px 24px 0 rgba(0,0,0,0.09);
                        position: absolute;
                        padding: 50px;
                        width: 870px;
                        top: calc(100% + 20px);
                        left: 0;
                        z-index: 3;
                        opacity: 0;
                        visibility: hidden;
                        @extend %basetransition;
                        > ul{
                            &.sku-megamenu{
                                display: flex;
                                justify-content: space-between;
                                > li{
                                    width: 50%;
                                    span{
                                        &.title{
                                            @include typography(16px, 16px, 600);
                                            color: $secondary-color;
                                            padding-bottom: 20px;
                                            display: block;
                                        }
                                    }
                                    a{
                                        font-size: 14px;
                                        line-height: 25.2px;
                                        &:hover{
                                            text-decoration: underline;
                                        }
                                    }
                                    ul{
                                        li{
                                            &:not(:last-child){
                                                padding-bottom: 15px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        &.different-width{
                            width: 1170px;
                            @include breakpoint(max-xl_device){
                                width: 1120px;
                            }
                        }
                    }
                    &:hover{
                        .sku-megamenu_wrap{
                            top: 100%;
                            opacity: 1;
                            visibility: visible;;
                        }
                    }
                }
                > a{
                    display: block;
                    padding: 45px 0;
                    font-size: 13px;
                    text-transform: uppercase;
                    font-weight: 500;
                }
                &:hover{
                    ul{
                        &.skudmart-dropdown {
                            top: 100%;
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
            }
        }
    }
    &.white-color{
        .main-nav{
            > ul{
                display: flex;
                > li{
                    &:hover{
                        > a{
                            color: $white-color;
                        }
                    }
                    > a{
                        color: $white-color;
                    }
                }
            }
        }
        .header-right{
            ul{
                li{
                    &.with-border_bottom{
                        input{
                            &.input-field{
                                border-color: $white-color;
                                color: $white-color;
                            }
                            &::placeholder{
                                color: $white-color;
                            }
                        }
                    }
                    a{
                        color: $white-color;
                    }
                    button{
                        i{
                            color: $white-color;
                        }
                    }
                }
            }
        }
    }
    .main-menu{
        .main-nav{
            > ul{
                > li{
                    @include breakpoint(max-lg_device){
                        &.megamenu-holder{
                            .sku-megamenu_wrap{
                                width: 100%;
                            }
                        }
                    }
                    ul{
                        > li{
                            &.submenu-holder{
                                ul{
                                    &.skudmart-submenu{
                                        left: auto;
                                        right: calc(100% + 30px);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        &.menu-style_two{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

    }
}

/* ---Transparent Header--- */
.transparent-header{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
}

/* ---Header Logo--- */
.header-logo{
    text-align: center;
    @include breakpoint(max-lg_device){
        text-align: left;
    }
    img{
        width: 170px;
        @extend %basetransition;
    }
    &.sticky-logo{
        display: none;
    }
}

/* ---Header Right--- */
.header-right{
    ul{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        li{
            &:not(:first-child){
                padding-left: 20px;
                @include breakpoint(max-xs_device){
                    padding-left: 15px;
                }
            }
            a{
                font-size: 18px;
            }
            &.minicart-wrap{
                position: relative;
                span{
                    &.cart-counter{
                        background-color: $secondary-color;
                        color: $white-color;
                        border-radius: 100%;
                        font-size: 10px;
                        width: 18px;
                        height: 18px;
                        line-height: 17px;
                        text-align: center;
                        position: absolute;
                        top: -15px;
                        right: -15px;
                    }
                }
            }
            &.myaccount-wrap{
                a{
                    font-size: 12px;
                    text-transform: uppercase;
                    vertical-align: middle;
                }
            }
        }
    }
}

/* ---Header Middle Form Area--- */
.hm-form_area {
    form{
        &.hm-searchbox {
            background: $white-color;
            border: 1px solid $border-color;
            display: flex;
            position: relative;
            border-radius: 5px;
            width: 100%;
            height: 45px;
            .select-search-category{
                width: auto;
                line-height: 43px;
                height: 43px;
                margin: 0;
                border: 0;
                padding: 0 28px 0 25px;
                font-size: 13px;
                border-radius: 15px 0 0 15px;
                position: relative;
                &:before{
                    right: 30px;
                    top: 25px;
                }
                ul{
                    &.list{
                        height: 440px;
                        overflow-y: auto;
                        overscroll-behavior-x: none;
                    }
                }
                .current{
                    &:after{
                        background-color: $border-color;
                        content: "";
                        position: absolute;
                        display: block;
                        height: 22px;
                        width: 1px;
                        top: 10px;
                        right: -10px;
                        font-size: 13px;
                    }
                }
            }
            input{
                background-color: transparent;
                font-size: 13px;
                height: 45px;
                border: none;
                width: 100%;
                padding: 0 75px 0 33px;
            }
            .search-btn{
                border-left: 1px solid $border-color;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 60px;
                height: 100%;
                i{
                    vertical-align: middle;
                }
            }
        }

    }
}

/* ---Header Bottom--- */
.header-bottom{
    .hb-wrap{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .hm-form_area{
            form{
                &.hm-searchbox{
                    @include breakpoint(max-xs_device){
                        width: calc(100% - 30px);
                        margin: 0 auto;
                    }
                }
            }
        }
        .nice-select{
            @include breakpoint(max-xs_device){
                display: none;
            }
        }
    }
}

/* ---Sticky Header--- */
.header-sticky{
    @include transition(all 300ms ease-in 0s);
    display: none;
    &.sticky {
        @include breakpoint(min-sm_device){
            animation: 800ms ease-in-out 0s normal none 1 running slideInDown;
            box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
            background-color: $white-color;
            position: fixed;
            z-index: 99;
            top: 0;
            left: 0;
            width: 100%;
            display: block;
        }
    }
}

/* ---Header Style Two--- */
.header-style-02{
    .search-box{
        &.with-dark_color{
            width: calc(100% - 50px);
            margin: 0 auto;
            form{
                input{
                    &.input-field{
                        width: 100%;
                        font-size: 22px;
                        padding: 5px 30px 5px 0;
                        border-color: $secondary-color;
                        color: $secondary-color;
                        &:focus{
                            color: $secondary-color;
                        }
                    }
                }
                button{
                    &.search-btn{
                        font-size: 24px;
                    }
                }
            }
        }
    }
    .header-right{
        ul{
            li{
                &:not(:first-child){
                    padding-left: 30px;
                    @include breakpoint(max-xs_device){
                        padding-left: 15px;
                    }
                }
                a{
                    font-size: 22px;
                    @include breakpoint(max-xs_device){
                        font-size: 20px;
                    }
                }
            }
        }
    }
}

/* ---Header Style Four--- */
.header-style-04{
    @include breakpoint(min-md_device){
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 2;
    }
}

/* ---Header Style Five--- */
.header-style-05{
    .main-header_nav{
        padding-top: 35px;
        padding-bottom: 35px;
        .header-logo{
            @include breakpoint(min-md_device){
                text-align: center;
            }
        }
    }
}

/* ---Header Style Six--- */
.header-style-06{
    &.main-header{
        &.white-color{
            .main-nav{
                > ul{
                    display: flex;
                    > li{
                        border-right: 1px solid #666;
                        padding-right: 0;
                        &:hover{
                            background-color: #363b5f;
                        }
                        > a{
                            padding: 25px;
                        }
                    }
                }
            }
        }
    }
}

/* ---Header Style Seven--- */
.header-style-07{
    .main-nav{
        > ul{
            display: flex;
            > li{
                &:not(:last-child){
                    padding-right: 0;
                }
                > a{
                    text-transform: uppercase;
                    font-size: 12px;
                    line-height: 21.6px;
                    padding: 20px 15px;
                    i{
                        vertical-align: text-top;
                        margin-left: 5px;
                    }
                }
            }
        }
    }
    .contact-number{
        display: flex;
        justify-content: flex-end;
        span,
        a{
            font-size: 14px;
            line-height: 23.6px;
            text-transform: uppercase;
        }
        span{
            color: $secondary-color;
        }
    }
}

/* ---Header Style Eight--- */
.header-style-08{
    .header-logo_wrap{
        display: flex;
        .offcanvas-menu_wrap{
            padding-left: 30px;
        }
    }
}

/* ---Offcanvas Menu Button--- */
.offcanvas-menu_btn{
    display: flex;
    align-items: center;
    font-size: 12px;
    text-transform: uppercase;
    i{
        vertical-align: middle;
        font-size: 30px;
        padding-right: 5px;
    }
}

/* ---Vertical Fixed Header--- */
.with-vertical_header{
    .vertical-fixed_header{
        display: none;
        @media (min-width: 1280px){
            background-color: $secondary-color;
            position: fixed;
            width: 100px;
            height: 100%;
            top: 0;
            left: 0;
            text-align: center;
            padding-top: 50px;
            padding-bottom: 50px;
            display: block;
            .inner-header{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;
                align-items: center;
                .fixed-header_logo{
                    width: 20px;
                    margin: 0 auto;
                }
                .header-bottom{
                    writing-mode: tb-rl;
                    display: flex;
                    .copyright{
                        padding-top: 30px;
                        span,
                        a{
                            font-size: 14px;
                        }
                    }
                    .social-link{
                        li{
                            padding-right: 0;
                            &:not(:last-child){
                                padding-bottom: 25px;
                            }
                            a{
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    }
    @media (min-width: 1280px) {
        .main-header{
            display: none;
        }
    }
}

/* ---Vertical Fixed Header Two--- */
.vertical-fixed_header-2{
    display: none;
    @media (min-width: 1280px){
        box-shadow: 0 0 20px 0 rgba(41,44,58,.06);
        background-color: $white-color;
        position: fixed;
        width: 370px;
        height: 100%;
        top: 0;
        left: 0;
        padding-top: 50px;
        padding-bottom: 50px;
        padding-left: 50px;
        padding-right: 50px;
        display: block;
        z-index: 3;
        .main-header{
            text-align: left;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .main-nav{
                > ul{
                    > li{
                        position: static;
                        display: block;
                        padding-right: 0;
                        > a{
                            padding-top: 0;
                            padding-bottom: 15px;
                            font-size: 32px;
                            line-height: 57px;
                            letter-spacing: 2px;
                            font-weight: 400;
                            text-transform: capitalize;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            &.active{
                                color: $primary-color;
                            }
                            i{
                                font-size: 14px;
                            }
                        }
                        .different-width{
                            width: 920px;
                        }
                        .sku-megamenu_wrap,
                        .skudmart-dropdown{
                            top: 0;
                            left: calc(100% + 50px);
                        }
                        &:hover{
                            .sku-megamenu_wrap,
                            .skudmart-dropdown{
                                top: 0;
                                left: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}