/*----------------------------------------*/
/*  12 - Components - Button
/*----------------------------------------*/
/* ---Skudmart's Button Position--- */
.button-wrap{
	display: flex;
	&.position-center{
		justify-content: center;
	}
}

/* ---Group Button--- */
.group-btn{
	display: flex;
	justify-content: center;
	li{
		&:not(:last-child){
			margin-right: 30px;
			@include breakpoint(max-xxs_device){
				margin-right: 15px;
			}
		}
	}
}

/* ---skudmart's Button--- */
.skudmart-btn {
	width: 140px;
	height: 45px;
	line-height: 45px;
	display: block;
	text-align: center;
	font-size: 14px;
	@extend %basetransition;
	&.primary-btn{
		background-color: rgb(29, 29, 29);
		color: $white-color;
	}
	&.secondary-btn{
		background-color: $sunset-orange_color;
	}
	&.aquamarine-btn{
		background-color: $primary-color-2;
		color: $secondary-color;
	}
	&.apache-btn{
		background-color: $primary-color;
		color: $white-color;
	}
	&.aluminium-btn{
		background-color: $aluminium-color;
		color: $white-color;
		&:hover{
			color: $white-color;
		}
	}
	&.valhalla-btn{
		background-color: $valhalla-color;
		color: $white-color;
	}
	&.radical-red_btn{
		background-color: $radical-red_color;
		color: $white-color;
	}
	&.white-btn{
		background-color: $white-color;
		color: $secondary-color;
	}
	// Button With Border
	&.btn-with_border{
		border: 1px solid $secondary-color;
		background-color: transparent;
		color: $secondary-color;
		&:hover {
			background-color: $secondary-color;
			color: $white-color;
		}
	}
	&.white-border_btn{
		border: 1px solid $white-color;
		background-color: transparent;
		color: $white-color;
		&:hover {
			background-color: $white-color;
			color: $secondary-color;
		}
	}
	&.gainsboro-border_btn{
		border: 2px solid $border-color;
		background-color: transparent;
		color: $secondary-color;
		&:hover {
			background-color: $secondary-color;
			border-color: $secondary-color;
			color: $white-color;
		}
	}
	&.secondary-border{
		border: 1px solid $sunset-orange_color;
		color: $sunset-orange_color;
	}
	// Button Hover Color
	&.primary-hover{
		&:hover{
			background-color: $primary-color;
			border-color: $primary-color;
			color: $white-color;
		}
	}
	&.aquamarine-hover{
		&:hover{
			background-color: $primary-color-2;
			border-color: $primary-color-2;
			color: $white-color;
		}
	}
	&.white-hover{
		&:hover{
			background-color: $white-color;
            color: $black-color;
		}
	}
	&.secondary-hover{
		&:hover{
			background-color: $sunset-orange_color;
			border-color: $sunset-orange_color;
			color: $white-color;
		}
	}
	&.nero-hover{
		&:hover{
			background-color: $secondary-color;
			border-color: $secondary-color;
			color: $white-color;
		}
	}
	&.persian-blue-hover{
		&:hover{
			background-color: $persian-blue_color;
			border-color: $persian-blue_color;
			color: $white-color;
		}
	}
	&.gradient-hover{
		&:hover{
			background: linear-gradient(to 270deg, #f64d4d 0%, #c965b7 100%);
			color: $white-color;
		}
	}
	// With Radius
	&.radius-2{
		border-radius: 2px;
	}
	&.radius-10{
		border-radius: 10px;
	}
	&.radius-18{
		border-radius: 18px;
	}
	&.radius-50{
		border-radius: 50px;
	}
}

/* ---Button Size--- */
.fullwidth-btn{
	width: 100%;
	height: 50px;
	line-height: 50px;
}
.btn-xs_size{
	width: 80px;
	height: 35px;
	line-height: 35px;
	font-size: 12px;
}
.btn-sm_size{
	width: 90px;
	height: 40px;
	line-height: 40px;
	font-size: 12px;
}
.btn-md_size{
	width: 120px;
	height: 40px;
	line-height: 40px;
	font-size: 12px;
}
.btn-lg_size{
	width: 150px;
	height: 55px;
	line-height: 55px;
}
.btn-xl_size{
	width: 200px;
	height: 70px;
	line-height: 70px;
}

/* ---Button With Padding--- */
.btn-with_padding{
	font-size: 14px;
	display: block;
	padding: 15px 30px;
	&.primary-btn{
		background-color: rgb(29, 29, 29);
		color: $white-color;
	}
	&.aluminium-btn{
		background-color: $aluminium-color;
		color: $white-color;
		&:hover{
			color: $white-color;
		}
	}
	&.neon-blue_btn{
		background-color: $neon-blue_color;
		color: $white-color;
		&:hover{
			color: $white-color;
		}
	}
	&.fern-btn{
		background-color: $fern-color;
		color: $white-color;
		&:hover{
			color: $white-color;
		}
	}
	&.casablanca-btn{
		background-color: $casablanca-color;
		color: $white-color;
		&:hover{
			color: $white-color;
		}
	}
	&.cerulean-btn{
		background-color: $cerulean-blue_color;
		color: $white-color;
	}
	&.blue-violet_btn{
		background-color: $blue-violet-color;
		color: $white-color;
	}
	&.turquoise-btn{
		background-color: $turquoise-color;
		color: $white-color;
	}
	&.persian-blue{
		background-color: $persian-blue_color;
		border-color: $persian-blue_color;
		color: $white-color;
		&:hover{
			color: $white-color;
		}
	}
	&.btn-with_border{
		border: 1px solid $secondary-color;
		background-color: transparent;
		color: $secondary-color;
		&:hover {
			background-color: $secondary-color;
			color: $white-color;
		}
	}
	&.secondary-border{
		border: 1px solid $sunset-orange_color;
		color: $sunset-orange_color;
	}
	&.gainsboro-border_btn{
		border: 2px solid $border-color;
		background-color: transparent;
		color: $secondary-color;
		&:hover {
			background-color: $secondary-color;
			border-color: $secondary-color;
			color: $white-color;
		}
	}
	&.secondary-hover{
		&:hover{
			background-color: $sunset-orange_color;
			border-color: $sunset-orange_color;
			color: $white-color;
		}
	}
	// Border Style
	&.border-style-double{
		border-style: double;
		border-width: 4px 4px 4px 4px;
		border-color: $secondary-color;
	}
	&.border-style-dotted{
		border-style: dotted;
		border-width: 2px 2px 2px 2px;
		border-color: $secondary-color;
	}
	&.border-style-dashed{
		border-style: dashed;
		border-width: 2px 2px 2px 2px;
		border-color: $secondary-color;
	}
	// Border Width
	&.border-width-2{
		border: 2px solid $secondary-color;
	}
	&.border-width-3{
		border: 3px solid $secondary-color;
	}
	&.border-width-4{
		border: 4px solid $secondary-color;
	}
	&.p-8{
		padding: 8px;
	}
	&.p-12{
		padding: 12px;
	}
	&.p-16{
		padding: 16px;
	}
	&.p-20{
		padding: 20px;
	}
}

/* ---Button Animation--- */
.grow-animation{
	transform: scale(1);
	@extend %basetransition;
	&:hover{
		transform: scale(1.1);
	}
}
.shrink-animation{
	transform: scale(1);
	@extend %basetransition;
	&:hover{
		transform: scale(.9);
	}
}
.pulse-animation{
	@extend %basetransition;
	&:hover{
		animation-name: pulse-animation;
		animation-duration: 1s;
		animation-timing-function: linear;
		animation-iteration-count: infinite;
	}
}
.pulse-grow-animation{
	@extend %basetransition;
	&:hover{
		animation-name: pulse-animation;
		animation-duration: .5s;
		animation-timing-function: linear;
		animation-iteration-count: infinite;
		animation-direction: alternate;
	}
}

@keyframes pulse-animation {
	from {
	  transform: scale3d(1, 1, 1);
	}
	50% {
	  transform: scale3d(1.15, 1.15, 1.15);
	}
	to {
	  transform: scale3d(1, 1, 1);
	}
}

/* ---Additional Button--- */
.read-more{
	position: relative;
	font-size: 14px;
	line-height: 14px;
	font-weight: 500;
	&:before{
		border-top: 1px solid;
		width: 30px;
		content: "";
		display: inline-block;
		vertical-align: middle;
		margin-right: 10px;
	}
	&:after{
		border-top: 1px solid;
		width: 30px;
		content: "";
		display: inline-block;
		vertical-align: middle;
		margin-left: 10px;
	}
	&.without-left_border{
		&:before{
			content: none;
			margin-right: 0;
		}
	}
}

/* ---More Product--- */
.more-product{
	font-size: 12px;
	i{
		vertical-align: middle;
	}
}