/*----------------------------------------*/
/*  38 - Pages - Contact Us
/*----------------------------------------*/
.contact-sidebar{
    @include breakpoint(max-md_device){
        padding-top: 95px;
    }
    ul{
        &.contact-info{
            li{
                display: flex;
                padding-bottom: 30px;
                &:last-child{
                    border-bottom: 1px solid $border-color;
                }
                .single-icon{
                    padding-right: 10px;
                    i{
                        font-size: 20px;
                    }
                }
                .single-content{
                    h5{
                        &.heading{
                            font-size: 14px;
                            line-height: 16.8px;
                            padding-bottom: 10px;
                        }
                    }
                    span{
                        font-size: 13px;
                        line-height: 20px;
                        display: block;
                    }
                }
            }
        }
    }
    ul{
        &.additional-info{
            padding-top: 25px;
            li{
                &:not(:last-child){
                    padding-bottom: 5px;
                }
                span{
                    color: $secondary-color;
                    padding-right: 10px;
                    vertical-align: middle;
                }
                a{
                    font-size: 13px;
                    line-height: 21.6px;
                }
            }
        }
    }
}



.success {
    display: none;
    background: #E1F8ED;
    padding: 20px;
    margin-top: 16px;
    margin-bottom: 16px;
    &.is-active {
      display: block;
    }
  }