/*----------------------------------------*/
/*  43 - Pages - Shop
/*----------------------------------------*/
/* ---Skukmart Toolbar--- */
.skukmart-toolbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 50px;
    @include breakpoint(max-sm_device){
        flex-direction: column;
    }
    .product-page_count{
        @include breakpoint(max-sm_device){
            padding-bottom: 20px;
        }
    }
    .toolbar-right_side{
        display: flex;
        align-items: center;
        @include breakpoint(max-xs_device){
            flex-direction: column;
        }
        @include breakpoint(max-xxs_device){
            width: 100%;
        }
        .product-view-mode{
            @include breakpoint(max-xs_device){
                padding-bottom: 25px;
            }
            a{
                color: $text-color;
                margin-right: 25px;
                &:last-child{
                    @include breakpoint(max-xs_device){
                        margin-right: 0;
                    }
                }
                &.active{
                    color: $primary-color;
                }
            }
        }
        ul{
            &.product-selection{
                display: flex;
                @include breakpoint(max-xxs_device){
                    flex-direction: column;
                    width: 100%;
                }
                > li{
                    &:not(:last-child){
                        margin-right: 15px;
                        @include breakpoint(max-xxs_device){
                            margin-right: 0;
                            margin-bottom: 15px;
                            width: 100%;
                        }
                    }
                    &.filter-selection{
                        border: 1px solid $border-color;
                        padding: 0 20px;
                        height: 41px;
                        line-height: 41px;
                        a{
                            &.filter-btn{
                                @include breakpoint(max-xxs_device){
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                }
                                i{
                                    margin-left: 5px;
                                }
                                &.active{
                                    color: $primary-color;
                                    i{
                                        &:before{
                                            content: '\ea13';
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* ---Filter Body--- */
.filter-body{
    display: none;
    box-shadow: 0 1px 24px 0 rgba(0, 0, 0, 0.09);
    padding: 35px 40px;
    margin-bottom: 30px;
    .custom-col{
        flex: 0 0 20%;
        max-width: 20%;
        @include breakpoint(max-md_device){
            flex: 0 0 50%;
            max-width: 50%;
        }
        @include breakpoint(max-xxs_device){
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    .filter-widgets_area{
        @include breakpoint(max-md_device){
            padding-bottom: 25px;
        }
        h2{
            &.heading{
                font-size: 16px;
                line-height: 19.2px;
                padding-bottom: 20px;
            }
        }
        .widgets-item{
            ul{
                li{
                    &:not(:last-child){
                        padding-bottom: 15px;
                    }
                    a{
                        color: $text-color;
                    }
                    &:hover{
                        a{
                            color: $primary-color;
                        }
                    }
                    &.has-sub{
                        a{
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            cursor: pointer;
                            i {
                                font-size: 14px;
                            }
                        }
                        > ul{
                            &.inner-body{
                                display: none;
                                padding-left: 15px;
                                padding-top: 15px;
                                li{
                                    &:not(:last-child){
                                        padding-bottom: 5px;
                                    }
                                    &:hover{
                                        a{
                                            color: $primary-color;
                                        }
                                    }
                                    a{
                                        display: block;
                                        font-size: 14px;
                                        line-height: 25.2px;
                                        color: $nobel-color;
                                    }
                                }
                            }
                        }
                        &.open{
                            i {
                                &:before{
                                    content: '\ea68';
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* ---Shop Product Wrap--- */
.shop-product-wrap {
    &.grid {
        .list-product_item {
            display: none;
        }
        .product-item {
            display: block;
            margin: 0;
            .single-product {
                overflow: hidden;
                .product-img {
                    img {
                        width: 100%;
                    }
                }
            }
        }
        &.gridview {
            &-2 {
                & > [class*="col-"] {
                    @include flex(0 0 50%);
                    max-width: 50%;
                    @include breakpoint (max-xs_device) {
                        @include flex(0 0 100%);
                        max-width: 100%;
                    }
                }
            }
            &-3 {
                & > [class*="col-"] {
                    @include flex(0 0 33.333333%);
                    max-width: 33.333333%;
                    @include breakpoint (max-lg_device) {
                        @include flex(0 0 50%);
                        max-width: 50%;
                    }
                    @include breakpoint (max-xs_device) {
                        @include flex(0 0 100%);
                        max-width: 100%;
                    }
                }
            }
            &-4 {
                & > [class*="col-"] {
                    @include flex(0 0 25%);
                    max-width: 25%;
                    @include breakpoint(max-lg_device){
                        @include flex(0 0 33.333333%);
                        max-width: 33.333333%;
                    }
                    @include breakpoint(max-lg_device){
                        @include flex(0 0 50%);
                        max-width: 50%;
                    }
                    @include breakpoint (max-xs_device) {
                        @include flex(0 0 100%);
                        max-width: 100%;
                    }
                }
            }
            &-5 {
                & > [class*="col-"] {
                    @include flex(0 0 20%);
                    max-width: 20%;
                }
            }
        }
    }
    &.listview {
        & > [class*="col-"] {
            @include flex(1 0 100%);
            width: 100%;
            max-width: 100%;
            .list-product_item {
                display: block;
                padding-bottom: 40px;
                .single-product {
                    overflow: hidden;
                    display: flex;
                    @include breakpoint(max-sm_device){
                        flex-direction: column;
                    }
                    .product-img {
                        position: relative;
                        width: 415px;
                        @include breakpoint (max-xs_device) {
                            width: 100%;
                        }
                        &:before{
                            background-color: $secondary-color;
                            @extend %basetransition;
                            width: 100%;
                            height: 100%;
                            content: "";
                            top: 0;
                            left: 0;
                            position: absolute;
                            opacity: 0;
                            pointer-events: none;
                            z-index: 1;
                        }
                        a{
                            img{
                                width: 100%;
                                &.secondary-img {
                                    position: absolute;
                                    left: 0;
                                    top: 0;
                                    opacity: 0;
                                    width: 100%;
                                    @extend %basetransition;
                                }
                            }
                        }
                        .add-actions {
                            position: absolute;
                            top: 75%;
                            left: 50%;
                            @extend %basetransition;
                            transform: translate(-50%, -50%);
                            opacity: 0;
                            visibility: hidden;
                            z-index: 2;
                            ul {
                                li {
                                    display: inline-block;
                                    transform: translateY(0);
                                    @extend %basetransition;
                                    &:first-child {
                                        transition-delay: 100ms;
                                    }
                                    a {
                                        background-color: $white-color;
                                        border: 1px solid $border-color;
                                        width: 40px;
                                        height: 40px;
                                        line-height: 42px;
                                        border-radius: 100%;
                                        display: block;
                                        text-align: center;
                                        &:hover{
                                            background-color: $primary-color;
                                            border-color: $primary-color;
                                            color: $white-color;
                                        }
                                        i {
                                            font-size: 16px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .product-content {
                        width: calc(100% - 415px);
                        padding-left: 40px;
                        @include breakpoint(max-sm_device) {
                            width: 100%;
                            padding: 0;
                        }
                        .product-desc_info {
                            @include breakpoint(max-sm_device){
                                padding-top: 25px;
                            }
                            h3 {
                                &.product-name{
                                    padding-bottom: 10px;
                                    font-size: unset;
                                    a{
                                        font-size: 18px;
                                        line-height: 21.6px;
                                        font-weight: 700;
                                    }
                                }
                            }
                            .price-box{
                                padding-bottom: 15px;
                                span{
                                    &.new-price{
                                        color: $charcoal-color;
                                    }
                                }
                            }
                            p{
                                &.short-desc {
                                    color: $charcoal-color;
                                    padding-bottom: 15px;
                                }
                            }
                            .rating-box{
                                padding-bottom: 20px;
                            }
                        }
                        .add-actions-2 {
                            ul {
                                li {
                                    display: inline-block;
                                    &:not(:last-child){
                                        margin-right: 15px;
                                    }
                                    a {
                                        height: 50px;
                                        line-height: 50px;
                                        display: block;
                                        font-size: 14px;
                                        i{
                                            vertical-align: middle;
                                        }
                                        &:hover{
                                            color: $primary-color;
                                        }
                                    }
                                    &.add-to-cart{
                                        a{
                                            background-color: $secondary-color;
                                            text-transform: uppercase;
                                            color: $white-color;
                                            padding: 0 20px;
                                            &:hover {
                                                background-color: $primary-color;
                                                color: $white-color;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &:hover {
                        .product-img {
                            &:before{
                                opacity: 0.5;
                            }
                            a{
                                img{
                                    &.secondary-img{
                                        opacity: 1;
                                    }
                                }
                            }
                            .add-actions {
                                top: 50%;
                                opacity: 1;
                                visibility: visible;
                            }
                        }
                    }
                }
            }
            .product-item {
                display: none;
            }
        }
    }
    & > [class*="col-"] {
        @extend %basetransition;
    }
}

/* ---Shop With Sidebar--- */
.shop-area{
    &.with-sidebar{
        @include breakpoint(max-md_device){
            padding-bottom: 85px;
        }
    }
}

/* ---Shop Vendor--- */
.vendor-profile_area{
    .single-content{
        @include breakpoint(max-md_device){
            padding-top: 20px;
        }
        h1{
            &.heading{
                padding-bottom: 10px;
            }
        }
        .vendor-widgets_area{
            display: flex;
            justify-content: space-between;
            padding-bottom: 10px;
            @include breakpoint(max-xs_device){
                flex-direction: column;
            }
            .widgets-leftside{
                .vendor-widgets{
                    > li{
                        display: inline-block;
                        &:not(:last-child){
                            padding-right: 25px;
                        }
                        i{
                            vertical-align: text-top;
                            margin-right: 5px;
                        }
                        a{
                            color: $text-color;
                            &:hover{
                                color: $primary-color;
                            }
                        }
                    }
                    &.customer-feedback{
                        > li{
                            display: inline-flex;
                            align-items: center;
                            @include breakpoint(max-xxs_device){
                                display: block;
                            }
                            > ul{
                                &.rating-box{
                                    display: flex;
                                    li{
                                        i{
                                            vertical-align: middle;
                                            color: $primary-color;
                                            font-size: 12px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .widgets-rightside{
                .social-link{
                    li{
                        &:not(:last-child){
                            padding-right: 15px;
                        }
                        a{
                            color: $text-color;
                            &:hover{
                                color: $primary-color;
                            }
                        }
                    }
                }
            }
        }
        .vendor-profile_menu{
            background-color: $white-smoke_color;
            ul{
                display: flex;
                @include breakpoint(max-sm_device){
                    flex-direction: column;
                }
                li{
                    display: inline-block;
                    &:not(:last-child){
                        a{
                            border-right: 1px solid rgba(0,0,0,0.1);
                            @include breakpoint(max-sm_device){
                                border-right: 0;
                            }
                        }
                    }
                    a{
                        padding: 15px 25px;
                        display: block;
                        &:hover{
                            background-color: $primary-color;
                            color: $white-color;
                        }
                    }
                    &.vendor-search_box{
                        position: relative;
                        form{
                            input{
                                &.input-field{
                                    background-color: transparent;
                                    padding: 15px 50px 15px 20px;
                                    border: 1px solid $white-smoke_color;
                                    border-left: 0;
                                    &:focus{
                                        border-color: rgba(0,0,0,0.1);
                                    }
                                }
                            }
                            .search-btn{
                                position: absolute;
                                right: 20px;
                                top: 50%;
                                transform: translateY(-50%);
                            }
                        }
                    }
                }
            }
        }
    }
}