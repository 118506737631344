/*----------------------------------------*/
/*  50 - Pages - Elements
/*----------------------------------------*/
/* ---Typography--- */
.typography-section{
    .heading{
        line-height: 1.4;
    }
    h1{
        &.heading{
            max-width: 54%;
            @include breakpoint(max-sm_device){
                max-width: 100%;
            }
        }
    }
    h2{
        &.heading{
            max-width: 42%;
            @include breakpoint(max-sm_device){
                max-width: 100%;
            }
        }
    }
    h3{
        &.heading{
            max-width: 38%;
            @include breakpoint(max-sm_device){
                max-width: 100%;
            }
        }
    }
    h4{
        &.heading{
            max-width: 32%;
            @include breakpoint(max-sm_device){
                max-width: 100%;
            }
        }
    }
    h5{
        &.heading{
            max-width: 27%;
            @include breakpoint(max-sm_device){
                max-width: 100%;
            }
        }
    }
    h6{
        &.heading{
            max-width: 21%;
            @include breakpoint(max-sm_device){
                max-width: 100%;
            }
        }
    }
    p{
        .custom{
            color: $secondary-color;
        }
        del{
            color: #aaa;
        }
    }
}

/* List Styles */
.list-styles_area{
    .list-style_wrap{
        .elements-heading{
            p{
                &.short-desc{
                    max-width: 35%;
                    @include breakpoint(max-sm_device){
                        max-width: 100%;
                    }
                }
            }
        }
        ul{
            &.list-item{
                display: flex;
                @include breakpoint(max-sm_device){
                    flex-wrap: wrap;
                }
                > li{
                    width: 25%;
                    @include breakpoint(max-sm_device){
                        width: 100%;
                    }
                    &:not(:last-child){
                        margin-right: 15px;
                        @include breakpoint(max-sm_device){
                            margin-right: 0;
                            .sub-list_item{
                                padding-bottom: 30px;
                            }
                        }
                    }
                    > ul{
                        &.sub-list_item{
                            li{
                                &:not(:last-child){
                                    padding-bottom: 15px;
                                }
                                i{
                                    color: $secondary-color;
                                    margin-right: 5px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.with-divider{
        .list-style_wrap{
            ul{
                &.list-item{
                    > li{
                        > ul{
                            &.sub-list_item{
                                li{
                                    &:not(:last-child){
                                        border-bottom: 1px solid $border-color;
                                        padding-bottom: 5px;
                                        margin-bottom: 10px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.with-3-column{
        .list-style_wrap{
            ul{
                &.list-item{
                    > li{
                        width: 33.333333%;
                        @include breakpoint(max-sm_device){
                            width: 100%;
                        }
                        > ul{
                            &.sub-list_item{
                                li{
                                    &:not(:last-child){
                                        padding-bottom: 30px;
                                    }
                                    a{
                                        color: $text-color;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &.list-style_wrap{
                ul{
                    &.list-item{
                        > li{
                            &.hover-style{
                                > ul{
                                    &.sub-list_item{
                                        li{
                                            a{
                                                &:hover{
                                                    color: $neon-blue_color;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            &.hover-style-2{
                                > ul{
                                    &.sub-list_item{
                                        li{
                                            &:hover{
                                                i{
                                                    color: $neon-blue_color;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            &.hover-style-3{
                                > ul{
                                    &.sub-list_item{
                                        li{
                                            &:hover{
                                                a,
                                                i{
                                                    color: $neon-blue_color;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            &.specific-font_size{
                                font-size: 18px;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* ---Elements Button--- */
.element-button_wrap{
    .elements-heading{
        p{
            &.short-desc{
                max-width: 44%;
                @include breakpoint(max-sm_device){
                    max-width: 100%;
                }
            }
        }
    }
    ul{
        &.button-list{
            display: flex;
            @include breakpoint(max-sm_device){
                flex-wrap: wrap;
            }
            > li{
                width: 25%;
                @include breakpoint(max-sm_device){
                    width: 100%;
                }
                &:not(:last-child){
                    @include breakpoint(max-sm_device){
                        padding-bottom: 30px;
                    }
                }
                > ul{
                    &.sub-list_button{
                        li{
                            &:not(:last-child){
                                padding-bottom: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* ---Icon Box--- */
.iconbox-item{
    text-align: center;
    @include breakpoint(max-md_device){
        padding-bottom: 25px;
    }
    .single-icon{
        i{
            color: $sunset-orange_color;
            font-size: 60px;
        }
    }
    .single-content{
        h2{
            &.heading{
                font-size: 18px;
                line-height: 21.6px;
            }
        }
        span{
            &.counter-title{
                color: $secondary-color;
                font-size: 16px;
                line-height: 32px;
            }
        }
        a{
            &.readmore{
                color: $secondary-color;
                font-size: 10px;
                line-height: 10px;
                text-transform: uppercase;
                text-decoration: underline;
                font-weight: 500;
            }
        }
    }
    &.single-icon_bg{
        .single-icon{
            display: flex;
            justify-content: center;
            .inner-icon{
                background-color: $turquoise-color;
                border-radius: 100%;
                width: 90px;
                height: 90px;
                line-height: 90px;
                text-align: center;
            }
            i{
                vertical-align: middle;
                color: $white-color;
                font-size: 36px;
            }
        }
        .single-content{
            p{
                &.short-desc{
                    font-size: 12px;
                    line-height: 24px;
                }
            }
        }
        &.radical-red_color{
            .single-icon{
                .inner-icon{
                    background-color: $radical-red_color;
                    width: 80px;
                    height: 80px;
                    line-height: 80px;
                }
            }
        }
    }
}
.iconbox-style-02{
    .iconbox-item{
        display: flex;
        text-align: left;
        .single-icon{
            i{
                color: $persian-blue_color;
                font-size: 60px;
            }
        }
        .single-content{
            padding-left: 50px;
        }
    }
}
.iconbox-style-03{
    .iconbox-item{
        margin-bottom: 30px;
        .inner-iconbox_item{
            padding-top: 50px;
            padding-left: 35px;
            padding-right: 35px;
        }
        &.single-icon_bg{
            .single-icon{
                .inner-icon{
                    width: 150px;
                    height: 150px;
                    line-height: 150px;
                    @extend %basetransition;
                }
            }
        }
        &.valhalla-color{
            .single-icon{
                .inner-icon{
                    background-color: $valhalla-color;
                    color: rgba(255,255,255,.8);
                }
            }
            .single-content{
                h2,
                p{
                    color: rgba(255,255,255,.8);
                }
            }
        }
        .single-content{
            h2{
                &.heading{
                    font-size: 24px;
                    line-height: 28.8px;
                }
            }
            p{
                &.short-desc{
                    font-size: 14px;
                }
            }
        }
        .button-wrap{
            a{
                &.skudmart-btn{
                    text-transform: uppercase;
                    height: 90px;
                    line-height: 90px;
                }
            }
        }
        &:hover{
            &.single-icon_bg{
                .single-icon{
                    .inner-icon{
                        background-color: $radical-red_color;
                    }
                }
                .button-wrap{
                    a{
                        &.skudmart-btn{
                            background-color: $radical-red_color;
                            color: $white-color;
                        }
                    }
                }
            }
        }
    }
}
.iconbox-style-04{
    .iconbox-item{
        margin-bottom: 30px;
        .inner-iconbox_item{
            padding-top: 50px;
            padding-left: 35px;
            padding-right: 35px;
        }
        .single-icon{
            display: flex;
            justify-content: center;
            .inner-icon{
                box-shadow: 0 0 55px 0 #dadada;
                border-radius: 100%;
                width: 100px;
                height: 100px;
                line-height: 100px;
                text-align: center;
                i{
                    vertical-align: middle;
                    font-size: 30px;
                }
            }
        }
        .single-content{
            h2{
                &.heading{
                    font-size: 24px;
                    line-height: 28.8px;
                }
            }
            p{
                &.short-desc{
                    font-size: 14px;
                }
            }
        }
    }
}
.our-work-process{
    .work-process-item{
        display: flex;
        @include breakpoint(max-md_device){
            flex-direction: column;
        }
        .work-process-img{
            width: 50%;
            @include breakpoint(max-md_device){
                width: 100%;
            }
        }
        .work-process-content{
            padding-top: 45px;
            padding-left: 50px;
            padding-right: 50px;
            width: 50%;
            @include breakpoint(max-md_device){
                width: 100%;
            }
            @include breakpoint(max-xs_device){
                padding-left: 15px;
                padding-right: 15px;
            }
            h2{
                &.heading{
                    font-size: 32px;
                    line-height: 32px;
                }
            }
            .iconbox-item{
                display: flex;
                @include breakpoint(max-xs_device){
                    flex-direction: column;
                    align-items: center;
                }
                .single-icon{
                    .inner-icon{
                        background-color: $radical-red_color;
                        border-radius: 100%;
                        width: 80px;
                        height: 80px;
                        line-height: 80px;
                        text-align: center;
                        @include breakpoint(max-xs_device){
                            margin-bottom: 25px;
                        }
                        i{
                            vertical-align: middle;
                            color: $white-color;
                            font-size: 30px;
                        }
                    }
                }
                .single-content{
                    text-align: left;
                    padding-left: 30px;
                    @include breakpoint(max-xs_device){
                        padding-left: 0;
                        text-align: center;
                    }
                    h3{
                        &.heading{
                            font-size: 20px;
                            line-height: 24px;
                        }
                    }
                }
            }
        }
    }
}

/* ---Pricing Tables--- */
.pricing-table_item{
    border: 1px solid $border-color;
    text-align: center;
    padding: 45px 50px;
    position: relative;
    @include breakpoint(max-md_device){
        margin-bottom: 30px;
    }
    .lable{
        position: absolute;
        top: 0;
        right: 0;
    }
    .single-icon{
        padding-bottom: 5px;
        i{
            font-size: 24px;
        }
    }
    h3{
        &.pricing-table_title{
            font-size: 18px;
            line-height: 21.6px;
            &.persian-blue_color{
                color: $persian-blue_color;
            }
        }
    }
    .price-box{
        &.with-border{
            border-bottom: 1px solid $border-color;
            padding-bottom: 15px;
        }
        span{
            color: $secondary-color;
            font-size: 32px;
            line-height: 57.6px;
            font-weight: 700;
            em{
                font-size: 16px;
                font-weight: 400;
                font-style: normal;
                vertical-align: middle;
            }
        }
    }
    ul{
        &.pricing-feature{
            padding-top: 35px;
            li{
                color: $secondary-color;
                &:not(:last-child){
                    padding-bottom: 10px;
                }
                &.with-skicker{
                    font-weight: 700;
                    position: relative;
                    &:after{
                        content: 'hot';
                        background-color: #EA622B;
                        color: #fff;
                        font-size: 11px;
                        padding: 3px 8px;
                        position: absolute;
                        line-height: 1;
                        top: -15px;
                    }
                }
                &.with-skicker-2{
                    font-weight: 700;
                    position: relative;
                    &:after{
                        content: 'new';
                        background-color: green;
                        color: #fff;
                        font-size: 11px;
                        padding: 3px 8px;
                        position: absolute;
                        line-height: 1;
                        top: -15px;
                    }
                }
            }
        }
    }
}
.pricing-table_area{
    &.style-two{
        h3{
            &.pricing-table_title{
                font-size: 18px;
                line-height: 21.6px;
                &.persian-blue_color{
                    color: $persian-blue_color;
                }
            }
        }
        .pricing-table_item{
            text-align: left;
            .price-box{
                span{
                    font-size: 66px;
                    line-height: 66px;
                    &.persian-blue_color{
                        color: $persian-blue_color;
                        sup{
                            &.price-prefix{
                                font-size: 16px;
                                color: $text-color;
                                vertical-align: super;
                            }
                        }
                        em{
                            color: $text-color;
                        }
                    }
                }
            }
            ul{
                &.pricing-feature{
                    li{
                        color: $text-color;
                        i{
                            color: $persian-blue_color;
                            margin-right: 5px;
                        }
                    }
                }
            }
        }
    }
    &.style-three{
        .pricing-table_item{
            h3{
                &.pricing-table_title{
                    font-size: 24px;
                    line-height: 28.8px;
                    &.persian-blue_color{
                        color: $persian-blue_color;
                    }
                }
            }
            .price-box{
                span{
                    font-size: 46px;
                    line-height: 46px;
                    &.persian-blue_color{
                        color: $persian-blue_color;
                        sup{
                            &.price-prefix{
                                font-size: 16px;
                                color: $persian-blue_color;
                                vertical-align: super;
                            }
                        }
                        em{
                            color: $text-color;
                        }
                    }
                }
            }
            .button-wrap{
                a{
                    &.skudmart-btn{
                        border-radius: 35px 35px 35px 35px;
                    }
                }
            }
        }
    }
}

/* ---Team Member--- */
.team-member_item{
    @include breakpoint(max-md_device){
        padding-bottom: 30px;
    }
    .single-img{
        img{
            width: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
    .single-content{
        h3{
            &.member-name{
                padding-top: 10px;
                a{
                    font-size: 20px;
                    line-height: 24px;
                }
            }
        }
        span{
            &.occupation{
                color: $secondary-color;
                padding-top: 5px;
                display: block;
                font-size: 12px;
                line-height: 12px;
            }
        }
    }
    &.hover-style{
        .single-img{
            position: relative;
            &:before{
                background-color: $primary-color;
                width: 100%;
                height: 100%;
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                pointer-events: none;
                @extend %basetransition;
            }
            .add-action{
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                opacity: 0;
                visibility: hidden;
                @extend %basetransition;
                ul{
                    &.social-link{
                        li{
                            &:not(:last-child){
                                padding-right: 10px;
                            }
                            a{
                                border: 1px solid $border-color;
                                color: $white-color;
                                text-align: center;
                                border-radius: 100%;
                                width: 35px;
                                height: 35px;
                                line-height: 35px;
                                &:hover{
                                    background-color: $primary-color;
                                    border-color: $primary-color;
                                }
                            }
                        }
                    }
                }
            }
        }
        &:hover{
            .single-img{
                &:before{
                    opacity: .9;
                }
                .add-action{
                    bottom: 30px;
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
    &.hover-style-2{
        .single-img{
            position: relative;
            overflow: hidden;
            img{
                @extend %basetransition;
                filter: grayscale(100);
            }
            .add-action{
                position: absolute;
                bottom: 0;
                left: -80px;
                opacity: 0;
                visibility: hidden;
                @extend %basetransition;
            }
        }
        &:hover{
            .single-img{
                img{
                    filter: grayscale(0);
                }
                .add-action{
                    left: 0;
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
    &.hover-style-3{
        .single-img{
            position: relative;
            img{
                @extend %basetransition;
                filter: grayscale(100);
            }
            &:before{
                background-color: rgba(33,33,33,.5);
                width: calc(100% - 40px);
                height: calc(100% - 40px);
                content: "";
                position: absolute;
                top: 20px;
                left: 20px;
                @extend %basetransition;
                opacity: 0;
                visibility: hidden;
                pointer-events: none;
                z-index: 1;
            }
            .add-action{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                opacity: 0;
                visibility: hidden;
                @extend %basetransition;
                text-align: center;
                width: 100%;
                z-index: 2;
                ul{
                    > li{
                        @extend %basetransition;
                        transform: translateY(50px);
                        opacity: 0;
                        visibility: hidden;
                        &:nth-child(1) {
                            transition-delay: 100ms;
                        }
                        &:nth-child(2) {
                            transition-delay: 150ms;
                        }
                        &:nth-child(3) {
                            transition-delay: 200ms;
                        }
                        &.member-name{
                            a{
                                font-size: 20px;
                            }
                        }
                        &.occupation{
                            span{
                                font-size: 12px;
                            }
                        }
                        &.social-link_wrap{
                            > ul{
                                &.social-link{
                                    li{
                                        &:not(:last-child){
                                            padding-right: 10px;
                                        }
                                        a{
                                            border: 1px solid $border-color;
                                            color: $white-color;
                                            text-align: center;
                                            border-radius: 100%;
                                            width: 35px;
                                            height: 35px;
                                            line-height: 35px;
                                            &:hover{
                                                background-color: $primary-color;
                                                border-color: $primary-color;
                                            }
                                        }
                                    }
                                }
                            }
                        }
    
                    }
                }
            }
        }
        &:hover{
            .single-img{
                img{
                    filter: grayscale(0);
                }
                &:before{
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    opacity: 1;
                    visibility: visible;
                }
                .add-action{
                    opacity: 1;
                    visibility: visible;
                    ul{
                        li{
                            transform: translateY(0);
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
            }
        }
    }
    &.hover-style-4{
        .single-img{
            .add-action{
                top: auto;
                transform: translateX(-50%);
                bottom: 0;
            }
        }
        &:hover{
            .single-img{
                .add-action{
                    bottom: 30px;
                }
            }
        }
    }
}

/* ---Typed Heading--- */
.typed-heading{
    h2{
        &.heading{
            font-size: 28px;
            line-height: 1.2;
        }
    }
    h5{
        &.cd-headline{
            font-size: 16px;
            line-height: 28.8px;
            font-weight: 400;
            span{
                b{
                    &.is-visible{
                        color: $radical-red_color;
                        font-style: normal;
                    }
                }
            }
        }
    }
}

/* ---Toggle--- */
.toggle-area{
    .elements-heading{
        p{
            &.different-width{
                max-width: 40%;
                @include breakpoint(max-sm_device){
                    max-width: 100%;
                }
            }
        }
    }
}
.toggle-item{
    > ul{
        > li{
            border-bottom: 1px solid $border-color;
            padding-bottom: 10px;
            margin-bottom: 10px;
            a{
                color: $secondary-color;
                padding-left: 10px;
                padding-right: 10px;
                &:hover{
                    color: $primary-color;
                }
            }
            > ul{
                &.toggle-body{
                    display: none;
                    padding-left: 15px;
                    padding-right: 15px;
                    padding-top: 5px;
                }
            }
            &.open{
                i{
                    &.arrows-1_small-triangle-right{
                        &:before{
                            content: "\eac5";
                        }
                    }
                    &.arrows-1_square-right{
                        &:before{
                            content: "\eac6";
                        }
                    }
                }
            }
        }
    }
    &.style-two{
        > ul{
            > li{
                a{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
            }
        }
    }
}

/* ---Google Map--- */
.google-map_area{
    h2{
        &.heading{
            font-size: 20px;
            line-height: 20px;
        }
    }
    .map-size {
        width: 100%;
        height: 300px;
    }
}

/* ---Counter--- */
.counter-item{
    @include breakpoint(max-md_device){
        padding-bottom: 25px;
    }
    .count-wrap{
        &.style-two{
            display: flex;
            justify-content: center;
            > span,
            h3{
                color: $secondary-color;
                font-size: 35px;
                line-height: 35px;
            }
        }
    }
}

/* ---Progress Bar--- */

.progress-charts{
    overflow: hidden;
    span{
        &.skill-name{
            @include typography(14px, 24.3px);
            display: block;
        }
    }
    .progress{
        height: 5px;
        border-radius: 5px;
        overflow: visible;
        margin: 20px 0;
        &.height{
            &-4{
                height: 4px;
            }
            &-8{
                height: 8px;
            }
            &-10{
                height: 10px;
            }
        }
        .progress-bar{
            position: relative;
            overflow: visible;
            span{
                &.percent-label{
                    position: absolute;
                    right: 0;
                    top: -20px;
                    color: #333;
                }
            }
        }
    }
}