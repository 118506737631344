/*----------------------------------------*/
/*  52 - Pages - Newsletter
/*----------------------------------------*/
.newsletter-item{
    display: flex;
    @include breakpoint(max-sm_device){
        flex-direction: column;
        align-items: center;
    }
	h3{
		&.heading{
			width: 35%;
			font-size: 36px;
            line-height: 36px;
            @include breakpoint(max-md_device){
                font-size: 26px;
                line-height: 26px;
            }
            @include breakpoint(max-sm_device){
                padding-bottom: 15px;
                text-align: center;
                width: 100%;
            }
		}
	}
	.subscribe-widget{
        width: 65%;
        @include breakpoint(max-sm_device){
            width: 100%;
        }
		li{
			&.subscribe-form_wrap{
				form{
					position: relative;
					input{
						&.input-field{
							background-color: transparent;
							width: 100%;
							padding-right: 90px;
							font-size: 14px;
							line-height: 1.8;
							&::placeholder{
								color: $white-color;
								opacity: 1;
							}
                        }
                        &.with-border_bottom{
                            border-top: 0;
							border-left: 0;
							border-right: 0;
                        }
					}
					button{
						&.subscribe-btn{
							position: absolute;
							top: 0;
							right: 0;
						}
					}
				}
			}
		}
    }
    &.style-02{
        height: 100%;
        align-items: center;
        .inner-item{
            background-color: $white-color;
            border: 1px solid $border-color;
            padding-top: 65px;
            padding-bottom: 70px;
            width: 970px;
            margin: 0 auto;
            @include breakpoint(max-sm_device){
                width: calc(100% - 30px);
            }
            h3{
                &.heading{
                    font-size: 24px;
                    line-height: 24px;
                    width: 100%;
                    text-align: center;
                    @include breakpoint(max-xxs_device){
                        line-height: 1.4;
                    }
                }
            }
            .subscribe-widget{
                width: calc(100% - 200px);
                margin: 0 auto;
                @include breakpoint(max-md_device){
                    width: calc(100% - 30px);
                }
                li{
                    &.subscribe-form_wrap{
                        form{
                            input{
                                &.input-field{
                                    height: 70px;
                                    padding-left: 30px;
                                    padding-right: 120px;
                                    @include breakpoint(max-xxs_device){
                                        padding-left: 15px;
                                        padding-right: 90px;
                                    }
                                }
                                &::placeholder{
                                    color: inherit;
                                    opacity: 1;
                                }
                            }
                        }
                        button{
                            &.subscribe-btn{
                                top: 50%;
                                right: 30px;
                                transform: translateY(-50%);
                                @include breakpoint(max-md_device){
                                    right: 15px;
                                }
                                &:hover{
                                    color: $primary-color;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.style-03{
        .inner-item{
            width: 100%;
            h3{
                &.heading{
                    width: 100%;
                    font-size: 32px;
                    line-height: 32px;
                }
            }
            .subscribe-widget{
                width: 100%;
                li{
                    &.subscribe-form_wrap{
                        form{
                            input{
                                &.input-field{
                                    border-color: $secondary-color;
                                    &::placeholder{
                                        color: $secondary-color;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.style-04{
        justify-content: center;
        text-align: center;
        text-align: left;
        margin: 0 auto;
        @include breakpoint(min-lg_device){
            width: 770px;
        }
        .inner-item{
            width: 100%;
            h3{
                &.heading{
                    color: $white-color;
                    width: 100%;
                    font-size: 16px;
                    line-height: 16px;
                }
            }
            .subscribe-widget{
                width: 100%;
                li{
                    &.subscribe-form_wrap{
                        form{
                            input{
                                &.input-field{
                                    background-color: $white-color;
                                    height: 70px;
                                    padding-left: 30px;
                                    padding-right: 75px;
                                }
                                &::placeholder{
                                    color: $secondary-color;
                                    opacity: 1;
                                }
                            }
                        }
                        button{
                            &.subscribe-btn{
                                height: 70px;
                                top: 50%;
                                right: 30px;
                                transform: translateY(-50%);
                                text-align: center;
                                text-transform: uppercase;
                            }
                        }
                    }
                }
            }
        }
    }
    &.style-05{
        justify-content: center;
        text-align: center;
        .inner-item{
            width: calc(100% - 400px);
            margin: 0 auto;
            @include breakpoint(max-md_device){
                width: 100%;
            }
            h3{
                &.heading{
                    width: 100%;
                    font-size: 36px;
                    line-height: 36px;
                    @include breakpoint(max-xxs_device){
                        font-size: 30px;
                    }
                }
            }
            p{
                &.short-desc{
                    color: $secondary-color;
                    max-width: 75%;
                    margin: 0 auto;
                    @include breakpoint(max-lg_device){
                        max-width: 100%;
                    }
                }
            }
            .subscribe-widget{
                width: 100%;
                li{
                    &.subscribe-form_wrap{
                        form{
                            input{
                                &.input-field{
                                    height: 70px;
                                    padding-left: 30px;
                                    padding-right: 150px;
                                }
                                &::placeholder{
                                    color: inherit;
                                    opacity: 1;
                                }
                            }
                        }
                        button{
                            &.subscribe-btn{
                                background-color: $secondary-color;
                                color: $white-color;
                                height: 70px;
                                padding: 0 15px;
                                top: 50%;
                                right: 0;
                                transform: translateY(-50%);
                                text-align: center;
                                text-transform: uppercase;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* ---Newsletter Area Two--- */
.newsletter-area{
    &.bg-1{
        background-image: url('../images/newsletter/bg/1.webp');
        background-repeat: no-repeat;
        background-size: cover;
        height: 510px;
    }
    &.bg-2{
        background-image: url('../images/newsletter/bg/2.webp');
        background-repeat: no-repeat;
        background-size: cover;
        height: 560px;
    }
    &.bg-3{
        background-image: url('../images/newsletter/bg/3.webp');
        background-repeat: no-repeat;
        background-color: #e1f9ee;
        background-position: 80% 58%;
        height: 265px;
    }
    &.bg-4{
        background-image: url('../images/newsletter/bg/4.webp');
        background-repeat: no-repeat;
        height: 250px;
    }
}