/*----------------------------------------*/
/*  10 - Components - Navigation
/*----------------------------------------*/
/* ---Navigation and pagination wrap--- */
.nav-pagination_wrap{
	.swiper-pagination{
		display: none;
		@include breakpoint(max-md_device){
			display: block;
		}
		span{
			&.swiper-pagination-bullet-active{
				background-color: $sunset-orange_color;
			}
		}
	}
	.swiper-button-next,
	.swiper-button-prev{
		display: flex;
		@include breakpoint(max-md_device){
			display: none;
		}
	}
	.swiper-button-next{
		right: 80px;
		@include breakpoint(max-xl_device){
			right: 40px;
		}
	}
	.swiper-button-prev{
		left: 80px;
		@include breakpoint(max-xl_device){
			left: 40px;
		}
	}
	.swiper-button-disabled{
		display: none;
	}
}
/* ---Swiper Arrow--- */
.swiper-button-next,
.swiper-button-prev{
	background-color: rgba(0, 0, 0, 0.5);
	color: $white-color;
	width: 70px;
	height: 70px;
	line-height: 70px;
	@extend %basetransition;
	&:after{
		font-size: 25px;
	}
	&:hover{
		background-color: $secondary-color;
	}
}
.darkNav{
	.swiper-button-next,
	.swiper-button-prev {
		border-color: $secondary-color;
		color: $secondary-color;
	}
}

/* ---Swiper Circle Arrow--- */
.circle-arrow{
	.swiper-button-next,
	.swiper-button-prev{
		background-color: transparent;
		border: 1px solid $border-color;
		border-radius: 100%;
		width: 60px;
		height: 60px;
		line-height: 60px;
		opacity: 0;
		visibility: hidden;
		@extend %basetransition;
		&:after{
			font-size: 16px;
		}
		&:hover{
			background-color: $white-color;
			border-color: $white-color;
			color: $black-color;
		}
	}
	&:hover{
		.swiper-button-next,
		.swiper-button-prev{
			opacity: 1;
			visibility: visible;
		}
	}
	&.arrow-rounded{
		.swiper-button-next,
		.swiper-button-prev{
			border-radius: 0;
		}
	}
}

/* ---With Background--- */
.with-bg_white{
	.swiper-button-next,
	.swiper-button-prev{
		background-color: $white-color;
		border: 0;
		color: $secondary-color;
		&:hover{
			background-color: $secondary-color;
			color: $white-color;
		}
	}
}

/* ---Swiper Arrow Size--- */
.swiper-arrow_sm{
	.swiper-button-next,
	.swiper-button-prev{
		width: 50px;
		height: 50px;
		line-height: 50px;
	}
}

/* ---Verticle Arrows--- */
.verticle-arrow{
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
	z-index: 1;
	.swiper-button-next{
		display: block;
	}
}

/* ---Swiper Arrow Wrap--- */
.swiper-arrow_wrap{
	&.bottom-left{
		position: absolute;
		bottom: 20px;
		left: 0;
		.swiper-button-next{
			left: 70px;
		}
		.swiper-button-prev{
			left: 0;
		}
	}
	&.without-background{
		.swiper-button-next,
		.swiper-button-prev{
			background-color: transparent;
			color: $very-light-grey_color;
			width: auto;
			height: auto;
			&:after{
				font-size: 40px;
			}
			&:hover{
				color: $secondary-color;
			}
		}
	}
}

/* ---Swiper Pagination--- */
.swiper-pagination{
	&.white-color{
		span{
			&.swiper-pagination-bullet{
				background-color: $white-color;
				@extend %basetransition;
				&:hover{
					opacity: 1;
				}
			}
			&.swiper-pagination-bullet-active{
				opacity: 1;
			}
		}
	}
	&.black-color{
		span{
			&.swiper-pagination-bullet{
				background-color: $black-color;
				@extend %basetransition;
				&:hover{
					opacity: 1;
				}
			}
			&.swiper-pagination-bullet-active{
				opacity: 1;
			}
		}
	}
	&.dark-border{
		span{
			&.swiper-pagination-bullet{
				width: 15px;
				height: 15px;
			}
			&.swiper-pagination-bullet-active{
				border: 2px solid $secondary-color;
				background-color: transparent;
			}
		}
	}
	&.vertical-pagination{
		top: 50%;
		bottom: auto;
		left: auto;
		right: 80px;
		transform: translateY(-50%);
		width: auto;
		@include breakpoint(max-md_device){
			right: 30px;
		}
		span{
			&.swiper-pagination-bullet{
				margin: 30px 0;
				display: block;
				width: 15px;
				height: 15px;
				position: relative;
				@include breakpoint(max-md_device){
					width: 10px;
					height: 10px;
					margin: 25px 0;
				}
				&:before{
					content: '';
					position: absolute;
					left: calc(-1*(16px - 4px));
					top: calc(-1*(16px - 4px));
					bottom: calc(-1*(16px - 4px));
					right: calc(-1*(16px - 4px));
					border: 1px solid #1d1d1d;
					border-radius: 50%;
					opacity: 0;
					transform: scale(.5);
					@extend %basetransition;
				}
				&:after{
					content: '';
					position: absolute;
					left: calc(-1*(16px + 4px));
					top: calc(-1*(16px + 4px));
					bottom: calc(-1*(16px + 4px));
					right: calc(-1*(16px + 4px));
					border: 1px solid #1d1d1d;
					border-radius: 50%;
					opacity: 0;
					transform: scale(.5);
					@extend %basetransition;
				}
				&.swiper-pagination-bullet-active{
					&:before{
						opacity: 1;
						transform: scale(1);
						@include breakpoint(max-md_device){
							transform: scale(.6);
						}
					}
					&:after{
						opacity: 1;
						transform: scale(1);
						@include breakpoint(max-md_device){
							transform: scale(.6);
						}
					}
				}
			}
		}
	}
	&.with-position_relative{
		position: relative;
		padding-top: 45px;
		bottom: 0;
	}
}

/* ---Slick Arrow--- */
.slick-arrow{
	background-color: $white-color;
	width: 35px;
	height: 35px;
	line-height: 42px;
	text-align: center;
	position: absolute;
	top: 50%;
	left: 0;
	font-size: 14px;
	z-index: 1;
	transform: translateY(-50%);
	&.slick-next{
		left: auto;
		right: 0;
	}
}