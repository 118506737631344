/*----------------------------------------*/
/*  21 - Components - Brand
/*----------------------------------------*/
.brand-common_style{
    .swiper-wrapper{
        align-items: center;
        .swiper-slide{
            .brand-item{
                text-align: center;
                img{
                    @extend %basetransition;
                    filter: grayscale(100);
                    &:hover{
                        filter: grayscale(0);
                    }
                }
            }
        }
    }
}