/*----------------------------------------*/
/*  21 - Components - Testimonials
/*----------------------------------------*/
.testimonial-item{
    display: flex;
    .single-content{
        padding-left: 30px;
        @include breakpoint(max-sm_device){
            padding-left: 0;
        }
        h3{
            &.user-name{
                span{
                    &:after{
                        content: "\ea7a";
                        font-family: 'LaStudioIcons';
                        display: block;
                        color: $primary-color;
                        font-size: 20px;
                        padding-top: 10px;
                    }
                }
            }
        }
        h4{
            &.user-name-2{
                font-size: 16px;
                line-height: 28.8px;
            }
        }
    }
    &.style-2{
        flex-direction: column;
        align-items: center;
        text-align: center;
        .single-img{
            &:after{
                content: "\ea7a";
                font-family: 'LaStudioIcons';
                display: block;
                font-size: 20px;
                margin-top: -15px;
            }
            img{
                width: 80px;
                height: 80px;
                object-fit: cover;
                border-radius: 100%;
            }
        }
    }
    &.style-3{
        font-family: 'Playfair Display', serif;
        display: flex;
        justify-content: center;
        padding-top: 30px;
        padding-bottom: 30px;
        width: 770px;
        margin: 0 auto;
        @include breakpoint(max-sm_device){
            flex-direction: column;
            width: 100%;
        }
        .single-img{
            @include breakpoint(max-sm_device){
                width: 120px;
                margin: 0 auto;
            }
            img{
                border-radius: 100%;
                object-fit: cover;
                width: 100%;
            }
        }
        .single-content{
            padding-left: 60px;
            padding-right: 0;
            @include breakpoint(max-sm_device){
                text-align: center;
                padding-top: 25px;
                padding-left: 0;
            }
            p{
                &.short-desc{
                    max-width: 95%;
                }
            }
        }
    }
    &.style-4{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        text-align: center;
        .single-content{
            p{
                &.user-comment{
                    font-family: 'Playfair Display', serif;
                    font-style: italic;
                    font-size: 32px;
                    line-height: 56px;
                    @include breakpoint(max-xxs_device){
                        font-size: 18px;
                        line-height: 1.8;
                    }
                }
            }
            h4{
                &.user-name-2{
                    font-size: 14px;
                    line-height: 25.2px;
                }
            }
        }
    }
}
/* ---Testimonial Area--- */
.testimonial-area{
    &.bg-1{
        background-image: url('../images/testimonials/bg/1.webp');
        background-repeat: no-repeat;
        background-size: cover;
        height: 280px;
    }
    &.bg-2{
        background-image: url('../images/testimonials/bg/2.webp');
        background-repeat: no-repeat;
        background-size: cover;
        height: 390px;
    }
}