/*----------------------------------------*/
/*  27 - Components - Shipping
/*----------------------------------------*/
.shipping-area{
    .shipping-nav{
        &.with-border_bottom{
            border-top: 1px solid $border-color;
        }
        &.with-border{
            border-top: 1px solid $border-color;
            border-bottom: 1px solid $border-color;
        }
    }
}

.shipping-item{
    text-align: center;
    @include breakpoint(max-md_device){
        padding-bottom: 25px;
    }
    @include breakpoint(max-sm_device){
        padding-bottom: 25px;
    }
    span{
        color: $secondary-color;
        padding-bottom: 15px;
        font-size: 40px;
        display: block;
    }
    h4{
        &.heading{
            font-size: 18px;
            line-height: 21.6px;
        }
    }
    a{
        color: inherit;
        font-size: 14px;
        line-height: 14px;
    }
    &.style-02{
        h4{
            &.heading{
                font-size: 14px;
                line-height: 16.4px;
                text-transform: uppercase;
            }
        }
        p{
            &.short-desc{
                font-size: 14px;
                line-height: 21.2px;
                margin: 0 auto;
                @include breakpoint(min-xxl_device){
                    max-width: 80%;
                }
            }
        }
    }
}