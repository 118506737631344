/*----------------------------------------*/
/*  36 - Section - Footer
/*----------------------------------------*/
/* ---Footer Top Area--- */
.footer-top_area{
	&.with-border_bottom{
		border-bottom: 1px solid $border-color;
	}
	&.footer-style-02{
		@include breakpoint(max-lg_device){
			text-align: center;
		}
		.footer-widgets_area{
			p{
				&.short-desc{
					padding-bottom: 0;
					@include breakpoint(max-xl_device){
						padding-bottom: 10px;
						max-width: 100%;
					}
				}
			}
		}
		.widget-menu{
			@include breakpoint(max-lg_device){
				padding-bottom: 20px;
			}
			ul{
				@include breakpoint(max-xs_device){
					flex-direction: column;
				}
				li{
					&:not(:last-child){
						@include breakpoint(max-xs_device){
							padding-right: 0 !important;
							padding-bottom: 10px;
						}
					}
				}
			}
		}
	}
}

/* ---Footer Widget--- */
.footer-widgets_area{
	h2{
		&.footer-title{
			padding-bottom: 10px;
			a{
				text-transform: uppercase;
				font-size: 24px;
				line-height: 28px;
			}
		}
	}
	p{
		&.short-desc{
			font-size: 14px;
			line-height: 22px;
			padding-bottom: 20px;
			max-width: 68%;
		}
		&.playfair-font{
			font-family: 'Playfair Display', serif;
			font-style: italic;
		}
	}
	ul{
		&.list-item{
			li{
				display: inline-block;
				&:not(:last-child){
					a{
						padding-right: 7px;
						&:after{
							color: $border-color;
							position: absolute;
							content: "|";
							top: 50%;
							right: 0;
							transform: translateY(-50%);
						}
					}
				}
				a{
					position: relative;
					font-size: 12px;
					line-height: 21.6px;
					text-transform: uppercase;
				}
			}
		}
	}
	h3{
		&.heading{
			font-size: 14px;
			line-height: 22px;
			padding-bottom: 15px;
			font-weight: 600;
		}
	}
	.footer-widget{
		li{
			&:not(:last-child){
				padding-bottom: 15px;
			}
			a{
				font-size: 14px;
				line-height: 22px;
				&:hover{
					text-decoration: underline;
				}
			}
			&.subscribe-form_wrap{
				position: relative;
				input{
					&.input-field{
						width: 100%;
						height: 50px;
						padding-left: 20px;
						padding-right: 160px;
					}
				}
				.subscribe-btn{
					position: absolute;
					top: 0;
					right: 0;
					height: 50px;
					line-height: 50px;
					padding: 0 25px;
					font-size: 14px;
					text-transform: uppercase;
				}
			}
		}
		&.subscribe-widget{
			h3{
				&.heading{
					padding-bottom: 25px;
				}
			}
		}
	}
}

/* ---Footer Widgets Wrap--- */
.footer-widgets_wrap{
	[class*="col-"]{
		@include breakpoint(max-md_device){
			padding-top: 40px;
		}
	}
}

/* ---Footer Bottom Area--- */
.footer-bottom_area{
	padding-top: 15px;
	padding-bottom: 15px;
	.copyright{
		@include breakpoint(max-xs_device){
			text-align: center;
			padding-bottom: 10px;
		}
		span{
			color: initial;
		}
		span,
		a{
			font-size: 14px;
			line-height: 22px;
		}
	}
	.payment-method{
		margin-top: 0;
		text-align: right;
		@include breakpoint(max-xs_device){
			text-align: center;
		}
	}
}

/* ---Footer Dark Color--- */
.footer-area{
	&.bg-dark_color{
		.footer-top_area{
			.footer-widgets_area{
				h2{
					&.footer-title{
						a{
							text-transform: uppercase;
							color: $white-color;
						}
					}
				}
				p{
					&.short-desc{
						color: #bebebe;
					}
				}
				h3{
					&.heading{
						color: $white-color;
					}
				}
				.social-link{
					li{
						a{
							color: $white-color;
						}
					}
				}
				.footer-widget{
					li{
						&:not(:last-child){
							padding-bottom: 5px;
						}
						a{
							color: #bebebe;
							&:hover{
								color: $white-color;
							}
						}
						span{
							color: #bebebe;
							font-size: 14px;
							line-height: 22px;
						}
					}
				}
			}
			[class*="footer-widgets_wrap"]{
				&:not(:last-child){
					padding-bottom: 20px;
					@include breakpoint(max-md_device){
						padding-bottom: 0;
					}
				}
				[class*="col-"]{
					@include breakpoint(max-md_device){
						padding-top: 20px;
					}
				}
			}
			.widget-menu{
				ul{
					display: flex;
					justify-content: center;
					li{
						display: inline-block;
						&:not(:last-child){
							padding-right: 30px;
						}
						a{
							&:hover{
								text-decoration: underline;
							}
						}
					}
				}
			}
		}
		.footer-bottom_area{
			.copyright{
				text-align: center;
				span,
				a{
					color: #bebebe;
				}
				a{
					&:hover{
						color: $white-color;
					}
				}
			}
		}
	}
	.with-position{
		padding-bottom: 25px;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		z-index: 1;
		@include breakpoint(max-md_device){
			text-align: center;
		}
		.copyright{
			span,
			a{
				text-transform: uppercase;
				font-size: 13px;
				letter-spacing: 1px;
				@include breakpoint(max-sm_device){
					font-size: 12px;
				}
			}
		}
		.footer-widget_item{
			display: flex;
			justify-content: flex-end;
			@include breakpoint(max-md_device){
				justify-content: center;
			}
			@include breakpoint(max-sm_device){
				flex-direction: column;
				align-items: center;
			}
			.widget-menu{
				padding-right: 25px;
				@include breakpoint(max-sm_device){
					padding-right: 0;
				}
				ul{
					li{
						display: inline-block;
						&:not(:last-child){
							padding-right: 25px;
							@include breakpoint(max-lg_device){
								padding-right: 15px;
							}
						}
						a{
							text-transform: uppercase;
							font-size: 13px;
							letter-spacing: 1px;
							@include breakpoint(max-sm_device){
								font-size: 12px;
							}
						}
					}
				}
			}
			.social-link{
				li{
					&:not(:last-child){
						@include breakpoint(max-lg_device){
							padding-right: 15px;
						}
					}
					a{
						font-size: 13px;
					}
				}
			}
		}
	}
}