/*----------------------------------------*/
/*  39 - Pages - Coming Soon
/*----------------------------------------*/
.coming-soon_area{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    &.coming-soon-bg{
        background-image: url('../images/coming-soon/bg/1.webp');
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: cover;
        height: 100vh;
    }
    .inner-content{
        padding-top: 100px;
        overflow-y: auto;
        width: 100%;
        h1{
            &.heading{
                font-family: 'Pacifico', cursive;
                font-size: 100px;
                line-height: 100px;
                color: $white-color;
                padding-bottom: 60px;
                @include breakpoint(max-sm_device){
                    font-size: 35px;
                    line-height: 1.2;
                }
            }
        }
        .countdown-wrap{
            padding-bottom: 45px;
        }
        .button-wrap{
            padding-bottom: 100px;
        }
    }
}