/*----------------------------------------*/
/*  24 - Components - Tab
/*----------------------------------------*/
/* ---Product Tab--- */
.product-tab{
    &.style-01{
        ul{
            &.product-menu{
                justify-content: center;
                li{
                    &:not(:last-child){
                        padding-right: 10px;
                        @include breakpoint(max-sm_device){
                            padding-bottom: 10px;
                        }
                    }
                    a{
                        border: 1px solid $border-color;
                        text-transform: uppercase;
                        padding: 15px 30px;
                        font-size: 14px;
                        line-height: 22px;
                        display: block;
                        @include breakpoint(max-xxs_device){
                            padding: 10px 15px;
                        }
                        &.active{
                            background-color: $secondary-color;
                            border-color: $secondary-color;
                            color: $white-color;
                        }
                        &:hover{
                            background-color: $secondary-color;
                            border-color: $secondary-color;
                            color: $white-color;
                        }
                    }
                }
            }
        }
    }
    &.style-02{
        ul{
            &.product-menu{
                justify-content: center;
                list-style-type: disc;
                @include breakpoint(max-xxs_device){
                    list-style-type: none;
                }
                li{
                    &:not(:last-child){
                        padding-right: 30px;
                    }
                    a{
                        text-transform: uppercase;
                        @include breakpoint(max-xxs_device){
                            font-size: 14px;
                        }
                        &.active{
                            text-decoration: underline;
                        }
                        &:hover{
                            color: initial;
                        }
                    }
                }
            }
        }
    }
    &.style-03{
        ul{
            &.product-menu{
                justify-content: center;
                li{
                    &:not(:last-child){
                        padding-right: 45px;
                        @include breakpoint(max-xxs_device){
                            padding-right: 0;
                        }
                    }
                    a{
                        font-family: 'Playfair Display', serif;
                        font-style: italic;
                        font-size: 46px;
                        line-height: 82.8px;
                        color: $text-color;
                        &.active{
                            color: $white-color;
                        }
                    }
                }
            }
        }
    }
    &.style-04{
        ul{
            @include breakpoint(max-xs_device){
                flex-direction: column;
                align-items: center;
                text-align: center;
            }
            &.product-menu{
                justify-content: center;
                li{
                    &:not(:last-child){
                        padding-right: 45px;
                        @include breakpoint(max-xs_device){
                            padding-right: 0;
                        }
                    }
                    a{
                        text-transform: uppercase;
                        font-size: 32px;
                        line-height: 57.6px;
                        color: $text-color;
                        font-weight: 500;
                        &.active{
                            color: $secondary-color;
                        }
                        &:hover{
                            color: $secondary-color;
                        }
                    }
                }
            }
        }
    }
}

/* ---Product Tab Two--- */
.product-tab-2{
    ul{
        &.product-menu{
            border-bottom: 1px solid $border-color;
            padding-bottom: 15px;
            justify-content: center;
            @include breakpoint(max-lg_device){
                justify-content: flex-start;
            }
            li{
                &:not(:last-child){
                    padding-right: 35px;
                    @include breakpoint(max-lg_device){
                        padding-right: 15px;
                    }
                }
                a{
                    color: inherit;
                    text-transform: uppercase;
                    font-size: 14px;
                    line-height: 20px;
                    position: relative;
                    &:hover{
                        color: $primary-color;
                    }
                    &.active{
                        color: $secondary-color;
                        font-weight: 600;
                        &:before{
                            background-color: $secondary-color;
                            height: 3px;
                            width: 100%;
                            content: "";
                            position: absolute;
                            bottom: -18px;
                            left: 0;
                            @include breakpoint(max-md_device){
                                content: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* ---Tab Content--- */
.sku-tab_content{
    .description-body{
        .single-item{
            display: flex;
            align-items: center;
            @include breakpoint(max-sm_device){
                flex-direction: column;
            }
            .single-img{
                width: 30%;
                @include breakpoint(max-sm_device){
                    width: 100%;
                }
            }
            .single-content{
                padding-left: 50px;
                width: 70%;
                @include breakpoint(max-sm_device){
                    padding-top: 25px;
                    padding-left: 0;
                    width: 100%;
                }
            }
        }
    }
    .review-body{
        > h2{
            &.heading{
                font-size: 24px;
                line-height: 28.8px;
                font-weight: 400;
                padding-bottom: 25px;
            }
        }
        ul{
            &.user-info_wrap{
                > li{
                    border-bottom: 1px solid $border-color;
                    padding-bottom: 40px;
                    &:not(:last-child){
                        margin-bottom: 40px;
                    }
                    > ul{
                        &.user-info{
                            display: flex;
                            justify-content: flex-start;
                            @include breakpoint(max-xs_device){
                                flex-direction: column;
                            }
                            li{
                                &.user-avatar{
                                    width: 15%;
                                    @include breakpoint(max-lg_device){
                                        width: 30%;
                                    }
                                    @include breakpoint(max-sm_device){
                                        width: 40%;
                                    }
                                }
                                &.user-comment{
                                    padding-left: 30px;
                                    @include breakpoint(max-xs_device){
                                        padding-top: 20px;
                                        padding-left: 0;
                                    }
                                    h3{
                                        &.product-name{
                                            font-family: 'Playfair Display', serif;
                                            font-style: italic;
                                            font-size: 18px;
                                            line-height: 32.4px;
                                            font-weight: 600;
                                        }
                                    }
                                    p{
                                        &.short-desc{
                                            font-size: 14px;
                                            line-height: 25.2px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .user-feedback{
            padding-top: 40px;
            h3{
                &.heading{
                    font-size: 24px;
                    line-height: 43.2px;
                }
            }
            p{
                &.short-desc{
                    padding-bottom: 15px;
                }
            }
            .rating-box{
                display: flex;
                align-items: center;
                span{
                    padding-right: 25px;
                }
            }
            form{
                &.pd-feedback_form{
                    padding-top: 15px;
                    padding-bottom: 50px;
                    .form-field{
                        padding-bottom: 20px;
                        label{
                            &.label-field{
                                padding-bottom: 10px;
                            }
                        }
                        textarea{
                            &.textarea-field{
                                height: 220px;
                                width: 100%;
                                padding: 25px;
                            }
                        }
                        input{
                            &.input-field{
                                width: 100%;
                                height: 50px;
                                padding: 0 15px;
                            }
                        }
                        &.form-group_field{
                            display: flex;
                            justify-content: space-between;
                            @include breakpoint(max-sm_device){
                                flex-direction: column;
                            }
                            .group-input{
                                width: 100%;
                                &[class*="group-input"]{
                                    &:not(:last-child){
                                        @include breakpoint(max-sm_device){
                                            padding-bottom: 20px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .form-btn_wrap{
                        padding-top: 30px;
                    }
                }
            }
        }
    }
    .shipping-delivery_body{
        padding-left: 30px;
        padding-right: 30px;
        @include breakpoint(max-sm_device){
            padding-left: 0;
            padding-right: 0;
        }
        h3{
            &.heading{
                font-size: 16px;
                line-height: 19.2px;
            }
        }
    }
    .vendor-info_body{
        ul{
            li{
                &.rating-box_wrap{
                    display: flex;
                    align-items: center;
                    > ul{
                        &.rating-box{
                            li{
                                a{
                                    font-size: 12px;
                                    line-height: 21.6px;
                                }
                            }
                        }
                    }
                    span{
                        font-size: 12px;
                        line-height: 21.6px;
                        padding-left: 5px;
                    }
                }
            }
        }
    }
}

/* ---Product Tab Area--- */
.product-tab_area{
    &.bg-dark_color{
        .tab-content{
            .product-item{
                .single-product{
                    .single-content{
                        .product-name{
                            color: $white-color;
                            &:hover{
                                color: $primary-color;
                            }
                        }
                        .price-box{
                            span{
                                &.new-price{
                                    color: $white-color;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}