/*----------------------------------------*/
/*  41 - Pages - Error 404
/*----------------------------------------*/
.error-404_area{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    &.error-404-bg{
        background-image: url('../images/error-404/bg/1.jpg');
        background-repeat: no-repeat;
        height: 100vh;
    }
    .error-content{
        h1{
            &.heading{
                font-size: 250px;
                line-height: 1;
                @include breakpoint(max-sm_device){
                    font-size: 100px;
                }
            }
        }
        h2{
            &.sub-heading{
                font-size: 46px;
                line-height: 1.27;
                @include breakpoint(max-sm_device){
                    font-size: 25px;
                }
            }
        }
        .button-wrap{
            padding-top: 25px;
        }
    }
}