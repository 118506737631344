/*----------------------------------------*/
/*  23 - Components - Product
/*----------------------------------------*/
/* ---Featured Product--- */
.featured-product_item{
    .single-img{
        position: relative;
        cursor: pointer;
        .fp-btn_wrap{
            &.position-bottom_left{
                position: absolute;
                bottom: 0;
                left: 0;
                width: 50%;
                z-index: 2;
                .skudmart-btn{
                    width: 100%;
                }
            }
        }
    }
    &:hover{
        .single-img{
            .fp-btn_wrap{
                &.position-bottom_left{
                    .skudmart-btn{
                        background-color: $white-color;
                        color: $black-color;
                    }
                }
            }
        }
    }
    &.style-02{
        .single-img{
            .fp-btn_wrap{
                &.position-bottom_left{
                    .skudmart-btn{
                        text-transform: uppercase;
                    }
                }
            }
        }
        &:hover{
            .single-img{
                .fp-btn_wrap{
                    &.position-bottom_left{
                        .skudmart-btn{
                            background-color: $primary-color-2;
                            color: $black-color;
                        }
                    }
                }
            }
        }
    }
}

/* ---Product Item--- */
.product-item{
    padding-bottom: 30px;
    .single-product{
        .single-img{
            position: relative;
            overflow: hidden;
            @extend %basetransition;
            &:before{
                background-color: $secondary-color;
                content: "";
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                position: absolute;
                opacity: 0;
                pointer-events: none;
                z-index: 1;
                @extend %basetransition;
            }
            a{
                img{
                    width: 100%;
                    &.secondary-img {
						position: absolute;
						left: 0;
						top: 0;
						opacity: 0;
						width: 100%;
						@extend %basetransition;
					}
                }
            }
            .add-actions{
                position: absolute;
                bottom: 50%;
                left: 0;
                transform: translateY(50%);
                width: 100%;
                text-align: center;
                z-index: 2;
                > ul{
                    li{
                        display: inline-block;
                        @extend %basetransition;
                        transform: translateY(50px);
                        &:not(:last-child){
                            padding-right: 5px;
                        }
                        &:nth-child(1) {
                            transition-delay: 100ms;
                        }
                        &:nth-child(2) {
                            transition-delay: 150ms;
                        }
                        &:nth-child(3) {
                            transition-delay: 200ms;
                        }
                        &:nth-child(4) {
                            transition-delay: 250ms;
                        }
                        a{
                            background-color: $white-color;
                            width: 45px;
                            height: 45px;
                            line-height: 50px;
                            display: block;
                            text-align: center;
                            @extend %basetransition;
                            opacity: 0;
                            visibility: hidden;
                            @include breakpoint(max-xl_device){
                                width: 40px;
                                height: 40px;
                                line-height: 45px;
                            }
                            &:hover{
                                background-color: $primary-color;
                                color: $white-color;
                            }
                        }
                    }
                }
                &.white-border{
                    ul{
                        li{
                            a{
                                border: 1px solid $border-color;
                                background-color: transparent;
                                color: $white-color;
                                &:hover{
                                    background-color: $primary-color-2;
                                    border-color: $primary-color-2;
                                }
                            }
                        }
                    }
                }
            }
        }
        .single-content{
            padding-top: 15px;
            .product-name{
                padding-bottom: 5px;
                font-size: inherit;
                line-height: inherit;
                display: block;
            }
            &.with-bg{
                background-color: $white-color;
                padding: 10px 20px;
                .product-name{
                    font-size: 14px;
                    line-height: 18.2px;
                    font-weight: 400;
                }
            }
            &.with-padding{
                padding-left: 20px;
                padding-right: 20px;
                .product-name{
                    font-size: 14px;
                }
            }
        }
        &:hover {
            .single-img{
                &:before{
                    opacity: 0.5;
                }
                &.without-overlay{
                    &:before{
                        content: none;
                    }
                }
                a{
                    img{
                        &.secondary-img {
                            opacity: 1;
                        }
                    }
                }
                .add-actions{
                    ul{
                        li{
                            transform: translateY(0);
                            a{
                                opacity: 1;
                                visibility: visible;
                            }
                        }
                    }
                }
            }
        }
    }
    &.product-banner{
        .single-img{
            position: relative;
            .inner-text{
                position: absolute;
                bottom: 40px;
                left: 40px;
                pointer-events: none;
                z-index: 1;
                h3{
                    &.title{
                        color: $white-color;
                        font-size: 36px;
                        line-height: 43.2px;
                    }
                }
            }
        }
    }
    &.style-02{
        .single-product{
            overflow: hidden;
            .single-content{
                .price-box{
                    span{
                        &.new-price{
                            color: $secondary-color;
                            font-weight: 500;
                        }
                    }
                }
                p{
                    &.short-desc{
                        font-size: 14px;
                    }
                }
                .add-actions{
                    ul{
                        li{
                            display: inline-block;
                            @extend %basetransition;
                            transform: translateY(50px);
                            opacity: 0;
                            visibility: hidden;
                            &:not(:last-child){
                                padding-right: 5px;
                            }
                            &:nth-child(1) {
                                transition-delay: 100ms;
                            }
                            &:nth-child(2) {
                                transition-delay: 150ms;
                            }
                            &:nth-child(3) {
                                transition-delay: 200ms;
                            }
                            &:nth-child(4) {
                                transition-delay: 250ms;
                            }
                            a{
                                background-color: #3C3F52;
                                color: $white-color;
                                width: 45px;
                                height: 45px;
                                line-height: 50px;
                                display: block;
                                text-align: center;
                                @extend %basetransition;
                                &:hover{
                                    background-color: $primary-color-2;
                                    color: $white-color;
                                }
                            }
                        }
                    }
                }
            }
            &:hover{
                .add-actions{
                    ul{
                        li{
                            transform: translateY(0);
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
            }
        }
    }
    &.with-border{
        .single-product{
            border: 1px solid $border-color;
            padding: 30px;
        }
    }
}

/* ---Popular Product Slider--- */
.popular-product_slider{
    .swiper-slide {
        @include breakpoint(min-sm_device){
            width: 30% !important;
        }
        &.swiper-slide-active{
            @include  breakpoint(min-sm_device){
                width: 40% !important;
            }
        }
        .single-product{
            .single-content{
                text-align: left;
                @include breakpoint(max-xs_device){
                    text-align: center;
                }
            }
        }
    }
}