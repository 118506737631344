/*----------------------------------------*/
/*  14 - Components - Breadcrumb
/*----------------------------------------*/
.breadcrumb-area {
	.breadcrumb-content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		h1 {
			text-align: center;
			font-size: 34px;
			line-height: 40.8px;
			letter-spacing: 5px;
			font-weight: 400;
			padding-bottom: 10px;
			@include breakpoint(max-sm_device){
				font-size: 25px;
			}
		}
		ul {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			li {
				display: inline-block;
				position: relative;
				font-size: 14px;
				line-height: 23.6px;
				letter-spacing: 0.5px;
				color: $secondary-color;
				text-transform: uppercase;
				font-size: 12px;
				line-height: 21.6px;
				letter-spacing: 0.5px;
				&:not(:last-child){
					padding-right: 8px;
					&:after {
						content: ">";
						margin-left: 8px;
					}
				}
				a{
					text-transform: uppercase;
					font-size: 12px;
					line-height: 21.6px;
					letter-spacing: 0.5px;
				}
			}
		}
	}
	&.with-bg{
		background-image: url("../images/breadcrumb/about.jpg");
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		height: 460px;
		.breadcrumb-content {
			h1 {
				color: $white-color;
				z-index: 1;
			}
			ul {
				li {
					color: $white-color;
					a{
						color: $white-color;
					}
				}
			}
		}
		&.contact-page_bg{
			background-image: url("../images/breadcrumb/contact.jpg");
			height: 345px;
		}
		&.faq-page_bg{
			background-image: url("../images/breadcrumb/faq.jpg");
			height: 345px;
		}
		&.collection-pages_bg{
			background-image: url("../images/breadcrumb/collections.jpg");
			height: 345px;
		}
		&.shop-instagram_bg{
			background-image: url("../images/breadcrumb/shop-instagram.jpg");
			height: 345px;
		}
		&.shop-masonry_bg{
			background-image: url("../images/breadcrumb/shop-masonry.jpg");
			height: 345px;
		}
		&.vendor-page_bg{
			background-image: url("../images/breadcrumb/collections.jpg");
			height: 270px;
			.breadcrumb-content{
				display: none;
			}
		}
		&.shop-related-pages_bg{
			background-image: url("../images/breadcrumb/shop-related.jpg");
			height: 345px;
		}
		&.element-pages_bg{
			background-image: url("../images/breadcrumb/element-pages.jpg");
			height: 345px;
		}
	}
	&.with-border{
		border-top: 1px solid $border-color;
		padding-top: 85px;
		padding-bottom: 95px;
		.breadcrumb-content{
			h1 {
				color: $secondary-color;
			}
			ul {
				li {
					a{
						color: inherit;
					}
				}
			}
		}
	}
	.with-page_nav {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.page-nav{
			ul{
				display: flex;
				li{
					&:not(:last-child){
						padding-right: 10px;
					}
				}
			}
		}
	}
}