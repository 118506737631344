/*----------------------------------------*/
/*  17 - Components - Social Link
/*----------------------------------------*/
.social-link {
	li {
		display: inline-block;
		&:not(:last-child){
			padding-right: 25px;
		}
		a {
			font-size: 16px;
			display: block;
		}
	}
	&.with-color{
		li{
			padding-right: 10px;
			&:last-child {
				padding-right: 0;
			}
			a{
				color: $white-color;
				font-size: 14px;
				width: 45px;
				height: 45px;
				line-height: 45px;
				text-align: center;
			}
			&.facebook{
				a{
					background-color: $facebook-color;
				}
			}
			&.twitter{
				a{
					background-color: $twitter-color;
				}
			}
			&.google-plus{
				a{
					background-color: $google-plus-color;
				}
			}
			&.skype{
				a{
					background-color: $skype-color;
				}
			}
		}
	}
	&.with-border-radius{
		li{
			a{
				border-radius: 3px;
			}
		}
		&.sm-size{
			li{
				a{
					width: 40px;
					height: 40px;
					line-height: 40px;
					font-size: 16px;
				}
			}
		}
	}
	&.vertical-social_link{
		display: inline-block;
		li{
			display: block;
			padding-right: 0;
			padding-bottom: 10px;
		}
	}
}