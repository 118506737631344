/*----------------------------------------*/
/*  23 - Components - Sidebar
/*----------------------------------------*/
.sidebar-area{
    @include breakpoint(max-md_device){
        padding-top: 95px;
    }
    .search-box{
        position: relative;
        input{
            &.search-box_input{
                border: 1px solid $border-color;
                width: 100%;
                height: 50px;
                padding: 0 45px 0 15px;
            }
        }
        button{
            &.search-box_btn{
                color: $charcoal-color;
                position: absolute;
                top: 50%;
                right: 15px;
                transform: translateY(-50%);
                &:hover{
                    color: $primary-color;
                }
            }
        }
    }

    .widgets-area{
        h2{
            &.heading{
                font-size: 24px;
                line-height: 28.8px;
                padding-bottom: 25px;
            }
        }
        .widgets-item{
            ul{
                li{
                    &:not(:last-child){
                        padding-bottom: 15px;
                    }
                    a{
                        font-size: 18px;
                        line-height: 32.4px;
                        text-transform: uppercase;
                        font-weight: 500;
                    }
                    &:hover{
                        a{
                            color: $primary-color;
                        }
                    }
                    &.has-sub{
                        a{
                            display: flex;
                            align-items: center;
                            cursor: pointer;
                            i {
                                margin-left: 20px;
                                font-size: 14px;
                            }
                        }
                        > ul{
                            &.inner-body{
                                display: none;
                                padding-top: 15px;
                                li{
                                    &:not(:last-child){
                                        padding-bottom: 10px;
                                    }
                                    &:hover{
                                        a{
                                            color: $primary-color;
                                        }
                                    }
                                    a{
                                        display: block;
                                        font-size: 12px;
                                        line-height: 21.6px;
                                        color: $nobel-color;
                                        font-weight: 400;
                                        &:hover{
                                            text-decoration: underline;
                                        }
                                    }
                                }
                            }
                        }
                        &.open{
                            a{
                                i {
                                    &:before{
                                        content: '\ea13';
                                    }
                                }
                            }
                        }
                    }
                }
            }
            ul{
                &.tags-item{
                    li{
                        display: inline-block;
                        &:not(:last-child){
                            margin-right: 5px;
                        }
                        a{
                            text-transform: none;
                            font-size: 14px;
                            line-height: 20px;
                            color: $nobel-color;
                            font-weight: 400;
                            display: block;
                            padding: 10px;
                            border: 1px solid $border-color;
                            &:hover{
                                background-color: $secondary-color;
                                border-color: $secondary-color;
                                color: $white-color;
                            }
                        }
                    }
                }
            }
        }
    }
    .blog-widgets_area{
        h3{
            &.heading{
                border-bottom: 2px solid $secondary-color;
                font-size: 18px;
                line-height: 21.6px;
            }
        }
        .widgets-item{
            .list-product{
                display: flex;
                .single-img{
                    width: 80px;
                    height: 80px;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center center;
                    }
                }
                .single-content{
                    padding-left: 20px;
                    width: calc(100% - 80px);
                    .product-name{
                        display: block;
                        font-size: 14px;
                        line-height: 19.6px;
                    }
                    span{
                        &.meta{
                            font-size: 11px;
                            line-height: 20.2px;
                        }
                    }
                }
                &[class*="list-product"]{
                    &:not(:last-child){
                        border-bottom: 1px solid $border-color;
                        padding-bottom: 25px;
                        margin-bottom: 25px;
                    }
                }
            }
            .blog-category{
                li{
                    display: flex;
                    justify-content: space-between;
                    &:not(:last-child){
                        padding-bottom: 20px;
                    }
                    span{
                        color: $secondary-color;
                        font-size: 14px;
                    }
                    a{
                        font-size: 14px;
                    }
                }
            }
            .blog-tags{
                a{
                    text-transform: lowercase;
                    font-size: 14px;
                    line-height: 25.2px;
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
            .widgets-banner{
                position: relative;
                &:before{
                    background-color: #181818;
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    transition: all .5s;
                    opacity: .2;
                    pointer-events: none;
                }
                .inner-content{
                    position: absolute;
                    left: 50%;
                    bottom: 0;
                    transform: translateX(-50%);
                    @extend %basetransition;
                    @include breakpoint(max-lg_device){
                        bottom: 20px;
                    }
                    span{
                        text-transform: uppercase;
                        font-size: 26px;
                        line-height: 26px;
                        letter-spacing: 1px;
                        @include breakpoint(max-lg_device){
                            font-size: 16px;
                            line-height: 16px;
                        }
                    }
                    h3{
                        &.offer{
                            font-size: 120px;
                            line-height: 120px;
                            @include breakpoint(max-lg_device){
                                font-size: 80px;
                                line-height: 80px;
                            }
                        }
                    }
                }
                &:hover{
                    &:before{
                        width: calc(100% - 30px);
                        height: calc(100% - 30px);
                        top: 15px;
                        left: 15px;
                    }
                    .inner-content{
                        bottom: 30px;
                    }
                }
            }
        }
    }
}