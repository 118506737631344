//--- All Variables - Related Stuff Included In This Area ---↓

//----skudmart Typography----↓
$fontFamily: 'Roboto', sans-serif;
$size: 16px;
$lineHeight: 1.6;
$weight: 400;

//----Color----↓
$primary-color:     #D2A35C;
$primary-color-2:   #7aedc9;
$secondary-color:   #1d1d1d;
$text-color:        #707070;
$border-color:      #d8d8d8;

//----Additional Color Variable----↓
$white-color:         #ffffff;
$black-color:         #000000;
$silver-color:        #bbbbbb;
$red-color:           #ff0000;
$snow-color:          #fcfcfc;
$gamboge-color:       #dc9b0e;
$coral-color:         #ff8c46;
$zircon-color:        #DAE5E7;
$radical-red_color  : #F53E6A;
$nobel-color:         #999999;
$atlantis-color:      #8cc63e;
$charcoal-color:      #454545;
$white-smoke_color:   #f7f7f7;
$dark-gray_color:     #ababab;
$gray-color:          #7e7e7e;
$very-light-grey_color:  #ccc;
$deep-sky-blue_color: #00CCFF;
$sunset-orange_color: #fe4a49;
$neon-blue_color:     #4241FF;
$aluminium-color:     #818a91;
$fern-color:          #5cb85c;
$casablanca-color:    #f0ad4e;
$cerulean-blue_color: #2C51D6;
$blue-violet-color:   #7817E8;
$turquoise-color:     #02deea;
$persian-blue_color:  #2f21b3;
$valhalla-color:      #2d2d48;
$jaguar-color:        #181531;
$midnight-color:      #262835;
$niro-color:          #202020;

//----Social Link Color Variables----↓
$facebook-color:    #3b579d;
$twitter-color:     #3acaff;
$google-plus-color: #ca0813;
$linkedin-color:    #0097bd;
$pinterest-color:   #cb2027;
$instagram-color:   #833ab4;
$skype-color:       #00aff0;
$share-color:       #fe6d4c;
$youtube-color:     #d72525;
$vimeo-color:       #00adef;

// Template Color Variation ----↓
$color-1: $primary-color;
$color-2: $primary-color-2;
$colorList: $color-1, $color-2;
