/******************************

INDEX:

    00 - Base - Typography
    01 - Base - Variables
    02 - Base - Mixins

    03 - Utilities - Helper
    04 - Utilities - Color

    10 - Components - Navigation
    11 - Components - Animation
    12 - Components - Button
    13 - Components - Slider
    14 - Components - Breadcrumb
    15 - Components - Form
    16 - Components - Newsletter
    17 - Components - Social Link
    18 - Components - Testimonials
    19 - Components - Sidebar
    20 - Components - Tab
    21 - Components - Brand
    22 - Components - Offcanvas
    23 - Components - Product
    24 - Components - Modal
    25 - Components - Instagram
    26 - Components - Banner
    27 - Components - Shipping
    28 - Components - Multiple Section

    35 - Section - Header
    36 - Section - Footer

    37 - Pages - About Us
    38 - Pages - Contact Us
    39 - Pages - Coming Soon
    40 - Pages - FAQs
    41 - Pages - Error 404
    42 - Pages - Collections
    43 - Pages - Shop
    44 - Pages - Cart
    45 - Pages - Order Tracking
    46 - Pages - My Account
    47 - Pages - Wishlist
    48 - Pages - Compare
    49 - Pages - Product Detail
    50 - Pages - Elements
    51 - Pages - Blog
    52 - Pages - Newsletter
    53 - Pages - Checkout

******************************/

/*

    Primary Color:       #D2A35C;
    Primary Color-2:     #ee4300;
    Secondary Color:     #000000;
    Text Color:          #1b1b1b;
    Border Color:        #d8d8d8;


    Font Family List:-

    font-family: 'Pacifico', cursive;
    font-family: 'Playfair Display', serif;
    font-family: 'Roboto', sans-serif;
    
*/

/* ---Google Font--- */
@import url('https://fonts.googleapis.com/css2?family=Pacifico&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

//Base
@import 'base/variables';
@import 'base/mixins';
@import 'base/typography';

// Utilities
@import 'utilities/helper';
@import 'utilities/color';

// Components
@import 'components/navigation';
@import 'components/animation';
@import 'components/button';
@import 'components/slider';
@import 'components/breadcrumb';
@import 'components/form';
@import 'components/social-link';
@import 'components/testimonials';
@import 'components/sidebar';
@import 'components/tab';
@import 'components/brand';
@import 'components/offcanvas';
@import 'components/product';
@import 'components/modal';
@import 'components/instagram';
@import 'components/banner';
@import 'components/shipping';
@import 'components/newsletter';
@import 'components/multiple-section';

// Section
@import 'section/header';
@import 'section/footer';

// Pages
@import 'pages/about-us';
@import 'pages/contact-us';
@import 'pages/coming-soon';
@import 'pages/faq';
@import 'pages/error-404';
@import 'pages/collections';
@import 'pages/shop';
@import 'pages/cart';
@import 'pages/order-tracking';
@import 'pages/my-account';
@import 'pages/wishlist';
@import 'pages/compare';
@import 'pages/product-detail';
@import 'pages/elements';
@import 'pages/blog';
@import 'pages/checkout';