/*----------------------------------------*/
/*  42 - Pages - Collections
/*----------------------------------------*/
.classic-banner_area{
    .single-content{
        @include breakpoint(max-sm_device){
            padding-top: 30px;
        }
        span{
            &.category{
                font-size: 16px;
                line-height: 16px;
                padding-bottom: 10px;
                display: block;
            }
        }
        h2{
            &.heading{
                font-family: 'Playfair Display', serif;
                font-style: italic;
                font-size: 46px;
                line-height: 1.2;
                padding-bottom: 20px;
                @include breakpoint(max-sm_device){
                    font-size: 30px;
                }
            }
        }
        p{
            &.short-desc{
                font-size: 16px;
                line-height: 32px;
                padding-bottom: 30px;
            }
        }
    }
}

/* ---Collection Slider--- */
.collection-slider{
    height: 100%;
    .swiper-slide{
        height: 100%;
        &.swiper-slide-active{
            .single-item{
                .single-content{
                    animation-name: fadeInUp;
					animation-delay: 0.5s;
					animation-duration: 1s;
                    animation-fill-mode: both;
                    opacity: 1;
                }
            }
        }
        .single-item{
            display: flex;
            align-items: center;
            height: 100%;
            @include breakpoint(max-sm_device){
                flex-direction: column;
            }
            .single-img{
                width: 50%;
                height: 100%;
                @include breakpoint(max-sm_device){
                    width: 100%;
                }
                .inner-img{
                    height: 100%;
                    img{
                        height: 100%;
                    }
                }
            }
            .single-content{
                padding-left: 80px;
                width: 50%;
                opacity: 0;
                @include breakpoint(max-md_device){
                    padding-left: 30px;
                }
                @include breakpoint(max-sm_device){
                    padding-top: 25px;
                    padding-left: 0;
                    width: 100%;
                }
                span{
                    &.category{
                        padding-bottom: 5px;
                        display: block;
                    }
                }
                h3{
                    &.heading{
                        font-size: 36px;
                        line-height: 36px;
                    }
                }
                .link-share{
                    display: flex;
                    li{
                        display: inline-block;
                        &:not(:last-child){
                            padding-right: 25px;
                        }
                        a{
                            font-size: 14px;
                            line-height: 25.2px;
                        }
                    }
                }
            }
        }
    }
}

/* ---Popular Collection--- */
.popular-collection_area{
    .masonry-grid{
        margin-left: -5px;
        margin-right: -5px;
        .grid-item{
            padding-left: 5px;
            padding-right: 5px;
            margin-bottom: 10px;
        }
    }
    .collection-item{
        .button-wrap{
            position: absolute;
            bottom: -50px;
            left: 0;
            width: 100%;
            z-index: 1;
            @extend %basetransition;
            a{
                &.skudmart-btn{
                    text-transform: uppercase;
                    &:hover{
                        color: $white-color;
                    }
                }
            }
        }
        &:hover{
            .button-wrap{
                bottom: 0;
            }
        }
    }
}