/*----------------------------------------*/
/*  03 - Utilities - Helper
/*----------------------------------------*/

/* ---Load Overlay--- */
.loadOverlay {
	background-color: $white-color;
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	display: block;
	z-index: 9999;
}

/* ---Custom Bootstrap Container--- */
.container{
	@include breakpoint(min-xl_device){
		max-width: 1170px;
	}
	&.custom-space{
		@include breakpoint(min-xl_device){
			max-width: calc(100% - 50px);
		}
		@include breakpoint(min-xxl_device){
			max-width: calc(100% - 130px);
		}
	}
	&.custom-space-2{
		@include breakpoint(min-xl_device){
			max-width: calc(100% - 90px);
		}
		@include breakpoint(min-xxl_device){
			max-width: calc(100% - 170px);
		}
	}
	&.custom-space-3{
		@include breakpoint(min-md_device){
			max-width: 770px;
		}
	}
}

/* ---Custom Column--- */
.custom-xxl-col{
	@include breakpoint(min-xxl_device){
		flex: 0 0 20%;
		max-width: 20%;
	}
}

/* ---Skudmart Custom Wrap--- */
.skudmart-wrap{
	&-70{
		margin-left: -35px;
		margin-right: -35px;
		@include breakpoint(max-lg_device){
			margin-left: -10px;
			margin-right: -10px;
		}
		[class*="col-"]{
			padding-left: 35px;
			padding-right: 35px;
			@include breakpoint(max-lg_device){
				padding-left: 10px;
				padding-right: 10px;
			}
		}
	}
}

/* ---Global Overlay--- */
.global-overlay {
	background-color: rgba(0,0,0,.5);
	@extend %basetransition;
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	z-index: 99;
	visibility: hidden;
	&.overlay-open {
		visibility: visible;
		cursor: pointer;
	}
}

/* ---Section Space--- */

/* --Padding Top-- */
.pt-100{
	padding-top: 100px;
}
.pt-95{
	padding-top: 95px;
}
.pt-90{
	padding-top: 90px;
}
.pt-85{
	padding-top: 85px;
}
.pt-80{
	padding-top: 80px;
}
.pt-75{
	padding-top: 75px;
}
.pt-70{
	padding-top: 70px;
}
.pt-65{
	padding-top: 65px;
}
.pt-60{
	padding-top: 60px;
}
.pt-55{
	padding-top: 55px;
}
.pt-50{
	padding-top: 50px;
}
.pt-45{
	padding-top: 45px;
}
.pt-40{
	padding-top: 40px;
}
.pt-35{
	padding-top: 35px;
}
.pt-30{
	padding-top: 30px;
}
.pt-25{
	padding-top: 25px;
}
.pt-20{
	padding-top: 20px;
}
.pt-15{
	padding-top: 15px;
}
.pt-10{
	padding-top: 10px;
}

/* --Padding Bottom-- */
.pb-100{
	padding-bottom: 100px;
}
.pb-95{
	padding-bottom: 95px;
}
.pb-90{
	padding-bottom: 90px;
}
.pb-85{
	padding-bottom: 85px;
}
.pb-80{
	padding-bottom: 80px;
}
.pb-75{
	padding-bottom: 75px;
}
.pb-70{
	padding-bottom: 70px;
}
.pb-65{
	padding-bottom: 65px;
}
.pb-60{
	padding-bottom: 60px;
}
.pb-55{
	padding-bottom: 55px;
}
.pb-50{
	padding-bottom: 50px;
}
.pb-45{
	padding-bottom: 45px;
}
.pb-40{
	padding-bottom: 40px;
}
.pb-35{
	padding-bottom: 35px;
}
.pb-30{
	padding-bottom: 30px;
}
.pb-25{
	padding-bottom: 25px;
}
.pb-20{
	padding-bottom: 20px;
}
.pb-15{
	padding-bottom: 15px;
}
.pb-10{
	padding-bottom: 10px;
}
.pb-05{
	padding-bottom: 5px;
}

/* ---Paddin Bottom Decrease--- */
.pb{
	&-max-md-85{
		@include breakpoint(max-md_device){
			padding-bottom: 85px;
		}
	}
}

/* ---Margin Bottom--- */
.mb-05{
	margin-bottom: 5px;
}
.mb-10{
	margin-bottom: 10px;
}

/* ---Disable Padding Bottom--- */
@include breakpoint(min-xxl_device){
	.pb-xxl-0{
		padding-bottom: 0 !important;
	}
}
@include breakpoint(max-xs_device){
	.pb-xs-0{
		padding-bottom: 0 !important;
	}
}
/* ---Disable Margin Bottom--- */
@include breakpoint(max-xs_device){
	.mb-xs-0{
		margin-bottom: 0 !important;
	}
}

/* ---Base Transition--- */
%basetransition {
	@include transition(all .3s ease-in);
}

/* ---Topbar--- */
.topbar-item{
	padding-top: 10px;
	padding-bottom: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	a{
		&.product-offer{
			padding-right: 30px;
			span{
				font-weight: 500;
			}
		}
	}
	&.bg-dark_color{
		a{
			&.product-offer{
				color: $white-color;
				&:hover{
					color: $primary-color;
				}
			}
		}
	}
	&.style-02{
		.product-offer{
			display: flex;
			padding-top: 20px;
			padding-bottom: 20px;
			span{
				font-size: 14px;
				text-transform: uppercase;
			}
			strong{
				font-weight: 500;
				font-size: 14px;
				text-transform: uppercase;
			}
		}
		.countdown-wrap{
			display: flex;
			span{
				&.countdown-title{
					font-weight: 500;
				}
			}
			.countdown{
				&.item-4{
					.countdown__item{
						margin-left: 15px;
						span {
							&.countdown__time {
								font-size: 14px;
								font-weight: 500;
							}
							&.countdown__text {
								display: inline-block;
								font-size: 14px;
								padding-top: 0;
							}
						}
					}
				}
			}
		}
	}
}

/* ---Pagination--- */
.pagination-area {
	&.position-center{
		display: flex;
		justify-content: center;
	}
	.pagination-box {
		li {
			display: inline-block;
			&:not(:last-child){
				padding-right: 5px;
			}
			a {
				border: 1px solid $border-color;
				width: 40px;
				height: 40px;
				line-height: 40px;
				text-align: center;
				display: block;
				border-radius: 100%;
				&.next{
					i{
						font-size: 16px;
					}
				}
			}
			&.active{
				a{
					background-color: $secondary-color;
					border-color: $secondary-color;
					color: $white-color;
				}
			}
			&:hover{
				a{
					background-color: $secondary-color;
					border-color: $secondary-color;
					color: $white-color;
				}
			}
		}
	}
}

/* ---Section Title--- */
.section-title_area{
	text-align: center;
	h2{
		&.heading{
			font-size: 24px;
			line-height: 1.2;
			letter-spacing: 1px;
			@include breakpoint(max-xs_device){
				font-size: 22px;
			}
			&.with-divider{
				position: relative;
				padding-bottom: 35px;
				margin-bottom: 25px;
				&:before{
					background-color: $border-color;
					position: absolute;
					content: "";
					width: 2px;
					height: 20px;
					bottom: 0;
					left: 50%;
					transform: translateX(-50%);

				}
			}
		}
	}
	p{
		&.short-desc{
			max-width: 62%;
			margin: 0 auto;
			line-height: 32px;
			padding-bottom: 20px;
			@include breakpoint(max-md_device){
				max-width: 85%;
			}
			@include breakpoint(max-xs_device){
				max-width: 100%;
			}
		}
	}
	.text-btn{
		font-size: 12px;
		line-height: 12px;
		font-weight: 600;
		text-decoration: underline;
	}
}
.section-title_area-2{
	display: flex;
	flex-direction: column;
	align-items: center;
	h2{
		&.heading{
			font-size: 30px;
			line-height: 36px;
			font-weight: 400;
			@include breakpoint(max-sm_device){
				font-size: 20px;
			}
		}
		&.with-divider{
			position: relative;
			padding-left: 75px;
			padding-right: 75px;
			&:before{
				background-color: $secondary-color;
				width: 50px;
				height: 1px;
				content: "";
				position: absolute;
				top: 50%;
				left: 0;
				transform: translateY(-50%);
			}
			&:after{
				background-color: $secondary-color;
				width: 50px;
				height: 1px;
				content: "";
				position: absolute;
				top: 50%;
				right: 0;
				transform: translateY(-50%);
			}
			@include breakpoint(max-xxs_device){
				padding-left: 15px;
				padding-right: 15px;
				&:before,
				&:after{
					content: none;
				}
			}
		}
	}
	p{
		&.short-desc{
			font-size: 14px;
			line-height: 28px;
		}
	}
}
.section-title_area-3{
	border-bottom: 1px solid $border-color;
	padding-bottom: 20px;
	margin-bottom: 25px;
	h2{
		&.heading{
			font-size: 24px;
			line-height: 28.8px;
		}
	}
}

/* ---Search Box--- */
.search-box{
	&.with-border_bottom{
		position: relative;
		input{
			&.input-field{
				border: 0;
				border-bottom: 1px solid $border-color;
				padding: 5px 20px 5px 0;
				color: $nobel-color;
				background-color: transparent;
				&:focus{
					border-color: $text-color;
					color: $text-color;
				}
				&::placeholder{
					opacity: 1;
				}
			}
		}
		.search-btn{
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
		}
	}
	&.different-width{
		@include breakpoint(min-lg_device){
			width: 270px;
		}
	}
}

/* ---Slider Nav Count--- */
.slide-nav_count{
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 150px;
	span{
		display: block;
		&.data-count{
			position: relative;
			&:before{
				content: attr(data-count);
				@include typography(51px, 51px, 600);
				@include breakpoint(max-lg_device){
					font-size: 40px;
					line-height: 1.2;
				}
			}
		}
		sup{
			@include typography(21px, 21px);
			vertical-align: super;
		}
	}
}

/* ---Sticker---*/
.sticker {
	background-color: $primary-color;
	color: $white-color;
	position: absolute;
	padding: 0 15px;
	height: 25px;
	font-size: 12px;
	line-height: 25px;
	font-weight: 500;
	text-align: center;
	top: 20px;
	right: 0;
	cursor: pointer;
	z-index: 2;
	&.secondary-color{
		background-color: $secondary-color;
	}
}

/* ---Product Information--- */
.product-info{
	border-bottom: 1px solid $border-color;
	padding-bottom: 15px;
	margin-bottom: 25px;
	display: flex;
	justify-content: space-between;
	@include breakpoint(max-md_device){
		flex-direction: column-reverse;
	}
	.customer-feedback{
		display: flex;
		align-items: center;
		.rating-box{
			padding-right: 5px;
		}
		span{
			color: $nobel-color;
			font-size: 12px;
		}
	}
	.product-stock{
	   span{
		   font-size: 12px;
		   &:before {
			content: '\ea20';
			font-family: LaStudioIcons;
			width: 15px;
			height: 15px;
			border-radius: 50%;
			border: 1px solid;
			font-size: 8px;
			display: inline-block;
			line-height: 14px;
			text-align: center;
			color: #86BA45;
			margin-left: 5px;
			margin-right: 5px;
		}
	   }
	}
}

/* ---Tooltip--- */
.tooltip{
	z-index: 10;
}
.tooltip-inner {
	padding: 0 8px;
	height: 25px;
	line-height: 22px;
	text-align: center;
	border-radius: 3px;
	font-size: 12px;
}
.tooltip.bs-tooltip-auto[x-placement^=top] .arrow::before,
.tooltip.bs-tooltip-top .arrow::before {
	content: "";
	border-width: 5px 5px 0;
}

.tooltip-inner {
	background-color: $secondary-color;
}
.tooltip.bs-tooltip-auto[x-placement^=top] .arrow::before,
.tooltip.bs-tooltip-top .arrow::before {
	border-top-color: $secondary-color;
}
.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.bs-tooltip-bottom .arrow::before {
	border-bottom-color: $secondary-color;
}
.tooltip.bs-tooltip-auto[x-placement^=right] .arrow::before,
.tooltip.bs-tooltip-right .arrow::before {
	border-right-color: $secondary-color;
}
.bs-tooltip-auto[x-placement^=left] .arrow::before,
.bs-tooltip-left .arrow::before {
	border-left-color: $secondary-color;
}

/* ---Grid Item--- */
.masonry-grid{
	margin-left: -10px;
	margin-right: -10px;
	.grid-item {
		padding-left: 10px;
		padding-right: 10px;
		margin-bottom: 20px;
		.single-img{
			height: 100%;
			.inner-img{
				height: 100%;
				img{
					height: 100%;
					width: 100%;
					object-fit: cover;
				}
			}
		}
		.sub-masonry_grid{
			margin-left: -10px;
			margin-right: -10px;
			.sub-grid_item{
				padding-left: 10px;
				padding-right: 10px;
				margin-bottom: 20px;
			}
		}
	}
}


/* ---Mousemove Wrap--- */
.mousemove-wrap {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	right: 0;
}

/* ---Blockquote--- */
blockquote{
	&.style-01{
		background-color: #2F2F2F;
		text-align: center;
		border: none;
		padding: 40px;
		p{
			font-family: "Playfair Display";
			color: $white-color;
			width: 480px;
			font-size: 18px;
			margin: 0 auto 0;
			@include breakpoint(max-sm_device){
				width: 100%;
			}
		}
	}
	.cite-wrap{
		.user-name{
			color: $white-color;
		}
	}
	&.style-02{
		border-left: 4px solid $secondary-color;
		color: $secondary-color;
		padding-left: 30px;
		text-transform: uppercase;
		font-size: 18px;
		font-family: "Playfair Display";
		margin-bottom: 5px;
		.cite-wrap{
			line-height: 1;
			.user-name{
				font-family: 'Roboto', sans-serif;
				font-size: 13px;
				font-style: normal;
				font-weight: 700;
				color: $secondary-color;
			}
		}
	}
}

/* ---Tab Content & Pane Fix--- */
.tab-content {
	width: 100%;
	.tab-pane {
		display: block;
		height: 0;
		max-width: 100%;
		visibility: hidden;
		overflow: hidden;
		opacity: 0;
		&.active {
			height: auto;
			visibility: visible;
			opacity: 1;
			overflow: visible;
			@extend %basetransition;
		}
	}
}

/*-- Scroll To Top --*/
.scroll-to-top{
    background-color: $secondary-color;
    color: $white-color;
    position: fixed;
    right: 50px;
	bottom: 90px;
	width: 40px;
	height: 40px;
	line-height: 40px;
    z-index: 999;
    font-size: 20px;
    overflow: hidden;
    display: block;
	text-align: center;
	opacity: 0;
	visibility: hidden;
	animation:  fadeOutRight 1s normal;
	@include transition(all 300ms ease);
	&.show{
		opacity: 1;
		visibility: visible;
		animation: skudSlideRight 1s normal;
	}
	i{
		@extend %basetransition;
		color: $white-color;
		font-size: 16px;
		display: inline-block;
	}
}

/* ---Rating Box--- */
.rating-box {
	ul {
		li {
			display: inline-block;
			i {
				color: $gamboge-color;
				font-size: 12px;
			}
			&.silver-color{
				i{
					color: $silver-color;
				}
			}
			&.secondary-color{
				i{
					color: $secondary-color;
				}
			}
		}
	}
}

/* ---Price Box--- */
.price-box{
	span{
		&.new-price{
			&.radical-red_color{
				color: $radical-red_color;
			}
			&.primary-color{
				color: $primary-color;
			}
		}
		&.old-price{
			@include typography(14px, 14px);
			text-decoration: line-through;
			color: $nobel-color;
		}
	}
}

/* ---Tag--- */
.tags-list {
	display: flex;
	@include breakpoint(max-xxs_device){
		flex-direction: column;
	}
	span{
		text-transform: uppercase;
		color: $secondary-color;
		font-weight: 600;
		padding-right: 5px;
	}
	li {
		display: inline-block;
		a{
			text-transform: lowercase;
		}
	}
}

/* ---Quantity--- */
.quantity {
	.cart-plus-minus {
		position: relative;
		width: 76px;
		height: 55px;
		text-align: left;
		> .cart-plus-minus-box {
			border: 1px solid $border-color;
			height: 55px;
			text-align: center;
			width: 48px;
			background: $white-color;
		}
		> .qtybutton {
			border-bottom: 1px solid $border-color;
			border-right: 1px solid $border-color;
			border-top: 1px solid $border-color;
			cursor: pointer;
			position: absolute;
			text-align: center;
			@extend %basetransition;
			width: 28px;
			height: 28px;
			line-height: 28px;
			font-size: 10px;
			&.dec {
				bottom: 0;
				right: 0;
			}
			&.inc {
				border-bottom: none;
				top: 0;
				right: 0;
			}
		}
	}
}

/* ---Range Slider--- */
.price-filter {
	.ui-widget-content {
		background-color: $border-color;
		border: none;
		border-radius: 0;
		box-shadow: none;
		cursor: pointer;
		height: 5px;
		margin: 0 0 25px;
		width: 100%;
		border-top-left-radius: 25px;
		border-top-right-radius: 25px;
		border-bottom-left-radius: 25px;
		border-bottom-right-radius: 25px;
	}
	.ui-slider-range {
		background-color: $secondary-color;
		border: none;
		box-shadow: none;
		height: 5px;
	}
	.ui-slider-handle {
		box-shadow: 0 1px 4px 0 rgba(0,0,0,0.4);
		background: $white-color;
		display: block;
		outline: none;
		width: 20px;
		height: 20px;
		line-height: 15px;
		text-align: center;
		top: 50%;
		left: 0;
		border-radius: 100%;
		transform: translateY(-50%);
		cursor: e-resize;
		@extend %basetransition;
		&:last-child {
			transform: translateY(-50%);
		}
		&:focus{
			box-shadow: 0 1px 4px 0 rgba(0,0,0,0.4);
			outline-color: $white-color;
			border-color: $white-color;
		}
	}
	.price-slider-amount {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.range-btn {
			border: 2px solid $text-color;
			width: 110px;
			height: 40px;
			line-height: 39px;
			@extend %basetransition;
			@include breakpoint(lg-device){
				width: 40px;
				font-size: 14px;
			}
			&:hover{
				background-color: $primary-color;
                border-color: $primary-color;
                color: $white-color;
			}
		}
		.label-input {
			label {
				font-size: 14px;
				margin: 0;
				text-transform: capitalize;
			}
			input {
				background: transparent none repeat scroll 0 0;
				border: medium none;
				box-shadow: none;
				font-size: 14px;
				height: inherit;
				padding-left: 5px;
				width: 99px;
			}
		}
	}
}

/* ---Color Option--- */
ul{
	&.color-option{
		li{
			label{
				@extend %basetransition;
				cursor: pointer;
				&:before{
					border-radius: 100%;
					border: 0;
				}
				&:after{
					content: '\ea20';
				}
			}
			input[type="checkbox"]:checked + label {
				&:before {
					border: 0;
				}
				&:after {
					opacity: 1;
				}
			}
			&.black-color{
				label{
					&:before{
						background-color: black;
					}
				}
			}
			&.blue-color{
				label{
					&:before{
						background-color: blue;
					}
				}
			}
			&.gray-color{
				label{
					&:before{
						background-color: gray;
					}
				}
			}
			&.green-color{
				label{
					&:before{
						background-color: green;
					}
				}
			}
			&:hover{
				label{
					color: $primary-color;
					&:after {
						opacity: 1;
					}
				}
			}
		}
	}
}

/* ---Widgets Checkbox--- */
.widgets-checkbox{
	li{
		label{
			&:after{
				content: '\ea20';
			}
		}
		input[type="checkbox"]:checked + label {
			&:before {
				background-color: $primary-color;
				border-color: $primary-color;
			}
			&:after {
				opacity: 1;
			}
		}
		&:hover{
			label{
				color: $primary-color;
				cursor: pointer;
				&:before{
					background-color: $primary-color;
					border-color: $primary-color;
				}
				&:after{
					opacity: 1;
				}
			}
		}
	}
}

/* ---Countdown--- */
.countdown-wrap {
	.countdown{
		&.item-4{
			.countdown__item{
				display: inline-block;
				position: relative;
				text-align: center;
				margin-left: 40px;
				position: relative;
				@include breakpoint(max-xs_device){
					margin-left: 20px;
				}
				&:first-child{
					margin-left: 0;
				}
				span {
					text-transform: capitalize;
					line-height: 1;
					&.countdown__time {
						font-size: 49px;
						@include breakpoint(max-sm_device){
							font-size: 25px;
						}
					}
					&.countdown__text {
						display: block;
						font-size: 18px;
						padding-top: 15px;
						@include breakpoint(max-sm_device){
							font-size: 14px;
						}
					}
				}
			}
		}
	}
	&.white-text_color{
		span {
			color: $white-color;
		}
	}
}

/* ---Image Hover Effect--- */
.img-hover_effect{
	&.single-img{
		position: relative;
		overflow: hidden;
		&:before{
			background-color: $black-color;
			content: "";
			top: 0;
			left: 0;
			position: absolute;
			width: 100%;
			height: 100%;
			opacity: 0;
			cursor: pointer;
			pointer-events: none;
			z-index: 1;
			@extend %basetransition;
		}
		img{
			@include transition(all 1.3s ease-in-out);
		}
	}
	&:hover{
		&.single-img{
			&:before{
				opacity: 0.5;
			}
			img{
				transform: scale(1.1);
			}
		}
	}
}

/* ---Newsletters Popup--- */
.popup_wrapper {
	background: rgba(0, 0, 0, 0.7) none repeat scroll 0 0;
	height: 100%;
	opacity: 0;
	position: fixed;
	@extend %basetransition;
	visibility: hidden;
	width: 100%;
	z-index: 9999;
	@include breakpoint (max-md_device) {
		display: none;
	}
	.test {
		background: $white-color;
		background-position: right;
		background-size: contain;
		background-repeat: no-repeat;
		bottom: 0;
		height: 425px;
		left: 0;
		margin: auto;
		max-width: 870px;
		position: absolute;
		right: 0;
		top: 0;
		@include breakpoint (min-lg_device) {
			max-width: 530px;
		}
		.popup_off {
			color: $secondary-color;
			top: 20px;
			right: 20px;
			display: block;
			cursor: pointer;
			position: absolute;
			@extend %basetransition;
			font-size: 25px;
			line-height: 1;
			&:hover{
				i{
					color: $primary-color;
					transform: rotate(180deg);
				}
			}
		}
	}
	.subscribe-area {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		height: 100%;
		width: 100%;
		.subscribe-content{
			text-align: center;
			width: calc(100% - 45px);
			h2{
				&.heading{
					font-size: 14px;
					line-height: 16.8px;
					max-width: 59%;
					margin: 0 auto;
				}
			}
			.subscribe-form-group {
				display: flex;
				flex-direction: column;
				align-items: center;
				form {
					&.subscribe-form{
						position: relative;
						width: 310px;
						input {
							border-bottom: 2px solid $secondary-color;
							background: $white-color;
							color: $secondary-color;
							border-left: 0;
							border-right: 0;
							border-top: 0;
							width: 100%;
							font-size: 14px;
							&::placeholder{
								color: $secondary-color;
								opacity: 1;
							}
						}
						.subscribe-bottom{
							text-align: left;
							label{
								&.label-field{
									font-size: 14px;
								}
							}
							input[type="checkbox"]:checked + label::after {
								color: #13aff0;
							}
						}
					}
				}
				button{
					&.subscribe-btn{
						position: absolute;
						top: 0;
						right: 0;
						font-size: 12px;
						text-transform: uppercase;
						font-weight: 500;
						line-height: 30px;
					}
				}
			}
		}
		.subscribe-img{
			position: relative;
			cursor: pointer;
			&:before{
				background-color: $secondary-color;
				width: 100%;
				height: 100%;
				content: "";
				top: 0;
				left: 0;
				position: absolute;
				opacity: 0;
				@extend %basetransition;
			}
			.inner-content{
				position: absolute;
				top: 50%;
				left: 30px;
				transform: translateY(-50%);
				text-align: center;
				h3{
					&.heading{
						max-width: 75%;
						margin: 0 auto;
					}
				}
			}
			&:hover{
				&:before{
					opacity: 0.5;
				}
				.inner-content{
					.button-wrap{
						a{
							&.skudmart-btn{
								background-color: $secondary-color;
								color: $white-color;
							}
						}
					}
				}
			}
		}
	}
}