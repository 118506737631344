/*----------------------------------------*/
/*  11 - Components - Animation
/*----------------------------------------*/
.swiper-slide{
	.inner-slide{
		.slide-content{
			opacity: 0;
		}
	}
	&.swiper-slide-active{
		.inner-slide{
			.slide-content{
				opacity: 1;
			}
		}
	}
}
/* ---Slider Animation--- */
.animation-style-01{
	&.swiper-slide-active{
		.slide-content{
			span{
				&.category{
					animation-name: fadeInUp;
					animation-delay: 0.5s;
					animation-duration: 1s;
					animation-fill-mode: both;
				}
			}
			h1{
				&.heading{
					animation-name: fadeInUp;
					animation-delay: 1s;
					animation-duration: 1s;
					animation-fill-mode: both;
				}
			}
			.price-box{
				animation-name: fadeInUp;
				animation-delay: 1.5s;
				animation-duration: 1s;
				animation-fill-mode: both;
			}
			.group-btn{
				.secondary-btn{
					animation-name: fadeInUp;
					animation-delay: 1.5s;
					animation-duration: 1s;
					animation-fill-mode: both;
				}
				.primary-btn{
					animation-name: fadeInUp;
					animation-delay: 2s;
					animation-duration: 1s;
					animation-fill-mode: both;
				}
			}
			.button-wrap{
				animation-name: fadeInUp;
				animation-delay: 2s;
				animation-duration: 1s;
				animation-fill-mode: both;
			}
		}
	}
}
.animation-style-02{
	&.swiper-slide-active{
		.slide-content{
			span{
				&.category{
					animation-name: fadeInLeft;
					animation-delay: 0.5s;
					animation-duration: 1s;
					animation-fill-mode: both;
				}
				&.product-offer{
					animation-name: fadeInLeft;
					animation-delay: 1s;
					animation-duration: 1s;
					animation-fill-mode: both;
				}
			}
			.button-wrap{
				animation-name: fadeInUp;
				animation-delay: 1.5s;
				animation-duration: 1s;
				animation-fill-mode: both;
			}
		}
	}
}
.animation-style-03{
	&.swiper-slide-active{
		.slide-img{
			animation-name: fadeInUp;
			animation-delay: 0.5s;
			animation-duration: 1s;
			animation-fill-mode: both;
		}
		.slide-content{
			span{
				&.category{
					animation-name: fadeInUp;
					animation-delay: 1.5s;
					animation-duration: 1s;
					animation-fill-mode: both;
				}
			}
			h2{
				&.heading{
					animation-name: fadeInUp;
					animation-delay: 2s;
					animation-duration: 1s;
					animation-fill-mode: both;
				}
			}
			.price-box{
				animation-name: fadeInUp;
				animation-delay: 2.5s;
				animation-duration: 1s;
				animation-fill-mode: both;
			}
			.button-wrap{
				animation-name: fadeInUp;
				animation-delay: 3s;
				animation-duration: 1s;
				animation-fill-mode: both;
			}
		}
	}
}
.animation-style-04{
	&.swiper-slide-active{
		.slide-img{
			animation-name: fadeInRight;
			animation-delay: 0.3s;
			animation-duration: 1s;
			animation-fill-mode: both;
		}
		.slide-content{
			h1{
				&.heading{
					animation-name: fadeInLeft;
					animation-delay: 0.5s;
					animation-duration: 1s;
					animation-fill-mode: both;
				}
			}
			.color-option{
				span{
					&.title{
						animation-name: fadeInLeft;
						animation-delay: 1s;
						animation-duration: 1s;
						animation-fill-mode: both;
					}
				}
				ul{
					li{
						
						a{
							animation-name: colorList;
							animation-delay: 1.5s;
							animation-fill-mode: both;
						}
						&:nth-child(1) {
							a{
								animation-duration: 1s;
							}
						}
						&:nth-child(2) {
							a{
								animation-duration: 1.5s;
							}
						}
						&:nth-child(3) {
							a{
								animation-duration: 2s;
							}
						}
					}
				}
			}
			.price-box{
				animation-name: fadeInLeft;
				animation-delay: 2s;
				animation-duration: 1s;
				animation-fill-mode: both;
			}
			.button-wrap{
				animation-name: fadeInUp;
				animation-delay: 2.5s;
				animation-duration: 1s;
				animation-fill-mode: both;
			}
		}
	}
}
.animation-style-05{
	&.swiper-slide-active{
		.slide-content{
			.slide-img{
				animation-name: fadeInUp;
				animation-delay: 0.5s;
				animation-duration: 1s;
				animation-fill-mode: both;
			}
			h3{
				&.heading{
					animation-name: fadeInUp;
					animation-delay: 0.5s;
					animation-duration: 1s;
					animation-fill-mode: both;
				}
			}
			.product-offer{
				animation-name: fadeInUp;
				animation-delay: 1s;
				animation-duration: 1s;
				animation-fill-mode: both;
			}
			p{
				&.short-desc{
					animation-name: fadeInUp;
					animation-delay: 1s;
					animation-duration: 1s;
					animation-fill-mode: both;
				}
			}
			.read-more{
				animation-name: fadeInUp;
				animation-delay: 1.5s;
				animation-duration: 1s;
				animation-fill-mode: both;
			}
			.button-wrap{
				animation-name: fadeInUp;
				animation-delay: 2s;
				animation-duration: 1s;
				animation-fill-mode: both;
			}
		}
	}
}
.animation-style-06{
	&.swiper-slide-active{
		.slide-content{
			.category{
				animation-name: fadeInUp;
				animation-delay: 0.5s;
				animation-duration: 1s;
				animation-fill-mode: both;
			}
			.product-offer{
				animation-name: fadeInUp;
				animation-delay: 1s;
				animation-duration: 1s;
				animation-fill-mode: both;
			}
			.button-wrap{
				animation-name: fadeInUp;
				animation-delay: 2s;
				animation-duration: 1s;
				animation-fill-mode: both;
			}
		}
		.slide-img{
			.inner-img{
				animation-name: zoomIn;
				animation-delay: 1s;
				animation-duration: 1s;
				animation-fill-mode: both;
			}
			.front-img{
				animation-name: fadeIn;
				animation-delay: 1.5s;
				animation-duration: 1.8s;
				animation-fill-mode: both;
			}
			.sticker-img{
				animation-name: skudZoomIn;
				animation-delay: 1.8s;
				animation-duration: 1.8s;
				animation-fill-mode: both;
			}
		}
	}
}
.animation-style-07{
	&.swiper-slide-active{
		.slide-content{
			.slide-img{
				animation-name: skudZoomIn;
				animation-delay: 0.3s;
				animation-duration: 1s;
				animation-fill-mode: both;
			}
			h1{
				&.heading{
					animation-name: skudZoomIn;
					animation-delay: 1s;
					animation-duration: 1s;
					animation-fill-mode: both;
				}
			}
			.product-collection{
				animation-name: fadeIn;
				animation-delay: 1.2s;
				animation-duration: 1s;
				animation-fill-mode: both;
				&::before{
					animation-name: skudSlideRight;
					animation-delay: 1.3s;
					animation-duration: 0.5s;
					animation-fill-mode: both;
				}
				&::after{
					animation-name: skudSlideLeft;
					animation-delay: 1.4s;
					animation-duration: 0.5s;
					animation-fill-mode: both;
				}
			}
			.button-wrap{
				animation-name: fadeInUp;
				animation-delay: 2s;
				animation-duration: 1s;
				animation-fill-mode: both;
			}
		}
	}
}

/* ---Animated Image--- */
.animated-img {
	&.style-01{
		position: relative;
		text-align: center;
		animation: sk-rotate 7.0s infinite linear;
		width: 35%;
		@include breakpoint(max-sm_device){
			top: 20%;
		}
		@include breakpoint(max-xs_device){
			top: 50%;
		}
		@include breakpoint(max-xxs_device){
			width: 45%;
		}
		img {
		  margin: 5%;
		}
	}
	@keyframes sk-rotate { 100% { transform: rotate(360deg); }}
}

/* ---Color List--- */
@keyframes colorList {
	from {
	  transform: scaleY(0);
	  transform-origin: bottom;
	}
	to {
		transform: scaleY(1);
		transform-origin: bottom;
	}
}

/* ---Skudmart's Zoom In--- */
@keyframes skudZoomIn{
	0%{
		transform: scale(0.7);
		opacity: 0;
	}
	100%{
		transform: scale(1);
		opacity: 1;
	}
}

/* ---skudmart's Slide Left--- */
@keyframes skudSlideLeft{
	0%{
		transform: translateX(-50px);
		opacity: 0;
	}
	100%{
		transform: translateX(0);
		opacity: 1;
	}
}
/* ---skudmart's Slide Right--- */
@keyframes skudSlideRight{
	0%{
		transform: translateX(50px);
		opacity: 0;
	}
	100%{
		transform: translateX(0);
		opacity: 1;
	}
}

/*skudmart's Slide Up Animation*/
@keyframes skudSlideInUp {
	0% {
		transform: translateY(250px);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

/*Top Down Keyframes*/

@-webkit-keyframes skudSlideInDown {
	0% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(100px);
	}
  }
  @keyframes skudSlideInDown {
	0% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(100px);
	}
  }

  .skudSlideInDown {
	animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

// Background Animation
.kenburns-top {
	animation: kenburns-top 5s linear 1 normal both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-3-13 17:17:18
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation kenburns-top
 * ----------------------------------------
 */
@keyframes kenburns-top {
  0% {
	transform: scale(1) translateY(0);
	transform-origin: 50% 16%;
  }
  100% {
	transform: scale(1.1) translateY(-15px);
	transform-origin: top;
  }
}