/*----------------------------------------*/
/*  15 - Components - Form
/*----------------------------------------*/
.form-area{
    h3{
        &.heading{
            @include typography(30px, 36px);
            padding-bottom: 15px;
        }
    }
}
form{
    &.feedback-form{
        .form-field{
            margin-bottom: 0;
            padding-bottom: 20px;
            textarea{
                &.textarea-field{
                    height: 220px;
                    width: 100%;
                    padding: 25px;
                }
            }
            input{
                &.input-field{
                    width: 100%;
                    height: 50px;
                    padding: 0 25px;
                }
            }
            &.group-input{
                display: flex;
                @include breakpoint(max-sm_device){
                    flex-direction: column;
                    input{
                        &[class*="input-field"]{
                            &:not(:last-child){
                                margin-bottom: 20px;
                            }
                        }
                    }
                }
            }
        }
        .inquiry-field{
            .nice-select{
                padding: 0 25px;
                height: 50px;
                line-height: 50px;
            }
        }
        .form-btn_wrap{
            padding-top: 25px;
        }
        &.with-border-bottom{
            .form-field{
                textarea{
                    &.textarea-field{
                        border: 0;
                        border-bottom: 1px solid $very-light-grey_color;
                        @extend %basetransition;
                        padding: 0;
                        &:focus{
                            border: 0;
                            border-bottom: 1px solid $secondary-color;
                            color: $secondary-color;
                        }
                    }
                }
                input{
                    &.input-field{
                        border: 0;
                        border-bottom: 1px solid $very-light-grey_color;
                        @extend %basetransition;
                        padding: 0;
                        &:focus{
                            border: 0;
                            border-bottom: 1px solid $secondary-color;
                            color: $secondary-color;
                        }
                    }
                }
            }
        }
    }
}