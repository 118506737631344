/*----------------------------------------*/
/*  28 - Components - Multiple Section
/*----------------------------------------*/
.multiple-section{
    position: relative;
    @media (min-width: 1280px){
        width: calc(100% - 370px);
        margin-left: auto;
    }
    .multiple-section_body{
        .product-item{
            text-align: center;
            .single-content{
                position: relative;
                @extend %basetransition;
                transition-delay: 280ms;
                opacity: 0;
                visibility: hidden;
                transform: translateY(50px);
                .product-name{
                    font-size: 14px;
                }
            }
            &:hover{
                .single-content{
                    transform: translateY(0);
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
        .banner-item{
            &.style-01{
                .inner-content{
                    text-align: center;
                    @include absCenter;
                    width: 100%;
                    z-index: 1;
                    span{
                        &.category{
                            text-transform: uppercase;
                            font-size: 18px;
                            line-height: 21.6px;
                            letter-spacing: 1px;
                            display: block;
                        }
                    }
                    h2{
                        &.heading{
                            font-size: 66px;
                            line-height: 66px;
                        }
                        &.playfair-font{
                            font-family: 'Playfair Display', serif;
                            font-style: italic;
                        }
                    }
                }
            }
            &.style-02{
                .inner-content{
                    .inner-img{
                        img{
                            transform: scale(1);
                            @extend %basetransition;
                        }
                    }
                }
                &:hover{
                    .inner-img{
                        img{
                            transform: scale(0.8);
                        }
                    }
                }
            }
        }
    }
    .header-logo{
        text-align: left;
        @media (min-width: 1280px){
            display: none;
        }
    }
    .header-right{
        ul{
            li{
                &.mobile-menu_wrap{
                    @media (min-width: 1280px){
                        display: none;
                    }
                }
            }
        }
    }
}

/* ---Multiple Section Two--- */
.multiple-section-2{
    .custom-col{
        flex: 0 0 20%;
        max-width: 20%;
        @include breakpoint(max-xl_device){
            flex: 0 0 25%;
            max-width: 25%;
        }
    }
    .custom-col-2{
        flex: 0 0 80%;
        max-width: 80%;
        @include breakpoint(max-xl_device){
            flex: 0 0 75%;
            max-width: 75%;
        }
        @media (max-width: 1300px){
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    .multiple-section_sidebar{
        @media (max-width: 1300px) {
            display: none;
        }
        .widget-item_area{
            .heading-area{
                background-color: $primary-color-2;
                display: flex;
                padding: 15px;
                i{
                    color: $secondary-color;
                    margin-right: 10px;
                }
                h3{
                    &.heading{
                        font-size: 18px;
                        line-height: 21.6px;
                    }
                }
            }
            .widget-item{
                border: 1px solid $border-color;
                .product-link{
                    text-transform: uppercase;
                    position: relative;
                    padding-top: 10px;
                    font-size: 13px;
                    &:before{
                        background-color: $border-color;
                        position: absolute;
                        content: "";
                        top: 0;
                        left: 50%;
                        height: 1px;
                        width: 40px;
                        transform: translateX(-50%);
                    }
                }
                ul{
                    &.widget-category{
                        li{
                            &:not(:last-child){
                                &.title{
                                    > a{
                                        padding-bottom: 15px;
                                    }
                                }
                            }
                            &.title{
                                > a{
                                    text-transform: uppercase;
                                    font-weight: 500;
                                    display: block;
                                }
                            }
                            > ul{
                                li{
                                    &:not(:last-child){
                                        a{
                                            padding-bottom: 5px;
                                        }
                                    }
                                    a{
                                        font-size: 14px;
                                        line-height: 25.2px;
                                        display: block;
                                    }
                                }
                            }
                        }
                    }
                }
                .list-product{
                    display: flex;
                    &[class*="list-product"]{
                        &:not(:last-child){
                            border-bottom: 1px solid $border-color;
                            padding-bottom: 30px;
                            margin-bottom: 20px;
                        }
                    }
                    .single-img{
                        border: 1px solid $border-color;
                        width: 30%;
                        img{
                            width: 100%;
                            object-fit: cover;
                            object-position: center center;
                            height: 100%;
                        }
                    }
                    .single-content{
                        padding-left: 15px;
                        width: 70%;
                        .product-name{
                            text-transform: uppercase;
                            font-size: 12px;
                            line-height: 23px;
                            display: block;
                        }
                        .price-box{
                            span{
                                font-size: 14px;
                                &.new-price{
                                    color: $secondary-color;
                                    font-weight: 500;
                                }
                            }
                        }
                    }
                }
                .newsletter-item{
                    p{
                        &.short-desc{
                            font-size: 14px;
                        }
                    }
                    .subscribe-widget{
                        width: 100%;
                        li{
                            &.subscribe-form_wrap{
                                form{
                                    input{
                                        &.input-field{
                                            width: 100%;
                                            height: 50px;
                                            padding: 15px 100px 15px 15px;
                                        }
                                        &::placeholder{
                                            color: $secondary-color;
                                            opacity: 1;
                                        }
                                    }
                                    button{
                                        &.subscribe-btn{
                                            top: 50%;
                                            transform: translateY(-50%);
                                            right: 15px;
                                            font-size: 12px;
                                            text-transform: uppercase;
                                            font-weight: 500;
                                            &:hover{
                                                color: inherit;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .tag-list{
                    li{
                        display: inline-block;
                        a{
                            border: 1px solid $border-color;
                            text-emphasis: none;
                            font-size: 12px;
                            padding: 4px 8px;
                            margin: 0 2px 5px;
                            display: block;
                            &:hover{
                                background-color: $primary-color-2;
                                border-color: $primary-color-2;
                                color: $white-color;
                            }
                        }
                    }
                }
            }
        }
    }
    .multiple-section_banner{
        &.bg-01{
            background-image: url('../images/multiple-section/bg/1-1402-220.webp');
			background-repeat: no-repeat;
			background-size: cover;
			height: 220px;
        }
        &.bg-02{
            background-image: url('../images/multiple-section/bg/2-1402-220.webp');
			background-repeat: no-repeat;
			background-size: cover;
			height: 220px;
        }
        &.style-01{
            .inner-content{
                display: flex;
                align-items: center;
                height: 100%;
                @include breakpoint(max-sm_device){
                    flex-direction: column;
                    justify-content: center;
                }
                .text-leftside{
                    width: 50%;
                    text-align: center;
                    @include breakpoint(max-sm_device){
                        width: 100%;
                    }
                    .font-italic{
                        *{
                            font-style: italic;
                        }
                    }
                    span{
                        &.category{
                            font-size: 36px;
                            line-height: 36px;
                            @include breakpoint(max-md_device){
                                font-size: 16px;
                                line-height: 1.2;
                            }
                        }
                    }
                    h2{
                        &.heading{
                            font-size: 66px;
                            line-height: 66px;
                            @include breakpoint(max-xl_device){
                                font-size: 55px;
                            }
                            @include breakpoint(max-md_device){
                                font-size: 25px;
                                line-height: 1.2;
                            }
                        }
                    }
                }
                .text-rightside{
                    padding-left: 20%;
                    width: 50%;
                    @include breakpoint(max-sm_device){
                        text-align: center;
                        width: 100%;
                        padding-left: 0;
                    }
                    h3{
                        &.product-offer{
                            font-size: 80px;
                            line-height: 80px;
                            @include breakpoint(max-xl_device){
                                font-size: 65px;
                            }
                            @include breakpoint(max-md_device){
                                font-size: 25px;
                                line-height: 1.2;
                            }
                        }
                    }
                    .button-wrap{
                        @include breakpoint(max-sm_device){
                            justify-content: center;
                        }
                    }
                }
            }
        }
        &.style-02{
            position: relative;
            .inner-content{
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                width: 50%;
                text-align: center;
                @include breakpoint(max-sm_device){
                    width: 100%;
                }
                h2{
                    &.heading{
                        background: linear-gradient(to 270deg, #f64d4d 0%, #c965b7 100%);
                        background-clip: text;
                        -webkit-text-fill-color: transparent;
                        font-size: 66px;
                        line-height: 66px;
                        @include breakpoint(max-xl_device){
                            font-size: 50px;
                        }
                        @include breakpoint(max-md_device){
                            font-size: 25px;
                            line-height: 1.2;
                        }
                    }
                }
            }
        }
    }
    .testimonial-with_instagram{
        .section-space{
            @include breakpoint(max-md_device){
                padding-bottom: 30px;
            }
        }
        .testimonial-area{
            padding: 45px;
            height: 100%;
            h2{
                &.heading{
                    font-size: 22px;
                    line-height: 22px;
                }
            }
            .single-testimonial_slider{
                .testimonial-item{
                    .single-content{
                        padding-right: 0;
                    }
                }
                .swiper-pagination{
                    position: relative;
                    bottom: 60px;
                }

            }
        }
        .instagram-area{
            padding: 45px;
            height: 100%;
        }
    }
}