/*----------------------------------------*/
/*  26 - Components - Banner
/*----------------------------------------*/
.banner-area{
    padding-top: 215px;
    @include breakpoint(max-md_device){
        padding-top: 100px;
    }
    .banner-nav{
        background-color: $white-smoke_color;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        @include breakpoint(max-md_device){
            flex-direction: column-reverse;
            padding: 30px;
        }
        .single-content{
            padding-left: 220px;
            @include breakpoint(max-lg_device){
                padding-left: 80px;
            }
            @include breakpoint(max-md_device){
                padding-left: 0;
                padding-top: 25px;
                text-align: center;
            }
            span{
                &.category{
                    color: $secondary-color;
                    font-size: 20px;
                    line-height: 24px;
                    display: block;
                    padding-bottom: 10px;
                }
            }
            h2{
                &.product-offer{
                    font-size: 80px;
                    line-height: 80px;
                    padding-bottom: 20px;
                    @include breakpoint(max-lg_device){
                        font-size: 40px;
                        line-height: 40px;
                    }
                }
            }
            p{
                &.short-desc{
                    font-family: 'Playfair Display';
                    color: $secondary-color;
                    font-style: italic;
                    padding-bottom: 25px;
                }
            }
            .button-wrap{
                @include breakpoint(max-md_device){
                    justify-content: center;
                }
            }
        }
        .single-img{
            display: flex;
            .front-img {
                position: absolute;
                bottom: 0;
                right: 0;
                @include breakpoint(max-md_device){
                    position: relative;
                }
                img{
                    width: 85%;
                    @include breakpoint(max-md_device){
                        width: 100%;
                    }
                }
            }
            .inner-img{
                background-color: $white-color;
                width: 75%;
                margin-left: auto;
                @include breakpoint(max-md_device){
                    display: none;
                }
            }
        }
    }
}

/* ---Banner Area Two--- */
.banner-area-2{
    .single-img{
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
        }
        .inner-content{
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            text-align: center;
            margin: 0 auto;
            width: 100%;
            z-index: 1;
            h3{
                &.banner-title{
                    @include breakpoint(max-lg_device){
                        font-size: 18px;
                        line-height: 21.6px;
                    }
                }
            }
        }
    }
}

/* ---Banner Area Three--- */
.banner-area-3{
    .scrollax-bg{
        background-image: url('../images/banner/3-1-1920x840.webp');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: right;
        position: relative;
        height: 550px;
        @include breakpoint(max-md_device){
            height: 450px;
        }
        @include breakpoint(max-sm_device){
            background-position: left;
        }
        .single-content{
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            width: 30%;
            @include breakpoint(max-lg_device){
                width: 40%;
            }
            @include breakpoint(max-sm_device){
                width: calc(100% - 60px);
                left: 30px;
                right: auto;
            }
        }
    }
    .single-content{
        h2{
            &.heading{
                font-size: 24px;
                line-height: 1.2;
            }
        }
        span{
            &.product-offer{
                color: $secondary-color;
                font-size: 36px;
                line-height: 64.8px;
                text-transform: lowercase;
                display: block;
                strong{
                    font-size: 120px;
                    line-height: 80px;
                    font-weight: 400;
                    vertical-align: middle;
                }
                sup{
                    vertical-align: super;
                }
            }
        }
    }
}

/* ---Banner Area Four--- */
.banner-area-4{
    .banner-bg{
        background-image: url('../images/banner/4-1-1920x494.webp');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: right;
        position: relative;
        height: 500px;
        .inner-content{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            height: 100%;
            @include breakpoint(max-xs_device){
                padding-left: 15px;
                padding-right: 15px;
            }
            h2{
                &.heading{
                    position: relative;
                    padding-left: 80px;
                    padding-right: 80px;
                    font-size: 36px;
                    line-height: 43.2px;
                    @include breakpoint(max-xs_device){
                        font-size: 26px;
                        padding-left: 0;
                        padding-right: 0;
                    }
                    &:before{
                        background-color: $primary-color;
                        width: 50px;
                        height: 2px;
                        content: "";
                        position: absolute;
                        top: 50%;
                        left: 0;
                        transform: translateY(-50%);
                        @include breakpoint(max-xs_device){
                            content: none;
                        }
                    }
                    &:after{
                        background-color: $primary-color;
                        width: 50px;
                        height: 2px;
                        content: "";
                        position: absolute;
                        top: 50%;
                        right: 0;
                        transform: translateY(-50%);
                        @include breakpoint(max-xs_device){
                            content: none;
                        }
                    }
                }
            }
            p{
                &.short-desc{
                    max-width: 45%;
                    text-align: center;
                    font-size: 14px;
                    line-height: 33.9px;
                    text-transform: uppercase;
                    @include breakpoint(max-lg_device){
                        max-width: 65%;
                    }
                    @include breakpoint(max-lg_device){
                        max-width: 95%;
                    }
                }
            }
        }
    }
}

/* ---Banner Area Five--- */
.banner-area-5{
    .scrollax-bg{
        background-image: url('../images/banner/5-1-1920x658.webp');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: right;
        position: relative;
        height: 550px;
        @include breakpoint(max-md_device){
            height: 450px;
        }
        @include breakpoint(max-sm_device){
            background-position: left;
        }
        .single-content{
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            width: 30%;
            @include breakpoint(max-lg_device){
                width: 40%;
            }
            @include breakpoint(max-sm_device){
                width: 100%;
                left: 30px;
            }
        }
        .single-content{
            h2{
                &.heading{
                    font-size: 24px;
                    line-height: 24px;
                }
            }
            span{
                &.product-offer{
                    color: $secondary-color;
                    font-size: 36px;
                    line-height: 64.8px;
                    text-transform: lowercase;
                    display: block;
                    strong{
                        font-size: 120px;
                        line-height: 80px;
                        font-weight: 400;
                        vertical-align: middle;
                    }
                    sup{
                        vertical-align: super;
                    }
                }
            }
        }
    }
}

/* ---Banner Area Six--- */
.banner-area-6{
    .single-content{
        @include breakpoint(max-sm_device){
            padding-top: 20px;
        }
        span{
            &.category{
                padding-bottom: 5px;
                display: block;
            }
        }
        h2{
            &.sub-heading{
                font-family: 'Playfair Display', serif;
                font-style: italic;
                font-size: 46px;
                line-height: 46px;
                @include breakpoint(max-md_device){
                    font-size: 35px;
                }
            }
        }
        p{
            &.short-desc{
                line-height: 32px;
            }
        }
    }
}

/* ---Banner Area Eight--- */
.banner-area-8{
    .scrollax-bg{
        background-image: url('../images/banner/7-1920x723.webp');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: right;
        position: relative;
        height: 720px;
        @include breakpoint(max-lg_device){
            height: 485px;
            background-position: center;
        }
        .single-content{
            position: absolute;
            top: 50%;
            left: 10%;
            transform: translateY(-50%);
            text-align: center;
            @include breakpoint(max-sm_device){
                width: calc(100% - 30px);
                left: 15px;
            }
            h2{
                &.heading{
                    font-family: 'Playfair Display', serif;
                    color: $white-color;
                    font-style: italic;
                    font-weight: 400;
                    font-size: 90px;
                    line-height: 90px;
                    @include breakpoint(max-sm_device){
                        font-size: 45px;
                        line-height: 50px;
                    }
                }
            }
            .popup-btn{
                a{
                    &.popup-vimeo{
                        background-color: $white-color;
                        border-radius: 100%;
                        width: 75px;
                        height: 75px;
                        line-height: 78px;
                        text-align: center;
                        margin: 0 auto;
                        display: block;
                        font-size: 34px;
                        &:hover{
                            background-color: $secondary-color;
                            color: $white-color;
                        }
                    }

                }
            }
        }
    }
}

/* ---Banner Area Nine--- */
.banner-area-9{
    .banner-item{
        .single-img{
            position: relative;
            cursor: pointer;
            .inner-content{
                position: absolute;
                top: 50%;
                left: 50px;
                transform: translateY(-50%);
                width: 100%;
                z-index: 1;
                &.text-position_center{
                    left: 50%;
                    transform: translate(-50%, -50%);
                    text-align: center;
                }
                .playfair-font{
                    font-family: 'Playfair Display', serif;
                    font-style: italic;
                }
                span{
                    &.category{
                        font-size: 24px;
                        line-height: 24px;
                        display: block;
                    }
                }
                h2{
                    &.heading{
                        font-size: 46px;
                        line-height: 46px;
                        @include breakpoint(max-xxs_device){
                            font-size: 30px;
                            line-height: 30px;
                        }
                    }
                }
                .button-wrap{
                    .skudmart-btn{
                        @include breakpoint(max-xs_device){
                            width: 110px;
                            height: 35px;
                            line-height: 35px;
                            font-size: 13px;
                        }
                    }
                }
            }
        }
        &:hover{
            .skudmart-btn{
                background-color: $primary-color-2;
                color: $white-color;
            }
        }
    }
    [class*="col-"]{
        &:not(:last-child){
            .banner-item{
                @include breakpoint(max-lg_device){
                    padding-bottom: 30px;
                }
            }
        }
    }
}

/* ---Banner Area Ten--- */
.banner-area-10{
    .scrollax-bg{
        background-image: url('../images/banner/10-1-1920x384.webp');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom;
        height: 384px;
        @include breakpoint(max-lg_device){
            height: 360px;
        }
        .banner-item{
            position: relative;
            height: 100%;
            overflow: hidden;
            .banner-content{
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                height: 100%;
                width: 55%;
                text-align: center;
                z-index: 1;
                @include breakpoint(max-xs_device){
                    width: 100%;
                }
                span{
                    color: $secondary-color;
                    &.category{
                        font-family: 'Playfair Display', serif;
                        font-style: italic;
                        font-size: 24px;
                        line-height: 24px;
                    }
                }
                h2{
                    &.heading{
                        text-shadow: 6px 6px 0 rgba(0, 0, 0, .5);
                        color: $white-color;
                        font-size: 88px;
                        font-weight: 700;
                        @include breakpoint(max-md_device){
                            font-size: 50px;
                        }
                    }
                }
                span{
                    &.product-offer{
                        font-size: 18px;
                        line-height: 18px;
                    }
                }
            }
            .banner-img_wrap{
                position: absolute;
                left: 0;
                bottom: -105px;
                display: flex;
                justify-content: space-between;
                width: 60%;
                @include breakpoint(max-md_device){
                    bottom: -70px;
                }
                @include breakpoint(max-xs_device){
                    width: 100%;
                }
            }
        }
    }
}

/* ---Bnner Area Eleven--- */
.banner-area-11{
    .banner-item{
        height: 100%;
        .single-img{
            position: relative;
            height: 100%;
            .inner-content{
                position: absolute;
                top: 70%;
                left: 50%;
                transform: translate(-50%, -50%);
                text-align: center;
                width: 100%;
                z-index: 1;
                opacity: 0;
                visibility: hidden;
                @extend %basetransition;
                p{
                    &.short-desc{
                        font-size: 14px;
                        max-width: 65%;
                        margin: 0 auto;
                        @include breakpoint(max-lg_device){
                            max-width: 95%;
                        }
                    }
                }
            }
            &:hover{
                .inner-content{
                    opacity: 1;
                    visibility: visible;
                    top: 50%;
                }
            }
        }
    }
    .banner-wrap{
        @include breakpoint(max-md_device){
            padding-top: 30px;
        }
    }
}

/* ---Banner Area Twelve--- */
.banner-area-12{
    .section-title_area-2{
        span{
            &.category{
                text-transform: uppercase;
                letter-spacing: 10px;
                font-size: 14px;
                line-height: 14px;
                font-weight: 500;
                display: block;
            }
        }
        h2{
            &.heading{
                font-size: 36px;
                line-height: 1.4;
                letter-spacing: 2px;
                font-weight: 500;
                @include breakpoint(max-sm_device){
                    font-size: 30px;
                }
            }
        }
        p{
            &.short-desc{
                font-size: 16px;
                max-width: 55%;
                margin: 0 auto;
                text-align: center;
                @include breakpoint(max-lg_device){
                    max-width: 85%;
                }
                @include breakpoint(max-sm_device){
                    max-width: 100%;
                }
            }
        }
    }
    .banner-slide{
        position: relative;
        .inner-content{
            @include absCenter;
            width: 100%;
            text-align: center;
            h3{
                &.banner-title{
                    font-size: 28px;
                    line-height: 33.6px;
                    letter-spacing: 2px;
                }
            }
        }
    }
}

/* ---Banner Area Thirteen--- */
.banner-area-13{
    .banner-item{
        display: flex;
        @include breakpoint(max-sm_device){
            flex-direction: column;
        }
        .banner-img{
            position: relative;
            width: 50%;
            @include breakpoint(max-sm_device){
                width: 100%;
            }
            .inner-img{
                position: absolute;
                right: 50px;
                bottom: 50px;
                @include breakpoint(max-xs_device){
                    right: 15px;
                    bottom: 50px;
                    width: calc(100% - 30px);
                }
            }
        }
        .banner-content{
            padding: 5%;
            width: 50%;
            @include breakpoint(max-sm_device){
                width: 100%;
            }
            @include breakpoint(max-xs_device){
                padding: 8% 5%;
            }
            span{
                &.category{
                    font-size: 18px;
                    line-height: 18px;
                    letter-spacing: 1px;
                    display: block;
                    color: $secondary-color;
                    text-transform: uppercase;
                    font-weight: 500;
                    padding-left: 85px;
                }
            }
            h2{
                &.heading{
                    font-size: 56px;
                    line-height: 67.2px;
                    position: relative;
                    padding-left: 85px;
                    @include breakpoint(max-xl_device){
                        font-size: 40px;
                        line-height: 46.2px;
                    }
                    @include breakpoint(max-lg_device){
                        font-size: 23px;
                        line-height: 1.4;
                    }
                    @include breakpoint(max-sm_device){
                        font-size: 20px;
                    }
                    &:before{
                        background-color: $secondary-color;
                        content: "";
                        width: 60px;
                        height: 5px;
                        position: absolute;
                        top: 50%;
                        left: 0;
                        transform: translateY(-50%);
                    }
                }
            }
            p{
                &.short-desc{
                    line-height: 30px;
                }
            }
        }
        &.style-02{
            @include breakpoint(max-sm_device){
                flex-direction: column-reverse;
            }
        }
    }
}

/* ---Banner Area Fourteen--- */
.banner-area-14{
    position: relative;
    &.banner-bg{
        background-image: url('../images/banner/14-1.webp');
        background-repeat: no-repeat;
        background-size: cover;
        height: 665px;
        @include breakpoint(max-xxs_device){
            height: 440px;
        }
    }
    .banner-content{
        display: flex;
        align-items: center;
        height: 100%;
        .inner-content{
            border: 5px solid $secondary-color;
            padding: 30px;
            width: 445px;
            text-align: center;
            h3{
                &.banner-title{
                    font-size: 18px;
                    line-height: 18px;
                    letter-spacing: 1px;
                }
            }
            h4{
                &.product-offer{
                    font-size: 80px;
                    line-height: 80px;
                    letter-spacing: 6px;
                    @include breakpoint(max-xxs_device){
                        font-size: 40px;
                        line-height: 1.4;
                        letter-spacing: 2px;
                    }
                }
            }
            span{
                font-size: 14px;
                line-height: 14px;
                letter-spacing: 14px;
                text-transform: uppercase;
                color: $secondary-color;
                font-weight: 500;
                display: block;
                @include breakpoint(max-xxs_device){
                    line-height: 1.4;
                    letter-spacing: 2px;
                }
            }
        }
    }
}

/* ---Banner Area Fifteen--- */
.banner-area-15{
    .parallax-banner{
        background-repeat: no-repeat;
        background-size: cover;
        height: 940px;
        @include breakpoint(max-sm_device){
            height: 550px;
        }
        &.bg-01{
            background-image: url('../images/banner/15-1.webp');
        }
        &.bg-02{
            background-image: url('../images/banner/15-2.webp');
        }
        &.bg-03{
            background-image: url('../images/banner/15-3.webp');
        }
        &.bg-04{
            background-image: url('../images/banner/15-4.webp');
        }
        .inner-content{
            display: inline-flex;
            height: 100%;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            h1{
                &.heading{
                    font-size: 56px;
                    line-height: 56px;
                    letter-spacing: 14px;
                    @include breakpoint(max-md_device){
                        font-size: 45px;
                        letter-spacing: 5px;
                    }
                    @include breakpoint(max-sm_device){
                        font-size: 26px;
                    }
                }
            }
            span{
                &.banner-collection{
                    color: $secondary-color;
                    position: relative;
                    font-size: 26px;
                    line-height: 31.2px;
                    display: block;
                    @include breakpoint(max-sm_device){
                        font-size: 16px;
                    }
                    &:before{
                        border-top: 3px solid;
                        width: 30px;
                        content: "";
                        display: inline-block;
                        vertical-align: middle;
                        margin-right: 10px;
                    }
                    &:after{
                        border-top: 3px solid;
                        width: 30px;
                        content: "";
                        display: inline-block;
                        vertical-align: middle;
                        margin-left: 10px;
                    }
                    &.lg-size{
                        &:before{
                            width: 200px;
                            margin-right: 25px;
                            @include breakpoint(max-lg_device){
                                width: 50px;
                            }
                            @include breakpoint(max-lg_device){
                                width: 30px;
                            }
                        }
                        &:after{
                            width: 200px;
                            margin-left: 25px;
                            @include breakpoint(max-lg_device){
                                width: 50px;
                            }
                            @include breakpoint(max-lg_device){
                                width: 30px;
                            }
                        }
                    }
                    &.md-size{
                        &:before{
                            width: 50px;
                            margin-right: 25px;
                        }
                        &:after{
                            width: 50px;
                            margin-left: 25px;
                        }
                    }
                    &.white-color{
                        color: $white-color;
                        &:before,
                        &:after{
                            color: $white-color;
                        }
                    }
                }
            }
            &.style-02{
                .category{
                    font-family: 'Playfair Display', serif;
                    font-style: italic;
                    font-size: 32px;
                    line-height: 32px;
                    display: block;
                }
                h1{
                    &.heading{
                        font-style: italic;
                        letter-spacing: 0;
                    }
                }
            }
            &.style-03{
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}

/* ---Banner Area Sixteen--- */
.banner-area-16{
    .banner-item{
        .single-img{
            position: relative;
            .inner-content{
                position: absolute;
                left: 85px;
                bottom: 85px;
                z-index: 1;
                @include breakpoint(max-sm_device){
                    left: 30px;
                    width: calc(100% - 60px);
                }
                span{
                    &.category{
                        font-size: 32px;
                        line-height: 38.4px;
                        letter-spacing: 2px;
                        display: block;
                        @include breakpoint(max-lg_device){
                            font-size: 16px;
                            line-height: 1.4;
                            padding-bottom: 0;
                        }
                    }
                    &.playfair-font{
                        font-family: 'Playfair Display', serif;
                        font-style: italic;
                    }
                }
                h3{
                    &.product-offer{
                        font-size: 100px;
                        line-height: 100px;
                        letter-spacing: 14px;
                        @include breakpoint(max-xl_device){
                            font-size: 70px;
                            line-height: 1.4;
                        }
                        @include breakpoint(max-lg_device){
                            font-size: 40px;
                            padding-bottom: 15px;
                        }
                        @include breakpoint(max-xxs_device){
                            font-size: 25px;
                        }
                    }
                }
                &.text-position_center{
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 100%;
                    text-align: center;
                }
            }
            &:hover{
                .skudmart-btn{
                    background-color: $primary-color;
                    color: $white-color;
                }
            }
        }
    }
    [class*="col-"]{
        &:not(:last-child){
            .banner-item{
                @include breakpoint(max-md_device){
                    padding-bottom: 30px;
                }
            }
        }
    }
}

/* ---Banner Area Seventeen--- */
.banner-area-17{
    &.with-bg{
        background-image: url('../images/banner/17-1.webp');
        background-repeat: no-repeat;
        background-size: cover;
        height: 800px;
        @include breakpoint(max-sm_device){
            height: 520px;
        }
    }
    .banner-wrap{
        background-color: $white-color;
        position: absolute;
        width: 620px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @include breakpoint(max-sm_device){
            width: calc(100% - 30px);
            margin: 0 auto;
        }
        .banner-inner_slider{
            height: 100%;
            .banner-item{
                .inner-content{
                    padding: 75px 30px 80px;
                    text-align: center;
                    span{
                        &.category{
                            color: $primary-color;
                            font-size: 18px;
                            line-height: 21.4px;
                            letter-spacing: 1px;
                            display: block;
                        }
                    }
                    h3{
                        &.heading{
                            font-family: 'Playfair Display', serif;
                            font-style: italic;
                            font-size: 66px;
                            line-height: 66px;
                            max-width: 75%;
                            margin: 0 auto;
                            @include breakpoint(max-sm_device){
                                max-width: 100%;
                                font-size: 40px;
                                line-height: 1.4;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* ---Banner Area Eighteen--- */
.banner-area-18{
    .with-bg{
        background-image: url('../images/banner/18-1.webp');
        background-repeat: no-repeat;
        background-size: cover;
        height: 600px;
        @include breakpoint(max-sm_device){
            height: 450px;
        }
    }
    .inner-content{
        position: absolute;
        top: 50%;
        left: 10%;
        transform: translateY(-50%);
        text-align: center;
        @include breakpoint(max-sm_device){
            width: calc(100% - 30px);
            left: 0;
        }
        span{
            &.category{
                color: $primary-color;
                font-size: 32px;
                line-height: 32px;
                display: block;
                text-transform: uppercase;
                font-weight: 700;
            }
        }
        h3{
            &.product-offer{
                font-size: 80px;
                line-height: 80px;
                @include breakpoint(max-sm_device){
                    font-size: 34px;
                    line-height: 1.2;
                }
                strong{
                    font-family: 'Playfair Display', serif;
                    font-style: italic;
                    display: block;
                    letter-spacing: 2px;
                }
            }
        }
    }
}

/* ---Banner Area Nineteen--- */
.banner-area-19{
    @media (min-width: 1280px){
        width: calc(100% - 100px);
        margin-left: auto;
    }
    .banner-item{
        .single-img{
            .inner-content{
                position: absolute;
                top: 60%;
                left: 50%;
                transform: translate(-50%, -50%);
                @extend %basetransition;
                z-index: 1;
                opacity: 0;
                visibility: hidden;
                h1{
                    &.heading{
                        @include breakpoint(max-md_device){
                            font-size: 25px;
                        }
                    }
                }
            }
        }
        &:hover{
            .single-img{
                .inner-content{
                    top: 50%;
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
    }
}