/*----------------------------------------*/
/*  04 - Utilities - Color
/*----------------------------------------*/
/* ---Background Color--- */
.bg-charcoal_color{
    background-color: $charcoal-color;
}
.bg-denim_color{
    background-color: $primary-color;
}
.bg-smoke_color{
    background-color: $white-smoke_color;
}
.bg-white_color{
    background-color: $white-color;
}
.bg-snow_color{
    background-color: $snow-color;
}
.bg-dark_color{
    background-color: $secondary-color;
}
.bg-jaguar_color{
    background-color: $jaguar-color;
}
.bg-midnight_color{
    background-color: $midnight-color;
}
.bg-niro_color{
    background-color: $niro-color;
}
.bg-primary_color{
    background-color: $primary-color;
}

/* ---Text Color Variation--- */
.white-text{
    *{
        color: $white-color;
    }
}
.text-neon_blue{
    *{
        color: $neon-blue_color;
    }
}
.secondary-text{
    *{
        color: $secondary-color;
    }
}
.text-color{
    *{
        color: $text-color;
    }
}

/* ---Template Color Variation--- */
@for $i from 1 through length($colorList) {
    .template-color-#{$i} {
        a{
            &:hover {
				color: nth($colorList, $i);
			}
        }
        /* ---Main Menu--- */
        .main-menu{
            .main-nav{
                ul{
                    li{
                        &:hover{
                            > a{
                                color: nth($colorList, $i);
                            }
                        }
                        a{
                            &.active{
                                color: nth($colorList, $i);
                            }
                        }
                        > ul{
                            &.skudmart-dropdown{
                                li{
                                    &:hover{
                                        > a{
                                            text-decoration: underline;
                                            color: nth($colorList, $i);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &.menu-style_three{
                .main-nav{
                    > ul{
                        > li{
                            &:hover{
                                > a{
                                    background-color: $secondary-color;
                                    color: $white-color;
                                }
                            }
                        }
                    }
                }
            }
        }
        /* ---Transparent Header--- */
        .white-color{
            .main-nav{
                ul{
                    li{
                        a{
                            &.active{
                                color: nth($colorList, $i);
                            }
                        }
                    }
                }
            }
        }
        /* ---Slick Arrow--- */
        .slick-arrow{
            color: nth($colorList, $i);
            &:hover{
                background-color: nth($colorList, $i);
                color: $white-color;
            }
        }
        /* ---Testimonial--- */
        .testimonial-item{
            &.style-2{
                .single-img{
                    &:after{
                        color: nth($colorList, $i);
                    }
                }
            }
        }

        /* ---Scroll To Top--- */
        .scroll-to-top{
            &:hover{
                background-color: nth($colorList, $i);
            }
        }
    }
}