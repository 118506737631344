/*----------------------------------------*/
/*  37 - Pages - About Us
/*----------------------------------------*/
.about-banner_area{
    padding-top: 95px;
    &[class*="about-banner_area"]{
        &:not(:first-child){
            @include breakpoint(max-md_device){
                padding-top: 90px;
            }
        }
    }
    .single-content{
        h2{
            &.heading{
                font-family: 'Playfair Display';
                font-style: italic;
                font-size: 50px;
                line-height: 65px;
                max-width: 75%;
                padding-bottom: 15px;
                @include breakpoint(max-lg_device){
                    font-size: 32px;
                    line-height: 1.4;
                    max-width: 80%;
                }
                @include breakpoint(max-md_device){
                    max-width: 100%;
                }
                @include breakpoint(max-sm_device){
                    padding-top: 20px;
                    font-size: 20px;
                }
            }
            &.heading-2{
                font-family: 'Playfair Display';
                font-style: italic;
                font-size: 32px;
                line-height: 41px;
                max-width: 75%;
                padding-bottom: 15px;
                @include breakpoint(max-lg_device){
                    max-width: 100%;
                }
                @include breakpoint(max-sm_device){
                    padding-top: 20px;
                    font-size: 20px;
                }
            }
        }
    }
}

/* ---Our Story Area--- */
.our-story_area{
    padding-top: 95px;
    .inner-bg{
        background-image: url('../images/about-us/bg/1.jpg');
        background-repeat: no-repeat;
        height: 740px;
        position: relative;
        @include breakpoint(max-lg_device){
            height: 550px;
        }
        @include breakpoint(max-sm_device){
            height: 350px;
        }
        .inner-content{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            h2{
                &.heading{
                    color: $white-color;
                    font-size: 150px;
                    line-height: 150px;
                    letter-spacing: 15px;
                    font-weight: 400;
                    padding-bottom: 10px;
                    @include breakpoint(max-lg_device){
                        font-size: 80px;
                        line-height: 80px;
                        padding-bottom: 20px;
                    }
                    @include breakpoint(max-sm_device){
                        font-size: 25px;
                        line-height: 25px;
                        padding-bottom: 25px;
                    }
                }
            }
            .link-wrap{
                a{
                    &.link-share{
                        background-color: $white-color;
                        color: $secondary-color;
                        width: 60px;
                        height: 60px;
                        line-height: 66px;
                        text-align: center;
                        display: block;
                        font-size: 20px;
                        border-radius: 100%;
                        &:hover{
                            background-color: $secondary-color;
                            color: $white-color;
                        }
                    }
                }
            }
        }
    }
}

/* ---Brand With Section Title--- */
.brand-area{
    &.with-section_title{
        .brand-nav{
            border-bottom: 1px solid $border-color;
            background-color: $white-color;
            padding-top: 55px;
            position: relative;
            margin-top: -60px;
            padding-bottom: 45px;
            .about-page_brand{
                .swiper-wrapper{
                    align-items: center;
                    .swiper-slide{
                        .brand-item{
                            text-align: center;
                            img{
                                transform: scale(.8);
                            }
                        }
                    }
                }
            }
        }
    }
}